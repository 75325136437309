import React, { useState, useContext, memo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Divider,
  Chip,
  IconButton,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  makeStyles,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  MenuOpen as MenuOpenIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import { isEqual } from "lodash";

import { UserContext } from "components/User";

import { firstInitial } from "util/helpers";

const useStyles = makeStyles({
  accordionDetails: {
    paddingTop: 0,
  },
});

const filterValueNames = {
  all: "all",
};

const filterNames = {
  status: "status",
  assignedTo: "assignedTo",
  referrer: "referralSource",
};

const DashboardFilters = ({ filters = [], values = [], updateFilter }) => {
  const [isOpen, toggleOpen] = useState(false);
  const { allAdmins = [] } = useContext(UserContext);
  const classes = useStyles();

  function getFilterValue(values, id) {
    let val = Object.keys(values).find((value) => {
      return value === id;
    });

    return !!val ? values[val] : "";
  }

  function resolveAdminDisplay(id) {
    const obj = allAdmins && allAdmins.find((admin) => admin.adminId === id);
    return obj
      ? obj.lastName
        ? `${obj.lastName} ${firstInitial(obj.firstName)}`
        : ` ${obj.email}`
      : ` ${id}`;
  }

  // console.log("rendering filters");

  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => toggleOpen(!isOpen)}>
          <MenuOpenIcon />
        </IconButton>
        <Typography variant="button">Filters</Typography>
        <Box ml={3}>
          {values &&
            filters
              .filter((item) => item.id !== filterNames.status)
              .map(({ id, name }) => {
                const val = getFilterValue(values, id);
                let label = `${name}: ${val}`;
                if (id === filterNames.assignedTo) {
                  label = `${name}: ${
                    !val ? " Unassigned" : resolveAdminDisplay(val)
                  }`;
                }
                let shouldDisplay = true;
                if (id === filterNames.referrer) {
                  if (val === "" || val === filterValueNames.all)
                    shouldDisplay = false;
                } else {
                  if (val === filterValueNames.all) shouldDisplay = false;
                }

                return !shouldDisplay ? null : (
                  <Chip
                    key={id}
                    label={label}
                    onDelete={() =>
                      updateFilter({
                        target: {
                          name: id,
                          value: filterValueNames.all,
                        },
                      })
                    }
                    variant="outlined"
                  />
                );
              })}
        </Box>
      </Box>
      <Drawer anchor={"left"} open={isOpen} onClose={() => toggleOpen(!isOpen)}>
        <Box display="flex" flexDirection="column" px={2} py={3}>
          <Typography variant="h6">Filters</Typography>
          <Box position="absolute" right="5px" top="5px">
            <IconButton onClick={() => toggleOpen(!isOpen)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mt={3}>
            {values ? (
              filters
                .filter((filter) => filter.id !== "status")
                .map(({ name, id, options }, i) => {
                  const val = getFilterValue(values, id);
                  return (
                    <React.Fragment key={name}>
                      {i > 0 && <Divider />}
                      <Box mb={2}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${name}-content`}
                            id={`${name}-header`}
                          >
                            <Typography variant="body1">{name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            className={classes.accordionDetails}
                          >
                            <Box>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label={name}
                                  name={id}
                                  value={val}
                                  onChange={updateFilter}
                                >
                                  {options.map(({ name: optName, field }) => {
                                    return (
                                      <FormControlLabel
                                        key={field}
                                        value={field || ""}
                                        control={<Radio />}
                                        label={optName}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </React.Fragment>
                  );
                })
            ) : (
              <div></div>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

function comp(prev, next) {
  if (!isEqual(prev.filters, next.filters)) return false;
  if (isEqual(prev.values, next.values)) return true;
}

export default memo(DashboardFilters, comp);
