import React, { memo } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Select,
  MenuItem,
  List,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { equality, mapAccountTypesToOptions } from "util/helpers";
import { ACCOUNT_TYPE_SOLO_401K, ACCOUNT_TYPE_EQRP } from "config/constants";

import { isEmpty } from "lodash";

const notesStyles = makeStyles((theme) => ({
  message: {
    color: theme.palette.text.secondary,
  },
  date: {
    color: theme.palette.text.secondary,
    "& span": {
      fontSize: "12px",
    },
  },
}));

const TextRow = ({ label, value }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    mb={2}
  >
    <Box mr={1} flex={0.5}>
      <Typography variant="body1">{label}</Typography>
    </Box>
    <Box flex={0.5} textAlign="right">
      <Typography variant="body1">{value}</Typography>
    </Box>
  </Box>
);

const Notes = ({
  classes,
  notes,
  note,
  handleChange,
  dirtyKeys,
  saveNote,
  isNewApplication,
}) => {
  const localClasses = notesStyles();
  const notesData = notes
    .map((note) => {
      let date = "";
      let dateStr = "";
      try {
        date = DateTime.fromJSDate(note.createdAt.toDate());
        dateStr = date.toFormat("yyyy/LL/dd");
      } catch (_) {
        date = (note.createdAt && DateTime.fromJSDate(note.createdAt)) || "";
        dateStr = (date && date.toFormat("yyyy/LL/dd")) || "";
      }
      note.date = date;
      note.dateStr = dateStr;
      note.message = note.message || "";
      note.createdBy = note.createdBy || "";
      return note;
    })
    .sort((a, b) => b.date - a.date);
  return (
    <Paper elevation={1}>
      <div className={classes.notesContainer}>
        <Box mb={1}>
          <Typography variant="h6">Notes</Typography>
        </Box>
        {!isEmpty(notesData) && (
          <Box mt={2} mb={1}>
            <List>
              {notesData.map(({ message, dateStr, createdBy = "" }, i) => {
                return (
                  <Box pb={1} key={i}>
                    <ListItemText className={localClasses.message}>
                      {message}
                    </ListItemText>
                    <Box display="flex">
                      <Box ml={1} mr={2} className={localClasses.date}>
                        {dateStr}
                      </Box>
                      <Box className={localClasses.date}>{createdBy}</Box>
                    </Box>
                  </Box>
                );
              })}
            </List>
          </Box>
        )}
        {isNewApplication ? (
          <Box mt={2}>
            <Typography variant="body2" style={{ opacity: "0.4" }}>
              Please create an application before adding notes
            </Typography>
          </Box>
        ) : (
          <Box mt={2}>
            <TextField
              id="note"
              name="note"
              label="New note"
              multiline
              fullWidth
              placeholder="Add a new note"
              rows={4}
              rowsMax={12}
              value={note}
              onChange={handleChange}
              variant="outlined"
            />
          </Box>
        )}
      </div>
      {isNewApplication ? null : (
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="button"
            disabled={!dirtyKeys.includes("note")}
            onClick={saveNote}
          >
            {isNewApplication ? "Add" : "Save"}
          </Button>
        </Box>
      )}
    </Paper>
  );
};

const SelectRow = ({ name, label, value, handleChange, options, readOnly }) => {
  if (!options) return null;
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mb={2}
    >
      <Box mr={1} flex={0.5}>
        <Typography>{label}</Typography>
      </Box>
      <Box flex={0.5} textAlign="right">
        <Select
          fullWidth
          variant="standard"
          labelId={name}
          id={name}
          name={name}
          value={value || ""}
          onChange={handleChange}
          readOnly={readOnly}
        >
          {options.map(({ name, value }) => (
            <MenuItem key={name} value={value} dense>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};
const ApplicationHead = (props) => {
  const isNewApplication = !props.id;
  // console.log("rendering headline");
  const defaultCheckingAccountType =
    props.accountType === ACCOUNT_TYPE_SOLO_401K ||
    props.accountType === ACCOUNT_TYPE_EQRP;
  return (
    <div>
      <div>
        <Box display="flex" flexDirection="column">
          <TextRow
            label="Participant name"
            value={`${props.firstName} ${props.lastName}`}
          />
          <SelectRow
            name="accountType"
            label="Account type"
            value={props.accountType}
            handleChange={props.handleChange}
            readOnly={props.readOnly}
            options={mapAccountTypesToOptions()}
          />
          {defaultCheckingAccountType ? (
            <TextRow
              label="Checking account type"
              value={
                props.checkingAccountType === "Trust"
                  ? "Trust"
                  : props.checkingAccountType
              }
            />
          ) : (
            <SelectRow
              name="checkingAccountType"
              label="Checking account type"
              value={props.checkingAccountType}
              handleChange={props.handleChange}
              options={[
                { name: "Trust", value: "Trust" },
                { name: "LLC", value: "LLC" },
              ]}
              readOnly={props.readOnly}
            />
          )}
        </Box>

        <Notes {...props} isNewApplication={isNewApplication} />
      </div>
    </div>
  );
};

ApplicationHead.defaultProps = {
  note: "",
  firstName: "",
  lastName: "",
  notes: [],
  accountType: "",
  checkingAccountType: "",
  readOnly: false,
};

ApplicationHead.propTypes = {
  note: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.object),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  accountType: PropTypes.string,
  checkingAccountType: PropTypes.string,
  readOnly: PropTypes.bool,
};

const fields = [
  "checkingAccountType",
  "accountType",
  "note",
  "notes",
  "firstName",
  "lastName",
  "readOnly",
];

export default memo(ApplicationHead, (prev, next) =>
  equality(prev, next, fields)
);
