import React, { useState, useEffect, useCallback } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DateTime } from "luxon";

function MaterialUIPickers({
  dateHandler,
  initialDate,
  id,
  disabled = false,
  fullWidth = false,
  shouldInitToday = true,
  formatToSave = "",
}) {
  const [selectedDate, setDate] = useState(new Date());

  const initialize = useCallback(() => {
    if (initialDate) {
      return setDate(initialDate);
    }
    if (!initialDate && !shouldInitToday) {
      return setDate(null);
    }
  }, [initialDate, shouldInitToday]);
  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleDateChange = (newDate) => {
    console.log("new date", newDate);
    setDate(newDate);
    if (formatToSave) {
      const formatted = DateTime.fromJSDate(newDate).toFormat(formatToSave);
      dateHandler(formatted);
    } else {
      dateHandler(newDate);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id={id}
        label="Select date"
        format="MM/dd/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disabled={disabled}
        fullWidth={fullWidth}
      />
    </MuiPickersUtilsProvider>
  );
}

export default MaterialUIPickers;
