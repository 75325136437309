import { createMuiTheme } from "@material-ui/core/styles";
import palette from "./palette";
import typography, { defaultFonts } from "./typography";
// TODO: configure this per subdomain to provide brand theming

export default function theme(subdomain) {
  return createMuiTheme({
    palette: palette[subdomain] || palette["default"],
    typography: typography[subdomain],
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": defaultFonts[subdomain],
        },
      },
      MuiInputBase: {
        root: {
          "& input": {
            "&[readonly]": {
              color: "rgba(0, 0, 0, 0.4)",
            },
          },
        },
      },
    },
  });
}
