import { participantsCollection } from "../requests";
import { mapParticipantDataToApplication } from "../dataMap";
import * as Sentry from '@sentry/react'

export async function getParticipant(id) {
  if (!id) {
    // rollbar.info(`getParticipant but no id`);
    Sentry.captureMessage(`getParticipant but no id`, {
      extra: {
        msg: "createAdditionalParticipant",
        id
      }
    })
    return null;
  }
  try {
    const doc = await participantsCollection.doc(id).get();
    if (!doc.exists) {
      return null;
    }

    const data = doc.data();
    data.id = doc.id;
    return data;

  } catch (error) {
    console.error(`getting participant with id of ${id}`, error);
    // rollbar.error(error, `getParticipant id of ${id}`);
    Sentry.captureException(error, { extra: { msg: "getParticipant ID", id } })
    return null;
  }
}

export async function mapParticipantsToApplications(applications) {
  try {
    await Promise.all(
      applications.map(async (app) => {
        const id = app.primaryParticipantId;
        const participant = await getParticipant(id);
        if (!participant) {
          return [];
        }
        mapParticipantDataToApplication(app, participant);
        return app;
      })
    );
    return applications;
  } catch (error) {
    console.error("mapping!", error);
    // rollbar.error(error, "map participants to applications");
    Sentry.captureException(error, { extra: { msg: "map participants to applications" } })
    return [];
  }
}
