import firebase from "config/firebase";
import * as Sentry from '@sentry/react'

const storageRef = firebase.storage().ref();

export async function getDocumentation(cb = () => { }) {
  try {
    const ref = storageRef.child("documentation/instructions.md");
    const url = await ref.getDownloadURL();
    const xhr = new XMLHttpRequest();
    xhr.responseType = "text";
    xhr.onload = function () {
      const md = xhr.response;
      cb(md);
    };
    xhr.open("GET", url);
    xhr.send();
  } catch (error) {
    // rollbar.error(error, "get documentation markdown");
    Sentry.captureException(error, { extra: { msg: "get documentation markdown" } })
    return null;
  }
}
