import React, { memo, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Link,
  Typography,
  Paper,
  AccordionSummary,
  AccordionDetails,
  FormLabel,
  InputLabel,
  Input,
  MenuItem,
  Select,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DatePicker from "components/DatePicker";
import SSNInput from "components/SSNInput";
import Accordion from "components/Accordion";
import * as Sentry from "@sentry/react";

import {
  equality,
  jumioLinkInstructions,
  mapStatesToOptions,
  mapCountriesToOptions,
  getTwoLetterCountryCode,
  getFileExtFromUrl,
} from "util/helpers";

import {
  generateIdentityRedirect,
  getImageUrl,
  downloadResource,
} from "util/requests";

import copy from "./copy.json";
import { last } from "lodash";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  linkStyleButton: {
    lineHeight: "1.8",
    textDecoration: "underline",
  },
  idImages: {
    width: "100%",
    display: "flex",
    paddingTop: "30px",
    justifyContent: "space-between",
  },
  imgWrapper: {
    maxWidth: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      width: "100%",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function IdentifyVerification({
  firstName,
  lastName,
  applicationId,
  customerType,
  primaryParticipantId,
  identityJumioCallback,
  identityExpiryDateOfId,
  identityIdType,
  identityIssueDateOfId,
  identityIssuingCountryOfPassport,
  identityIssuingStateOfId,
  identityJumioFailAt,
  identityJumioSuccessAt,
  identityNumberOnId,
  identityJumioReason,
  handleChange,
  updateField,
  readOnly,
}) {
  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [idImages, setIdImages] = useState({
    front: "",
    back: "",
    selfie: "",
  });
  const [errorImages, setErrorImages] = useState(null);
  const classes = useStyles();
  const shouldDisplayNotReadyNoApplication = !applicationId;
  const shouldDisplayCustomerNotBegun =
    customerType === "customer" &&
    identityJumioSuccessAt === undefined &&
    identityJumioFailAt === undefined;
  const shouldDisplayLinkGeneration =
    !!applicationId &&
    (identityJumioSuccessAt === null || identityJumioSuccessAt === undefined);

  async function getImages() {
    if (identityJumioCallback) {
      const frontPath = `/participants/${primaryParticipantId}/identity/id_front.jpeg`;
      const backPath = `/participants/${primaryParticipantId}/identity/id_back.jpeg`;
      const selfiePath = `/participants/${primaryParticipantId}/identity/face.jpeg`;
      const pathArr = [frontPath, selfiePath];
      const hasBackPath = last(identityJumioCallback).idScanImageBackside;
      if (hasBackPath) pathArr.push(backPath);
      const [front, selfie, back] = await Promise.all(
        pathArr.map((path) => {
          return getImageUrl(path, ".png");
        })
      );
      if (!front || !selfie) handleErrorImages();
      setIdImages({
        front,
        back,
        selfie,
      });
    }
  }

  function handleErrorImages(e) {
    if (errorImages === null) {
      console.log("cannot load images in main id section");
      setErrorImages("could not load images");
    }
  }

  async function downloadAllImages() {
    try {
      await Promise.all(
        Object.keys(idImages).map((key) => {
          if (idImages[key]) {
            return downloadResource(
              idImages[key],
              `${key}.${getFileExtFromUrl(idImages[key])}`
            );
          }
          return null;
        })
      );
    } catch (error) {
      // rollbar.error(error, "download all id images");
      Sentry.captureException(error, {
        extra: { msg: "download all id images" },
      });
    }
  }

  useEffect(() => {
    getImages();
  }, [identityJumioCallback]); // eslint-disable-line

  // console.log("shjould displlay link gen", shouldDisplayLinkGeneration);
  async function generateLink() {
    if (!primaryParticipantId) return;
    setIsLoading(true);
    const redirectLink = await generateIdentityRedirect(primaryParticipantId);
    console.log("redirect", redirectLink);
    setLink(redirectLink);
    setIsLoading(false);
  }

  async function copyLinkAndInstructions() {
    const instructions = jumioLinkInstructions(
      firstName && lastName ? `${firstName} ${lastName}` : "Customer",
      link
    );
    await navigator.clipboard.writeText(instructions);
  }

  return (
    <Paper>
      <Box p={3}>
        <Box>
          <Typography variant="body1" color="primary">
            <b>Identity verification</b>
          </Typography>
        </Box>
        <Box pt={3}>
          {shouldDisplayNotReadyNoApplication ? (
            <Box>
              <Alert variant="outlined" severity="error">
                Please save the application first before completing this
                section.
              </Alert>
              <Box display="flex" justifyContent="center" mt={4}>
                <Button variant="outlined" disabled onClick={() => {}}>
                  Generate link
                </Button>
              </Box>
            </Box>
          ) : shouldDisplayCustomerNotBegun ? (
            <Alert variant="outlined" severity="error">
              The applicant has not completed the identification process{" "}
            </Alert>
          ) : (
            <Box>
              <Box mb={0.5}>
                {identityJumioFailAt && !identityJumioSuccessAt && (
                  <Alert severity="error">
                    Identity verification failed
                    <Box mt={1}>
                      <Typography variant="body2">
                        Reason:{" "}
                        {identityJumioReason
                          ? identityJumioReason
                          : (Array.isArray(identityJumioCallback) &&
                              identityJumioCallback[0] &&
                              identityJumioCallback[0].verificationStatus) ||
                            "Unknown"}
                      </Typography>
                    </Box>
                  </Alert>
                )}
                {identityJumioSuccessAt && (
                  <Alert severity="success">Identity verification passed</Alert>
                )}
                {identityJumioCallback && (
                  <>
                    <Box className={classes.idImages}>
                      {idImages.selfie && (
                        <Box className={classes.imgWrapper}>
                          <a
                            href={idImages.selfie}
                            download="face.jpeg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              id="id-img-selfie-main"
                              src={idImages.selfie}
                              alt="selfie"
                              onError={handleErrorImages}
                            />
                          </a>
                          <Typography variant="caption">Face</Typography>
                        </Box>
                      )}
                      {idImages.front && (
                        <Box className={classes.imgWrapper}>
                          <a
                            href={idImages.front}
                            download="id_front.jpeg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              id="id-img-front-main"
                              src={idImages.front}
                              alt="ID Front"
                              onError={handleErrorImages}
                            />
                          </a>
                          <Typography variant="caption">ID Front</Typography>
                        </Box>
                      )}
                      {idImages.back && (
                        <Box className={classes.imgWrapper}>
                          <a
                            href={idImages.back}
                            download="id_back.jpeg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              id="id-img-back-main"
                              src={idImages.back}
                              alt="ID back"
                              onError={handleErrorImages}
                            />
                          </a>
                          <Typography variant="caption">ID Back</Typography>
                        </Box>
                      )}
                    </Box>
                    <Box mt={1} mb={4}>
                      {errorImages ? (
                        <Typography color="error" variant="body2">
                          There was an issue loading id images
                        </Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={downloadAllImages}
                        >
                          Download all images
                        </Button>
                      )}
                    </Box>
                  </>
                )}
                <Box pt={3} pb={3}>
                  <Typography variant="body2">
                    <b>Identity data</b>
                  </Typography>
                </Box>
                <Box>
                  <InputLabel id="identityId" className={classes.selectLabel}>
                    ID type
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="identityId"
                    name="identityIdType"
                    id="identityIdType"
                    value={identityIdType || ""}
                    onChange={handleChange}
                    readOnly={readOnly}
                  >
                    <MenuItem key={"DRIVING LICENSE"} value={"DRIVING LICENSE"}>
                      Driving License
                    </MenuItem>
                    <MenuItem key={"ID CARD"} value={"ID CARD"}>
                      ID Card
                    </MenuItem>
                    <MenuItem key={"PASSPORT"} value={"PASSPORT"}>
                      Passport
                    </MenuItem>
                  </Select>
                </Box>
                <Box my={3}>
                  <InputLabel id="identitynum" className={classes.selectLabel}>
                    Number on ID
                  </InputLabel>
                  <Input
                    id={`identityNumberOnId`}
                    name={`identityNumberOnId`}
                    label={`Number on ID`}
                    value={identityNumberOnId || ""}
                    onChange={handleChange}
                    fullWidth
                    readOnly={readOnly}
                  />
                </Box>
                <Box my={3}>
                  <FormLabel component="legend">{"Expiry on ID"}</FormLabel>
                  <DatePicker
                    id={`datepicker-identityExpiryDateOfId`}
                    initialDate={identityExpiryDateOfId}
                    dateHandler={updateField.bind(
                      null,
                      "identityExpiryDateOfId"
                    )}
                    disabled={readOnly}
                    fullWidth
                    shouldInitToday={false}
                    formatToSave="LL/dd/yyyy"
                  />
                </Box>
                <Box my={3}>
                  <FormLabel component="legend">{"Issue date on ID"}</FormLabel>
                  <DatePicker
                    id={`datepicker-identityIssueDateOfId`}
                    initialDate={identityIssueDateOfId}
                    dateHandler={updateField.bind(
                      null,
                      "identityIssueDateOfId"
                    )}
                    disabled={readOnly}
                    fullWidth
                    shouldInitToday={false}
                    formatToSave="LL/dd/yyyy"
                  />
                </Box>
                <Box my={3}>
                  <InputLabel
                    id="identityState"
                    className={classes.selectLabel}
                  >
                    State
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="identityState"
                    name="identityIssuingStateOfId"
                    id="identityIssuingStateOfId"
                    value={identityIssuingStateOfId || ""}
                    onChange={handleChange}
                    readOnly={readOnly || identityIdType === "PASSPORT"}
                    style={{
                      color: identityIdType === "PASSPORT" ? "red" : "inherit",
                      opacity: identityIdType === "PASSPORT" ? 0.5 : 1,
                    }}
                  >
                    <MenuItem value="" disabled dense>
                      {identityIdType === "PASSPORT"
                        ? "Not required"
                        : "Select a state"}
                    </MenuItem>
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box my={3}>
                  <InputLabel
                    id="identityCountry"
                    className={classes.selectLabel}
                  >
                    Country on Passport
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="identityCountry"
                    name="identityIssuingCountryOfPassport"
                    id="identityIssuingCountryOfPassport"
                    value={
                      getTwoLetterCountryCode(
                        identityIssuingCountryOfPassport
                      ) || ""
                    }
                    onChange={handleChange}
                    displayEmpty
                    readOnly={
                      readOnly ||
                      identityIdType === "DRIVING LICENSE" ||
                      identityIdType === "ID CARD"
                    }
                    style={{
                      color:
                        identityIdType === "DRIVING LICENSE" ||
                        identityIdType === "ID CARD"
                          ? "red"
                          : "inherit",
                      opacity:
                        identityIdType === "DRIVING LICENSE" ||
                        identityIdType === "ID CARD"
                          ? 0.5
                          : 1,
                    }}
                  >
                    <MenuItem value="" disabled dense>
                      {identityIdType === "DRIVING LICENSE" ||
                      identityIdType === "ID CARD"
                        ? "Not required"
                        : "Select a country"}
                    </MenuItem>
                    {mapCountriesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              {shouldDisplayLinkGeneration && (
                <Box mt={4}>
                  <Box>
                    <Typography variant="body2">
                      <b>Instructions for Identity Verification</b>
                    </Typography>
                    <Box mt={1}>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: copy.instructions,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={4}
                    flexDirection="column"
                  >
                    <Box position="relative">
                      <Button
                        variant="outlined"
                        disabled={isLoading}
                        onClick={link ? copyLinkAndInstructions : generateLink}
                      >
                        {isLoading
                          ? "Generating link"
                          : link
                          ? "Copy link and instructions"
                          : "Generate link"}
                      </Button>
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          color="secondary"
                          className={classes.buttonProgress}
                        />
                      )}
                    </Box>
                    <Box mt={3} textAlign="center">
                      <Typography variant="body2">
                        {link
                          ? "The button above will copy the Stripe along with instructions for the applicant for completing the process. Paste this in an email to the applicant."
                          : "This link will be active for 2 weeks."}
                      </Typography>
                      {link ? (
                        <Box
                          mt={1}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box>
                            <Typography variant="body2">
                              If you need to create a new link,
                            </Typography>
                          </Box>
                          <Box ml={0.5}>
                            <Link
                              className={classes.linkStyleButton}
                              component="button"
                              variant="body2"
                              onClick={generateLink}
                              type="button"
                            >
                              click here
                            </Link>
                          </Box>
                        </Box>
                      ) : (
                        <Typography variant="body2">
                          If the applicant does not complete the process before
                          then, you can create a new link here.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {/* <Box pt={3}>
          <Alert severity="success">Sample has been completed message</Alert>
        </Box> */}
      </Box>
    </Paper>
  );
}

const IdentitySecurity = ({
  firstName,
  lastName,
  section,
  primarySocialSecurity,
  isLoadingSSNs,
  handleChange,
  identityJumioCallback,
  identityExpiryDateOfId,
  identityIdType,
  identityIssueDateOfId,
  identityIssuingCountryOfPassport,
  identityIssuingStateOfId,
  identityJumioFailAt,
  identityJumioSuccessAt,
  identityNumberOnId,
  identityJumioReason,
  primaryParticipantId,
  id: applicationId,
  updateField,
  classes,
  readOnly,
}) => {
  const ssn = useMemo(
    () => (
      <SSNInput
        label="Social Security Number"
        name="primarySocialSecurity"
        value={primarySocialSecurity}
        handleChange={handleChange}
        readOnly={readOnly || isLoadingSSNs}
        isLoading={isLoadingSSNs}
      />
    ),
    /* eslint-disable-next-line */
    [primarySocialSecurity, readOnly, isLoadingSSNs]
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${section}-content`}
        id={`${section}-header`}
      >
        <Typography variant="h6">Identity and Security</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.section}>
          <IdentifyVerification
            applicationId={applicationId}
            primaryParticipantId={primaryParticipantId}
            identityJumioReason={identityJumioReason}
            identityJumioCallback={identityJumioCallback}
            identityExpiryDateOfId={identityExpiryDateOfId}
            identityIdType={identityIdType}
            identityIssueDateOfId={identityIssueDateOfId}
            identityIssuingCountryOfPassport={identityIssuingCountryOfPassport}
            identityIssuingStateOfId={identityIssuingStateOfId}
            identityJumioFailAt={identityJumioFailAt}
            identityJumioSuccessAt={identityJumioSuccessAt}
            identityNumberOnId={identityNumberOnId}
            handleChange={handleChange}
            updateField={updateField}
            firstName={firstName}
            lastName={lastName}
            readOnly={readOnly}
          />
          <Box mt={6}>
            <Typography variant="body1" color="primary">
              <b>Security Information</b>
            </Typography>
          </Box>
          <Box mt={1}>{ssn}</Box>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

IdentitySecurity.defaultProps = {
  firstName: "",
  lastName: "",
  primarySocialSecurity: "",
  identityJumioCallback: null,
  identityExpiryDateOfId: null,
  identityIdType: null,
  identityIssueDateOfId: null,
  identityIssuingCountryOfPassport: null,
  identityIssuingStateOfId: null,
  identityJumioFailAt: undefined,
  identityJumioSuccessAt: undefined,
  identityJumioReason: "",
  identityNumberOnId: null,
  readOnly: false,
};

IdentitySecurity.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  primarySocialSecurity: PropTypes.string,
  identityJumioCallback: PropTypes.arrayOf(PropTypes.object),
  identityExpiryDateOfId: PropTypes.string,
  identityIdType: PropTypes.string,
  identityIssueDateOfId: PropTypes.string,
  identityIssuingCountryOfPassport: PropTypes.string,
  identityIssuingStateOfId: PropTypes.string,
  identityNumberOnId: PropTypes.string,
  identityJumioFailAt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]), // null or timestamp actually
  identityJumioSuccessAt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]), // null or timestamp actually
  identityJumioReason: PropTypes.string,
  readOnly: PropTypes.bool,
  isLoadingSSNs: PropTypes.bool,
};

const fields = [
  "firstName",
  "lastName",
  "primarySocialSecurity",
  "identityJumioCallback",
  "identityExpiryDateOfId",
  "identityIdType",
  "identityIssueDateOfId",
  "identityIssuingCountryOfPassport",
  "identityIssuingStateOfId",
  "identityJumioFailAt",
  "identityJumioSuccessAt",
  "identityNumberOnId",
  "identityJumioReason",
  "readOnly",
  "isLoadingSSNs",
];

export default memo(IdentitySecurity, (prev, next) =>
  equality(prev, next, fields)
);
