const yup = require("yup");

export async function validatesMinReqs(allData, cb) {
  return minSchema
    .validate(allData, { abortEarly: false })
    .then(() => {
      return cb(null);
    })
    .catch(function (err) {
      if (err.name === "ValidationError") {
        const list = err.errors.map((msg) => msg.split(" ")[0]);
        return cb(list);
      } else {
        return cb(null);
      }
    });
}

const minSchema = yup.object().shape({
  email: yup.string().email().required(),
  accountType: yup.string().required(),
  checkingAccountType: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup
    .mixed()
    .test("valid number", "phoneNumber in unsupported format", (value) => {
      return validatePhoneNumber(value);
    })
    .required(),
  countryCitizenship: yup.string().required(),
  gender: yup.string().required(),
  birthDate: yup.string().required(),
  maritalStatus: yup.string().required(),
  employmentStatus: yup.string().required(),
});

export function validatePhoneNumber(value) {
  if (!value) return false;
  const transformedNumber = value && `+1${value.replace(/\D/g, "")}`;
  if (!transformedNumber) return false;
  return (
    transformedNumber.length === 12 &&
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
      transformedNumber
    )
  );
}

export const CAN_SUBMIT_KEYS = [
  { key: "investmentSector1", label: "investment sector 1" },
  { key: "willInvestInternationally", label: "invests internationally" },
  { key: "ssnRef", label: "social security number" },
  { key: "employer", label: "employer" },
  { key: "industry", label: "industry" },
  { key: "resAddressLine1", label: "residential address line 1" },
  { key: "resCity", label: "residential address city" },
  { key: "resPostcode", label: "residential address postcode" },
  { key: "resState", label: "residential address city" },
  { key: "mailingAddressLine1", label: "mailing address line 1" },
  { key: "mailingCity", label: "mailing address city" },
  { key: "mailingPostcode", label: "mailing address postcode" },
  { key: "mailingState", label: "mailing address city" },
];
