import React from "react";
import { Box, Fab, makeStyles, CircularProgress } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  fabContainer: {
    position: "fixed",
    right: 10,
    top: 80,
  },
  spinner: {
    color: "#fff",
  },
}));

const FloatingButton = ({
  clickHandler = () => {},
  disabled = false,
  ariaLabel = "download",
  color = "secondary",
  loading = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.fabContainer}>
      <Fab
        color={color}
        aria-label={ariaLabel}
        onClick={clickHandler}
        disabled={disabled}
      >
        {loading ? (
          <CircularProgress size={26} className={classes.spinner} />
        ) : (
          <GetAppIcon />
        )}
      </Fab>
    </Box>
  );
};

export default FloatingButton;
