import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;
const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export default firebase.initializeApp(config);

const runFuncsLocally = false;
const isDev = process.env.NODE_ENV === "development";
const stagingBase =
  "https://us-central1-solera-app-staging-2a393.cloudfunctions.net/";

const devBase = runFuncsLocally
  ? "http://localhost:5001/solera-app-staging-2a393/us-central1/"
  : stagingBase;

// we do rewrites on all https funcs on the portal
export const FIREBASE_URLS = {
  manageSSN: isDev ? devBase + "manageSSN" : "/ssn",
  verifyIdentity: isDev ? devBase + "verifyIdentity" : "/verify-identity",
  downloadApplications: isDev
    ? devBase + "downloadApplications"
    : "/download-applications",
  additionalParticipantLink: isDev
    ? devBase + "handleAdditionalParticipants/link"
    : "/additional-participants/link",
  sendCustomerSms: isDev ? devBase + "sendCustomerSms" : "/sms",
};
