/* cSpell:disable */
// maps admin authorityDomain to subdomain name

const subdomain = {
  "advanta-ira": "advanta-ira",
  "anchor-zero": "anchor-zero",
  "aspira401k": "aspira401k",
  "brandee-berry": "brandee-berry",
  "brian-eastman": "brian-eastman",
  "broad-financial": "broad-financial",
  "checkbook-ira": "checkbook-ira",
  "choices-401k": "choices-401k",
  "complete-ira": "complete-ira",
  "damion-lupo": "damion-lupo",
  "digitaltrust": "digitaltrust",
  "discount-solo-401k": "discount-solo-401k",
  "dorado-ira": "dorado-ira",
  "easy-ira-llc": "easy-ira-llc",
  "emparion": "emparion",
  "entrust-group": "entrust-group",
  "eqrp": "eqrp",
  "etzsoft": "etzsoft",
  "freedom-trust": "freedom-trust",
  "if-cares-consulting": "if-cares-consulting",
  "invest-self-directed": "invest-self-directed",
  "ira-express-inc": "ira-express-inc",
  "ira-financial-group": "ira-financial-group",
  "jef-baker": "jef-baker",
  "jeff-vandrew": "jeff-vandrew",
  "john-hyre": "john-hyre",
  "john-park": "john-park",
  "joshua-sharp": "joshua-sharp",
  "jv-associates": "jv-associates",
  "kkos": "kkos",
  "legaspi-tax": "legaspi-tax",
  "m2-trust": "m2-trust",
  "mat-sorensen": "mat-sorensen",
  "maverik-401k": "maverik-401k",
  "midland-ira": "midland-ira",
  "monarch-ira": "monarch-ira",
  "mw-ira": "mw-ira",
  "my-direct-ira": "my-direct-ira",
  "mysolo401k.com": "mysolo401k.com",
  "nabers": "nabers",
  "new-direction-ira": "new-direction-ira",
  "nuview": "nuview",
  "oceaniq": "oceaniq",
  "ocho": "ocho",
  "perpetual-assets": "perpetual-assets",
  "pgi-agencies": "pgi-agencies",
  "quest-ira": "quest-ira",
  "real-life-investments": "real-life-investments",
  "resure-financial": "resure-financial",
  "reunion-investments": "reunion-investments",
  "rocket-dollar-gold": "rocket-dollar-gold",
  "rocket-dollar": "rocket-dollar",
  "royal-legal-solutions": "royal-legal-solutions",
  "safeguard-advisors": "safeguard-advisors",
  "scot-smith": "scot-smith",
  "sd-ira-wealth": "sd-ira-wealth",
  "sd-retirement-plans": "sd-retirement-plans",
  "sense-financial": "sense-financial",
  "snbadmin": "solera",
  "solera": "solera",
  "solo-401k-services": "solo-401k-services",
  "sortis": "sortis",
  "sunwest-trust": "sunwest-trust",
  "survival-401k": "survival-401k",
  "swan-bitcoin": "swan-bitcoin",
  "udirect-ira-services": "udirect-ira-services",
  "unchained": "unchained",
  "unified-wealth": "unified-wealth",
  "vantage-ira": "vantage-ira",
  "vast": "vast",
  "wealth-dynamics": "wealth-dynamics",
};

if (process.env.REACT_APP_ENV !== 'production') {
  subdomain['aaa-first-financial'] = 'aaa-first-financial'
  subdomain['acme-corp'] = 'acme-corp' // created for Sunil at Anchor Zero
  subdomain['bob-loblaw-trust'] = 'bob-loblaw-trust'
  subdomain['iron-safe'] = 'iron-safe'
  subdomain['saul-goodman-investments'] = 'saul-goodman-investments'
}

export default subdomain;
