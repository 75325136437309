import { firebase as firebaseAdmin } from "@firebase/app";
import firebase from "config/firebase";
import { isEmpty, capitalize } from "lodash";
import * as Sentry from '@sentry/react'

import { map as dataMap } from "util/dataMap";
import { SOLERA } from "config/constants";
import { mapParticipantsToApplications } from "./participant";
import { mapApplicationsToParticipants } from "./application";
import { totalCountsCollection } from "../requests";

export async function setQueryListener(
  query,
  prevListener,
  callbackAdd,
  callbackUpdate,
  callbackRemove,
  errorHandler
) {
  if (prevListener) {
    prevListener();
  }

  function refreshData(doc, callback) {
    // take application data and add participant data to it
    try {
      const data = doc.data();
      data.id = doc.id;
      // don't include these - they represent a failed attempt at creating an application and participant data is necessary
      // if (!data.primaryParticipantId) return null;
      return mapParticipantsToApplications([data]).then((data) => {
        return callback({ data, doc });
      });
    } catch (error) {
      console.log("error", error);
      // rollbar.error(error, "refreshData");
      Sentry.captureException(error, { extra: { msg: "refreshData" } })
      errorHandler(error);
    }
  }

  const unsubscribe = await query.onSnapshot(
    (snapshot) => {
      if (snapshot.size === 0) {
        callbackAdd();
      }
      const source = snapshot.metadata.hasPendingWrites ? "local" : "server";
      if (source === "local") return; // only bother with server changes otherwise can run twice when changed in this app
      snapshot.docChanges().forEach((change) => {
        // might want to treat each a little differently so separated each for now
        if (change.type === "added") {
          // console.log("adding");
          refreshData(change.doc, callbackAdd);
        }
        if (change.type === "modified") {
          console.log("modified");
          refreshData(change.doc, callbackUpdate);
        }
        if (change.type === "removed") {
          refreshData(change.doc, callbackRemove);
        }
      });
    },
    (error) => {
      const currUser = firebase.auth().currentUser;
      if (currUser) {
        // there is sometimes a lag between signing out and unsubscribing. we don't want to throw an error here in that case
        // so we can first look if the user is logged in
        console.error("error with listener", error);
        // rollbar.error(error, "dashboard listener");
        Sentry.captureException(error, { extra: { msg: "error with listener" } })
        errorHandler(error);
      }
    }
  );
  return unsubscribe;
}

// firebase queries are case sensitive
export async function getApplicationsSearch(
  field,
  value,
  authority,
  retries = 3
) {
  let arr = [];
  if (!field || !value) return arr;
  try {
    arr = await search(field, value, authority);
    if (isEmpty(arr)) {
      // check cases
      if (retries <= 0) {
        return arr;
      }
      let formattedValue = value;
      if (retries === 3) formattedValue = capitalize(value);
      if (retries === 2) formattedValue = value.toLowerCase();
      if (retries === 1) formattedValue = value.toUpperCase();
      return getApplicationsSearch(
        field,
        formattedValue,
        authority,
        retries - 1
      );
    }
    return arr;
  } catch (error) {
    console.error("search error", error);
    // rollbar.error(error, "getApplicationsSearch");
    Sentry.captureException(error, { extra: { msg: "getApplicationsSearch" } })
    throw error;
  }
}

export async function search(field, value, providerSource) {
  console.log("field for search", field);
  const collectionName = dataMap[field];
  const providerKey =
    collectionName === "applications"
      ? "providerSource"
      : "applicationProvider";
  try {
    let arr = [];

    if (!collectionName) return arr;
    const collection = firebase.firestore().collection(collectionName);
    // get search collection data

    // special case for id as the doc id might not also be stored with the data
    if (field === "id") {
      field = firebaseAdmin.firestore.FieldPath.documentId();
    }
    let snap;

    if (providerSource) {
      // this applies to non-solera admins
      snap = await collection
        .where(providerKey, "==", providerSource)
        .where(field, "==", value)
        .get();
    } else {
      snap = await collection.where(field, "==", value).get();
    }

    if (snap.empty) return arr;
    resolveSnapshot(snap, arr);
    if (collectionName === "applications") {
      // now get the participant info
      return await mapParticipantsToApplications(arr);
    } else if (collectionName === "participants") {
      // now get the application info
      return await mapApplicationsToParticipants(arr);
    }
  } catch (error) {
    console.error(
      `search error for ${field},
    ${value},
    ${providerSource}, ${collectionName}, ${providerKey}`,
      error
    );
    // rollbar.error(
    //   `search error for ${field},
    // ${value},
    // ${providerSource}`,
    //   error
    // );
    Sentry.captureException(error, { extra: { msg: "search error", field, value, providerSource } })
    return [];
  }
}

function resolveSnapshot(snap, arr) {
  if (snap.empty) return arr;
  snap.forEach((doc) => {
    const data = doc.data();
    data.id = doc.id;
    arr.push(data);
  });
  return arr;
}

export async function getApplications(query) {
  let apps = [];
  if (!query) return apps;
  try {
    const snap = await query.get();
    resolveSnapshot(snap, apps);
    // get participants and add to data
    return await mapParticipantsToApplications(apps);
  } catch (error) {
    console.error("get apps", error);
    // rollbar.error(error, "getApplications");
    Sentry.captureException(error, { extra: { msg: "getApplications" } })
    throw error;
  }
}

export async function getCountByStatus(statuses, providerSource) {
  const documentId = providerSource === SOLERA
    ? "applications" : "applicationsByProvider"

  const doc = await totalCountsCollection.doc(documentId).get();

  let counts = []
  if (doc.exists) {
    const data = doc.data();
    const provCounts = providerSource === SOLERA ? data : data[providerSource];

    if (typeof provCounts === "object") {
      counts = statuses.map((status) => {
        return provCounts[status] || 0;
      });
    }
  }

  console.log(
    "get application counts by status",
    { provider: providerSource, counts }
  );

  return counts
}
