import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import firebase from "config/firebase";

import { UserContext } from "components/User";
import {
  setQueryListener as subscribe,
  getCountByStatus,
} from "util/requests/dash";
import { retrieveReferrers } from "util/requests/referrers";
import { columns, transformRow, sortModel } from "util/dashboard";
import { firstInitial } from "util/helpers";
import { statusTypes } from "util/dataMap";
import { DASHBOARD_RECORD_LIMIT, STATUS } from "../../config/constants";

import { uniqBy, findIndex, cloneDeep, isEmpty, sortBy } from "lodash";

export const ApplicationsDataContext = createContext();

const statuses = statusTypes.map((status) => {
  status.field = status.value;
  return status;
});

const ApplicationsData = ({ children }) => {
  const [data, setApplicationsData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterValues, setFilterValues] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [referrers, setReferrers] = useState([]);
  const [page, setPage] = useState({
    currPage: 1,
    prevPage: 1,
  });
  const [countsByStatus, setCountsByStatus] = useState(null);
  const subscription = useRef(null);
  const lastDoc = useRef(null);
  const pageDocs = useRef({});
  const recordCount = useRef(0);
  const {
    isLoggedIn,
    authorityDomain,
    allAdmins = [],
    uid: adminId,
    email: currentAdminEmail,
  } = useContext(UserContext);

  const initFilterOptions = useCallback(
    (data) => {
      // console.log("data for init filter", data);
      const adminOptions = data.allAdmins
        .filter((admin) => admin.adminId !== data.adminId)
        .map((admin) => ({
          name: admin.lastName
            ? `${admin.lastName} ${firstInitial(admin.firstName)}`
            : admin.email,
          field: admin.adminId,
        }));
      const referrersCopy = cloneDeep(referrers);
      const referrerOptions = sortBy(
        referrersCopy.map((referrer) => {
          if (referrer.value === "SOLERA") {
            const val = "0000-NOT ASSIGNED";
            referrer.field = val;
            referrer.name = val;
          } else {
            referrer.field = referrer.value;
          }

          return referrer;
        }),
        ["name"]
      );
      // console.log("adminoptions", adminOptions);
      const filters = [
        {
          name: "Assigned",
          id: "assignedTo",
          options: [
            { name: "Unassigned", field: null },
            { name: `Me (${data.currentAdminEmail})`, field: data.adminId },
            ...adminOptions,
            { name: "All", field: "all" },
          ],
        },
        {
          name: "Status",
          id: "status",
          options: statuses,
        },
      ];
      if (authorityDomain === "solera") {
        filters.push({
          name: "Referrer",
          id: "referralSource",
          options: referrerOptions,
        });
      }
      return filters;
    },
    [referrers, authorityDomain]
  );

  function handlePageChange(pageData) {
    setPage((prev) => ({
      currPage: pageData.page,
      prevPage: prev.currPage,
    }));
  }

  useEffect(() => {
    // get this data when the authority domain has been established, after log in
    async function populateStatusCounts() {
      const statuses = Object.keys(STATUS).map((stat) => STATUS[stat]);
      console.log("authority domain get counts by status", authorityDomain);
      const counts = await getCountByStatus(statuses, authorityDomain);
      const data = statuses.map((stat, index) => ({
        status: stat,
        count: counts[index] || 0,
      }));

      setCountsByStatus(data);
    }
    if (!authorityDomain) return;
    populateStatusCounts();
  }, [authorityDomain]);

  useEffect(() => {
    // this is run before auth is established
    async function getReferrers() {
      const referrers = await retrieveReferrers();
      setReferrers(referrers);
    }
    getReferrers();
  }, []);

  useEffect(() => {
    const options = initFilterOptions({
      allAdmins,
      adminId,
      currentAdminEmail,
    });
    setFilterOptions(options);
  }, [adminId, JSON.stringify(allAdmins), initFilterOptions]); //eslint-disable-line

  // we flexibly build the query based on filter values and pagination
  const resolveQuery = useCallback(
    (pg) => {
      const applicationsCollection = firebase
        .firestore()
        .collection("applications");

      const query = () => {
        let func = applicationsCollection;
        Object.keys(filterValues).forEach((key) => {
          const value = filterValues[key] === "" ? null : filterValues[key];
          if (key === "submittedAt") {
            if (value === null) {
              func = func.where(key, "==", value);
            } else if (value !== "all") {
              func = func.where(key, ">", new Date("01-01-2020"));
            }
          } else if (value !== "all") {
            func = func.where(key, "==", value);
          }
        });
        if (lastDoc.current) {
          if (pg.currPage > pg.prevPage) {
            return func
              .orderBy("updatedAt", "desc")
              .limit(DASHBOARD_RECORD_LIMIT)
              .startAfter(lastDoc.current);
          } else if (pg.currPage < pg.prevPage) {
            if (pg.currPage > 1) {
              return func
                .orderBy("updatedAt", "desc")
                .limit(DASHBOARD_RECORD_LIMIT)
                .startAfter(pageDocs.current[pg.prevPage - 2])
                .endBefore(lastDoc.current);
            } else {
              return func
                .orderBy("updatedAt", "desc")
                .limit(DASHBOARD_RECORD_LIMIT)
                .endBefore(lastDoc.current);
            }
          }
        }
        return func.orderBy("updatedAt", "desc").limit(DASHBOARD_RECORD_LIMIT);
      };
      return query;
    },
    [filterValues]
  );

  const getData = useCallback(
    async (pg) => {
      if (isEmpty(allAdmins)) return;
      // console.log("@@LOG: getting data for page:", pg);
      setLoading(true);
      setError(null);
      recordCount.current = 0;
      setApplicationsData([]);
      const supporting = { allAdmins };
      if (pg?.prevPage < pg?.currPage) {
        // we go forward, we set the page doc
        pageDocs.current[pg.prevPage] = lastDoc.current;
      }

      try {
        const query = resolveQuery(pg);

        function callbackAdd({ data, doc } = {}) {
          // console.log("data", data);
          if (!data) {
            setApplicationsData([]);
            return setLoading(false);
          }

          const formattedData = transformRow(...data, supporting);
          setApplicationsData((prev) => {
            const joined = uniqBy([...prev, formattedData], "id");
            return joined;
          });
          setLoading(false);
          lastDoc.current = doc;
          recordCount.current += 1;
        }

        function callbackUpdate({ data, doc } = {}) {
          console.log("running callback update");
          const formattedData = transformRow(...data, supporting);
          setApplicationsData((prev) => {
            const index = findIndex(prev, (row) => row.id === formattedData.id);
            const newData = cloneDeep(prev);
            newData[index] = formattedData;
            return newData;
          });
          setLoading(false);
        }

        function callbackRemove({ data, doc } = {}) {
          console.log("running callback remove");
          const formattedData = transformRow(...data, supporting);
          setApplicationsData((prev) => {
            let newData = cloneDeep(prev);
            newData = newData.filter((item) => item.id !== formattedData.id);
            return newData;
          });
          setLoading(false);
        }

        const newSubscription = await subscribe(
          query(),
          subscription.current,
          callbackAdd,
          callbackUpdate,
          callbackRemove,
          setError
        );
        subscription.current = newSubscription;
      } catch (error) {
        console.error("err getting data", error);
        setLoading(false);
        setError(error);
      }
    },
    [resolveQuery, allAdmins]
  );

  useEffect(() => {
    // stop the firestore subscription if the user logs out
    if (!isLoggedIn && !isLoading) {
      console.log("stopping the listener after logout");
      subscription.current && subscription.current();
    }
  }, [isLoggedIn, isLoading]);

  useEffect(() => {
    // initialize the filter values
    function initFilterValues() {
      const obj = {
        providerSource: authorityDomain === "solera" ? "all" : authorityDomain,
        assignedTo: "all",
        status: "REQUIRES REVIEW",
      };
      setFilterValues((prevState) => ({
        ...prevState,
        ...obj,
      }));
    }
    if (isLoggedIn) {
      initFilterValues();
    }
  }, [isLoggedIn, authorityDomain, adminId]);

  useEffect(() => {
    // this runs when filter values change
    async function run() {
      if (!isLoggedIn) return;
      if (!countsByStatus) return;
      if (filterValues) {
        lastDoc.current = null;
        console.log("setting page in filter change", filterValues);
        // reset the page count
        setPage({
          prevPage: 1,
          currPage: 1,
        });
        console.log("fetching new data in filter value change");
        await getData({
          prevPage: 1,
          currPage: 1,
        });
      }
    }
    run();
  }, [filterValues, getData, countsByStatus, isLoggedIn]);

  useEffect(() => {
    // runs when data pagination changes
    if (page.currPage === page.prevPage) return;
    if (page.currPage > page.prevPage) {
      if (recordCount.current < DASHBOARD_RECORD_LIMIT) {
        return;
      }
    }

    getData(page);
  }, [page, getData]);

  const updateFilter = useCallback(({ target }) => {
    const { name, value } = target || {};
    if (name && value !== undefined) {
      setFilterValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }, []);

  const initColumns = columns;
  return (
    <ApplicationsDataContext.Provider
      value={{
        data,
        sortModel,
        referrers,
        filterValues,
        filters: filterOptions,
        columns: initColumns,
        countsByStatus: countsByStatus,
        isLoading,
        error,
        updateFilter,
        authorityDomain,
        handlePageChange,
        page,
      }}
    >
      {children}
    </ApplicationsDataContext.Provider>
  );
};

export default ApplicationsData;
