import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Box, Typography, Button } from "@material-ui/core";
import { firebase as firebaseAdmin } from "@firebase/app";

import { isEmpty, cloneDeep, omit } from "lodash";

import { UserContext } from "components/User";
import { uploadFile } from "util/requests";
import {
  createApplication,
  updateApplication,
} from "util/requests/application";
import {
  handleRetrieveSSNs,
  handleFetchNestedBeneficiarySSNs,
} from "util/requests/ssn";
import {
  downloadApplications,
  getAllApplicationData,
} from "util/requests/application";

import { SOLERA, STATUS } from "config/constants";

import { resolveDocumentPaths, documentMap } from "util/dataMap";
import { filterTouchedData, cleanData, handleFieldLogic } from "util/helpers";
import { validatesMinReqs, CAN_SUBMIT_KEYS } from "util/validation";

import ApplicationForm from "components/ApplicationForm";
import Spinner from "components/Spinner";

const ApplicationFormWrapper = () => {
  const [data, setData] = useState({
    dirtyKeys: [],
    note: "",
    notes: [],
  });
  const [files, setFiles] = useState({});
  const [dirtyFiles, setDirtyFiles] = useState([]);
  const [errorSave, setErrorSave] = useState(null);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [errorDownload, setErrorDownload] = useState(null);
  const [canDownload, setCanDownload] = useState(false);
  const [validates, setValidatesMinReqs] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorsSubmit, setErrorsSubmit] = useState([]);

  const { id = null } = useParams();
  const admin = useContext(UserContext);
  const history = useHistory();

  const mountedRef = useRef(null);

  const initFormData = useCallback((data) => {
    data.dirtyKeys = [];
    data.note = data.note || "";
    data.notes = data.notes || [];
    data.isLoadingSSNs = true;
    data.isLoadingBeneficiarySSNs = true;
    setData(data);
    handleStatusChange(data.status);
  }, []);

  function handleStatusChange(status) {
    if (status === "READY FOR BATCH") {
      setCanDownload(true);
    }
  }

  function retrieveSSNs(data) {
    handleRetrieveSSNs(data).then((ssns) => {
      if (mountedRef.current) {
        setData((prev) => ({
          ...prev,
          ...ssns,
          isLoadingSSNs: false,
        }));
      }
    });
  }

  function retrieveBeneficiarySSNs(data) {
    handleFetchNestedBeneficiarySSNs(data).then((designatedBeneficiaries) => {
      if (mountedRef.current) {
        setData((prev) => ({
          ...prev,
          designatedBeneficiaries,
          isLoadingBeneficiarySSNs: false,
        }));
      }
    });
  }

  function handleDownload() {
    // download a zip file with application info
    setIsLoadingDownload(true);
    if (errorDownload) {
      setErrorDownload(null);
    }
    downloadApplications(data.applicationRefId || data.id)
      .then(() => {
        mountedRef.current && setIsLoadingDownload(false);
      })
      .catch((e) => {
        setErrorDownload(e.message);
        setIsLoadingDownload(false);
      });
  }

  useEffect(() => {
    async function getData() {
      console.log("running effect on id with func getData");
      mountedRef.current = true;
      const data = await getAllApplicationData(id);
      if (!data) {
        return setErrorFetch(
          "An error occurred getting this application data. Please try again"
        );
      }
      console.log("all fetched data", data);
      retrieveSSNs(data);
      retrieveBeneficiarySSNs(data);

      if (mountedRef.current) {
        setErrorFetch(null);
        return initFormData(data);
      }
    }
    if (id) getData();

    return function cleanup() {
      mountedRef.current = false;
    };
  }, [id, initFormData]);

  useEffect(() => {
    let mounted = true;
    function validateMinInputs() {
      if (id && window.location.pathname.includes("new")) return;
      if (id) {
        console.log("setting validation to true");
        setValidatesMinReqs(true);
      }
      // only run on new applications
      if (!mounted) return;
      validatesMinReqs(data, (errList) => {
        if (errList) {
          setValidatesMinReqs(false);
          setValidationErrors(errList);
        } else {
          setValidatesMinReqs(true);
          setValidationErrors([]);
        }
      });
    }

    validateMinInputs();
    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    data.accountType,
    data.firstName,
    data.lastName,
    data.accountType,
    data.checkingAccountType,
    data.email,
    data.phoneNumber,
    data.countryCitizenship,
    data.gender,
    data.birthDate,
    data.maritalStatus,
    data.employmentStatus,
  ]);

  function handleChange(e) {
    e.persist();
    let value = enforceType(e.target.value);
    let name = e.target.name;
    const payload = {
      [name]: value,
    };
    const assocData = handleFieldLogic(name, value, data);
    Object.keys(assocData).forEach((key) => {
      payload[key] = assocData[key];
    });
    setData((prev) => ({
      ...prev,
      dirtyKeys: [...new Set([...prev.dirtyKeys, ...Object.keys(payload)])],
      ...payload,
    }));
  }

  function enforceType(value) {
    if (value === "true") return true;
    if (value === "false") return false;
    return value;
  }

  const handleAddressAutocomplete = useCallback((addressObj, name) => {
    const associatedFields = {
      homeAddressObj: {
        resCity: addressObj.city,
        resState: addressObj.state,
        resAddressLine1: addressObj.address,
        resPostcode: addressObj.zipCode,
      },
      mailingAddressObj: {
        mailingCity: addressObj.city,
        mailingState: addressObj.state,
        mailingAddressLine1: addressObj.address,
        mailingPostcode: addressObj.zipCode,
      },
      previousAddressObj: {
        previousCity: addressObj.city,
        previousState: addressObj.state,
        previousAddressLine1: addressObj.address,
        previousPostcode: addressObj.zipCode,
      },
    };
    const payload = associatedFields[name];
    payload[name] = addressObj;
    setData((prev) => ({
      ...prev,
      dirtyKeys: [...new Set([...prev.dirtyKeys, ...Object.keys(payload)])],
      ...payload,
    }));
  }, []);

  function updateField(name, value) {
    // direct update of field when required
    const payload = {
      [name]: value,
    };
    const assocData = handleFieldLogic(name, value, data);
    Object.keys(assocData).forEach((key) => {
      payload[key] = assocData[key];
    });
    setData((prev) => ({
      ...prev,
      dirtyKeys: [...new Set([...prev.dirtyKeys, ...Object.keys(payload)])],
      ...payload,
    }));
  }

  const onSelectFile = useCallback((e) => {
    const file = e.currentTarget.files[0];
    const name = e.target.name;
    setFiles((prev) => {
      const newFiles = cloneDeep(prev);
      newFiles[name] = file;
      return newFiles;
    });
    setDirtyFiles((prev) => {
      return [...new Set([...prev, name])];
    });
  }, []);

  function checkCanSubmit(allData) {
    const missingKeys = [];
    CAN_SUBMIT_KEYS.forEach(({ key, label }) => {
      if (allData[key] === undefined || allData[key] === "") {
        if (key === "ssnRef" && !!allData["primarySocialSecurity"]) {
          // do nothing, exception
        } else if (
          // handle employer and former employer
          key === "employer" &&
          (allData["employmentStatus"] === "unemployed" ||
            allData["employmentStatus"] === "retired")
        ) {
          if (!allData["formerEmployer"]) {
            missingKeys.push("most recent or former employer");
          }
        } else {
          missingKeys.push(label);
        }
      }
    });
    return missingKeys;
  }

  async function handleSave(e, shouldSubmit) {
    try {
      e.preventDefault();
      setErrorSave(null);
      setIsLoadingSave(true);
      const dirtyKeys = data.dirtyKeys;
      if (isEmpty(dirtyKeys) && isEmpty(dirtyFiles)) return;
      let payload = filterTouchedData(dirtyKeys, data);
      payload = cleanData(payload);
      if (shouldSubmit) {
        // check here if min data exists to submit
        const allData = { ...data, ...payload };
        const missingSubmissionKeys = checkCanSubmit(allData);

        if (!isEmpty(missingSubmissionKeys)) {
          setErrorsSubmit(missingSubmissionKeys);
          setIsLoadingSave(false);
          return;
        }
        setErrorsSubmit([]);
        payload.submittedAt = firebaseAdmin.firestore.FieldValue.serverTimestamp();
        payload.status = "REQUIRES REVIEW";
      }
      const shouldHaveAdditionalParticipant = data.addAdditionalParticipant;
      if (id) {
        // update the application

        const primaryParticipantId = data.primaryParticipantId;
        const additionalParticipantId = data.additionalParticipantId;

        await handleFilesUpload(
          id,
          primaryParticipantId,
          additionalParticipantId
        );
        let addPartId;
        // supplement this upon update
        payload.applicationRefId = id;
        /////////////////////////////
        if (payload) {
          addPartId = await updateApplication(
            id,
            payload,
            primaryParticipantId,
            additionalParticipantId,
            admin.authorityDomain,
            data.referralSource,
            shouldHaveAdditionalParticipant
          );
        }

        setData((prev) => ({
          ...prev,
          additionalParticipantId:
            additionalParticipantId && shouldHaveAdditionalParticipant === false
              ? ""
              : addPartId || additionalParticipantId,
          submittedAt: prev.submittedAt
            ? prev.submittedAt
            : payload.submittedAt
            ? new Date()
            : null,
          dirtyKeys: [],
          status: payload.submittedAt
            ? STATUS.REQUIRES_REVIEW
            : prev.status || STATUS.DRAFT,
        }));
        setIsLoadingSave(false);
        if (payload.status && payload.status === "READY FOR BATCH") {
          handleStatusChange(payload.status);
        }
      } else {
        // create a new application
        console.log("payload", payload);
        if (!isEmpty(data.notes)) {
          payload.notes = firebaseAdmin.firestore.FieldValue.arrayUnion(
            ...data.notes
          );
        }
        supplementPayload(payload);
        const { applicationId, participantId } =
          (await createApplication(payload, admin.authorityDomain)) || {};

        await handleFilesUpload(applicationId, participantId);
        setData((prev) => ({
          ...prev,
          dirtyKeys: [],
        }));
        setIsLoadingSave(false);
        history.push(`/applications/${applicationId}`);
      }
    } catch (error) {
      console.error("handle save", error);
      setErrorSave((error && error.message) || "");
      setIsLoadingSave(false);
    }
  }

  function supplementPayload(payload) {
    // add more fields to payload
    payload.createdByUserType =
      admin.authorityDomain === SOLERA ? `${admin.email} (admin)` : "referrer";
  }

  async function handleFilesUpload(
    applicationId,
    participantId,
    additionalParticipantId
  ) {
    try {
      // upload files and return refs as array,
      if (isEmpty(files)) return;
      console.log("uploading files", files);
      setIsUploadingFiles(true);
      const refs = await Promise.all(
        Object.keys(files).map((key) => {
          const path = resolveDocumentPaths(
            key,
            applicationId,
            participantId,
            additionalParticipantId
          );
          const file = files[key];
          return uploadFile(path, file);
        })
      );
      const payload = {};
      refs.forEach((ref, i) => {
        const name = Object.keys(files)[i];
        if (ref) {
          payload[name] = ref;
        }
      });

      console.log("upload files payload", payload);
      if (isEmpty(payload)) {
        setIsUploadingFiles(false);
        return;
      }
      await updateApplication(
        applicationId,
        payload,
        participantId,
        additionalParticipantId,
        admin.authorityDomain,
        data.referralSource
      );
      setFiles((prev) => ({
        ...prev,
        ...payload,
      }));
      // reset files
      setDirtyFiles([]);
      // POST-SUBMISSION UPLOADS: save a note
      if (data.submittedAt) {
        let note = "Documents uploaded post-submission:";
        for (const key in payload) {
          note += ` ${documentMap[key]}`;
        }
        await saveNote(note);
      }
      setIsUploadingFiles(false);
    } catch (error) {
      console.error(error);
      setErrorSave((error && error.message) || "");
      setIsUploadingFiles(false);
    }
  }

  async function saveNote(body) {
    if (!data.note && !body) return;
    const noteData = {
      message: data.note || body,
      createdAt: new Date(),
      createdBy: (admin && admin.email) || "admin",
      id: Math.random().toString(36).slice(2),
    };
    // if a new application, we simply add it to state and it gets saved when main form is saved
    if (id) {
      const payload = {
        notes: firebaseAdmin.firestore.FieldValue.arrayUnion(noteData),
      };
      const primaryParticipantId = data.primaryParticipantId;
      const additionalParticipantId = data.additionalParticipantId;
      await updateApplication(
        id,
        payload,
        primaryParticipantId,
        additionalParticipantId,
        admin.authorityDomain,
        data.referralSource
      );
    }
    setData((prev) => ({
      ...prev,
      note: "",
      notes: [...prev.notes, noteData],
      dirtyKeys: prev.dirtyKeys.filter((key) => key !== "note"),
    }));
  }

  async function refetch() {
    setData({});
    const data = await getAllApplicationData(id);
    if (data) {
      setErrorFetch(null);
      initFormData(data);
    } else {
      return setErrorFetch(
        "An error occurred getting this application data. Please try again"
      );
    }
  }

  function renderError() {
    return (
      <Container maxWidth="md">
        <Box py={2}>
          <Typography variant="body1" color="error">
            There was an error getting the data, please try again.
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="secondary" onClick={refetch}>
              Try again
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  function resolveReadOnly() {
    let readOnly = false;
    if (data.id === undefined) return readOnly;
    if (admin.authorityDomain === SOLERA) {
      if (
        data.status !== STATUS.DRAFT &&
        data.status !== STATUS.REQUIRES_REVIEW
      ) {
        readOnly = true;
      }
    } else {
      if (data.status !== STATUS.DRAFT) readOnly = true;
    }
    if (data.primaryParticipantId === undefined) readOnly = true;
    return readOnly;
  }

  function handleCloseSubmitErrorAlert(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setErrorsSubmit([]);
  }

  // console.log("all data", data);
  function renderForm(isNewApplication) {
    return (
      <div>
        <Container maxWidth="md">
          <ApplicationForm
            {...data}
            readOnly={resolveReadOnly()}
            files={files}
            dirtyFiles={dirtyFiles}
            handleChange={handleChange}
            onSelectFile={onSelectFile}
            handleAddressAutocomplete={handleAddressAutocomplete}
            updateField={updateField}
            handleSave={handleSave}
            error={errorSave}
            saveNote={saveNote}
            isLoadingSave={isLoadingSave}
            handleDownload={handleDownload}
            isLoadingDownload={isLoadingDownload}
            errorDownload={errorDownload}
            errorsSubmit={errorsSubmit}
            handleCloseSubmitErrorAlert={handleCloseSubmitErrorAlert}
            canDownload={canDownload}
            isUploadingFiles={isUploadingFiles}
            authorityDomain={admin.authorityDomain}
            isNewApplication={isNewApplication}
            validatesMinReqs={validates}
            validationErrors={validationErrors}
          />
        </Container>
      </div>
    );
  }

  function renderNew() {
    return errorSave ? renderError() : renderForm(true);
  }

  function renderExisting() {
    return errorFetch ? (
      renderError()
    ) : isEmpty(omit(data, ["notes", "dirtyKeys", "note"])) ? (
      <Spinner />
    ) : (
      renderForm()
    );
  }

  return (
    <main>
      <div>{id ? renderExisting() : renderNew()}</div>
    </main>
  );
};

export default ApplicationFormWrapper;
