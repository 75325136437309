// define colors

// SOLERA
const SOLERA = {
  RED: "#E04039",
  DARK_RED: "#BF3832",
  BLUE: "#5D89AB",
  DARK_BLUE: "#22415F",
  LIGHT_BLUE: "#C1E1EE",
  CREAM: "#F4F0E5",
  WARM_BLACK: "#201C1C",
};

// DEFAULT
const DEFAULT = {
  PRIMARY: "#3f51b5",
  PRIMARY_DARK: "#303f9f",
  SECONDARY: "#f50057",
  SECONDARY_LIGHT: "#40d9ff",
  SECONDARY_DARK: "#c51162",
  INFO: "#2196f3",
  TEXT: "#201C1C",
};

const palette = {
  default: {
    primary: {
      main: DEFAULT.PRIMARY,
      dark: DEFAULT.PRIMARY_DARK,
    },
    secondary: {
      main: DEFAULT.SECONDARY,
      light: DEFAULT.SECONDARY_LIGHT,
      dark: DEFAULT.SECONDARY_DARK,
    },
    info: {
      main: DEFAULT.INFO,
    },
    text: {
      primary: DEFAULT.TEXT,
    },
  },
  solera: {
    primary: {
      main: SOLERA.RED,
      dark: SOLERA.DARK_RED,
    },
    secondary: {
      main: SOLERA.BLUE,
      light: SOLERA.LIGHT_BLUE,
      dark: SOLERA.DARK_BLUE,
    },
    info: {
      main: SOLERA.CREAM,
    },
    text: {
      primary: SOLERA.WARM_BLACK,
    },
  },
};

export default palette;
