import { firebase as firebaseAdmin } from "@firebase/app";
import { DateTime } from "luxon";
import { fairMarketCollection } from "../requests";
import * as Sentry from '@sentry/react'

export async function getAllFairMarketAccounts() {
  const snapshot = await fairMarketCollection
    .orderBy("updatedAt", "desc")
    .get();
  const size = snapshot.size;
  const data = snapshot.docs.map((doc) => {
    const obj = doc.data();
    obj.docId = doc.id;
    return obj;
  });
  return { data, size };
}

export async function getFairMarketStats() {
  // get entire collection and parse data to get high level stats
  const { data, size } = await getAllFairMarketAccounts();
  if (size === 0) {
    return {
      data: [],
      size,
    };
  }
  let stats = {
    completed: 0,
    total: size,
    lastUpdated: new Date(),
  };

  stats.lastUpdated = DateTime.fromJSDate(data[0].updatedAt.toDate()).toFormat(
    "LL/dd/yyyy"
  );
  data.forEach((item) => {
    if (item.completedAt) {
      stats.completed += 1;
    }
  });
  return { stats, data };
}

export async function searchFairMarketAccount(accountNumber) {
  try {
    let results = null;
    const snapshot = await fairMarketCollection
      .where("accountNumber", "==", accountNumber)
      .get();
    if (snapshot.empty) return results;
    results = snapshot.docs[0].data();
    results.id = snapshot.docs[0].id;
    return results;
  } catch (error) {
    // rollbar.error(error, "searchFairMarketAccounts");
    Sentry.captureException(error, { extra: { msg: "searchFairMarketAccounts" } })
    throw error;
  }
}

export async function updateFairMarketAccount(id, data, wasCompleted) {
  try {
    const payload = {
      marketValue: data.marketValue,
      valuationDate: data.valuationDate,
      updatedAt: firebaseAdmin.firestore.FieldValue.serverTimestamp(),
    };

    if (!wasCompleted) {
      payload.completedAt = firebaseAdmin.firestore.FieldValue.serverTimestamp();
    }

    await fairMarketCollection.doc(id).update(payload);
  } catch (error) {
    // rollbar.error(error, "updateFairMarketAccount");
    Sentry.captureException(error, { extra: { msg: "updateFairMarketAccount" } })
  }
}
