import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  AccordionSummary,
  InputLabel,
  Select,
  MenuItem,
  AccordionDetails,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Accordion from "components/Accordion";
import DatePicker from "components/DatePicker";
import SSNInput from "components/SSNInput";
import {
  CHECKING_ACCOUNT_TYPE_TRUST,
  ACCOUNT_TYPE_SOLO_401K,
} from "config/constants";

import { equality, mapStatesToOptions } from "util/helpers";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Trust = ({
  accountType,
  section,
  checkingAccountType,
  trustName,
  trustTaxId,
  trustCreationDate,
  trustStateOfRegistration,
  trustAddressEquals,
  trustAddressLine1,
  trustState,
  trustCity,
  trustApt,
  trustPostcode,
  //
  resCity,
  resState,
  resAddressLine1,
  resPostcode,
  mailingCity,
  mailingState,
  mailingAddressLine1,
  mailingPostcode,
  handleChange,
  updateField,
  classes,
  readOnly,
  isLoadingSSNs,
}) => {
  const isResAddressComplete =
    resCity && resState && resAddressLine1 && resPostcode;
  const isMailingAddressComplete =
    mailingCity && mailingState && mailingAddressLine1 && mailingPostcode;

  const trustTax = useMemo(
    () => (
      <SSNInput
        label="Trust Tax id"
        maskType="taxId"
        name="trustTaxId"
        value={trustTaxId}
        handleChange={handleChange}
        readOnly={readOnly || isLoadingSSNs}
        isLoading={isLoadingSSNs}
      />
    ),
    /* eslint-disable-next-line */
    [trustTaxId, readOnly, isLoadingSSNs]
  );

  return (
    <>
      {checkingAccountType === CHECKING_ACCOUNT_TYPE_TRUST && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section}-content`}
            id={`${section}-header`}
          >
            <Typography variant="h6">Trust</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.section}>
              <Box mb={4}>
                <TextField
                  fullWidth
                  className={classes.input}
                  variant="standard"
                  label="Trust Name"
                  type="text"
                  name={"trustName"}
                  id={"trustName"}
                  value={trustName || ""}
                  onChange={handleChange}
                  margin="dense"
                  inputProps={{ readOnly: Boolean(readOnly) }}
                ></TextField>
                <Box mt={3} display="flex" flexDirection="column">
                  <FormLabel component="legend">
                    {"Trust creation date"}
                  </FormLabel>
                  <DatePicker
                    id={`datepicker-trustCreationDate`}
                    initialDate={trustCreationDate}
                    dateHandler={updateField.bind(null, "trustCreationDate")}
                    disabled={readOnly}
                  />
                </Box>
                {trustTax}

                {accountType !== ACCOUNT_TYPE_SOLO_401K && (
                  <>
                    <InputLabel
                      id="trustStateReg"
                      className={classes.selectLabel}
                    >
                      State of Trust registration
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="trustStateOfRegistration"
                      name="trustStateOfRegistration"
                      id="trustStateOfRegistration"
                      value={trustStateOfRegistration || ""}
                      onChange={handleChange}
                      readOnly={readOnly}
                    >
                      {mapStatesToOptions().map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Box>
              {isResAddressComplete && isMailingAddressComplete ? (
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {"Trust Address info"}
                  </FormLabel>
                  <RadioGroup
                    aria-label={"trustAddressEquals"}
                    name={"trustAddressEquals"}
                    value={trustAddressEquals}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value={"residential"}
                      control={<Radio disabled={readOnly} />}
                      label="Same as residential"
                    />
                    <FormControlLabel
                      value={"mailing"}
                      control={<Radio disabled={readOnly} />}
                      label="Same as mailing"
                    />
                    <FormControlLabel
                      value={"other"}
                      control={<Radio disabled={readOnly} />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <Alert severity="warning">
                  Please complete the Contact information address section
                </Alert>
              )}

              {!!trustAddressEquals && (
                <>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Trust address line 1"
                    type="text"
                    name="trustAddressLine1"
                    id="trustAddressLine1"
                    value={trustAddressLine1 || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Trust Apartment or Suite"
                    type="text"
                    name="trustApt"
                    id="trustApt"
                    value={trustApt || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Trust City"
                    type="text"
                    name="trustCity"
                    id="trustCity"
                    value={trustCity || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                  <InputLabel id="trustState" className={classes.selectLabel}>
                    Trust State
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="trustState"
                    name="trustState"
                    id="trustState"
                    value={trustState || ""}
                    onChange={handleChange}
                    readOnly={readOnly}
                  >
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Zip code"
                    type="text"
                    name="trustPostcode"
                    id="trustPostcode"
                    value={trustPostcode || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

Trust.defaultProps = {
  accountType: "",
  checkingAccountType: null,
  trustName: "",
  trustCreationDate: new Date(),
  trustStateOfRegistration: "",
  trustAddressEquals: "",
  trustAddressLine1: "",
  trustState: "",
  trustApt: "",
  trustCity: "",
  trustPostcode: "",
  trustTaxId: "",
  readOnly: false,
};

Trust.propTypes = {
  accountType: PropTypes.string,
  checkingAccountType: PropTypes.string,
  trustName: PropTypes.string,
  trustCreationDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  trustStateOfRegistration: PropTypes.string,
  trustAddressEquals: PropTypes.oneOf(["mailing", "residential", "other", ""]),
  trustAddressLine1: PropTypes.string,
  trustState: PropTypes.string,
  trustApt: PropTypes.string,
  trustCity: PropTypes.string,
  trustPostcode: PropTypes.string,
  trustTaxId: PropTypes.string,
  readOnly: PropTypes.bool,
  isLoadingSSNs: PropTypes.bool,
};

const fields = [
  "checkingAccountType",
  "trustName",
  "trustCreationDate",
  "trustStateOfRegistration",
  "trustAddressEquals",
  "trustAddressLine1",
  "trustState",
  "trustApt",
  "trustCity",
  "trustPostcode",
  "resCity",
  "resState",
  "resAddressLine1",
  "resPostcode",
  "mailingCity",
  "mailingState",
  "mailingAddressLine1",
  "mailingPostcode",
  "trustTaxId",
  "readOnly",
  "isLoadingSSNs",
  "accountType",
];

export default memo(Trust, (prev, next) => equality(prev, next, fields));
