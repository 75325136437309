import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import { InputBase, Button, Select, MenuItem, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "150px",
    fontSize: "14px",
    height: "36px",
    "& div": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
  },
  inputContainer: {
    color: "inherit",
    border: "1px solid black",
    marginRight: 10,
    position: "relative",
    height: "36px",
    width: "20ch",
    borderRadius: "4px",
    borderColor: fade("rgb(133, 133, 133)", 0.5),
    "&:focus-within": {
      borderColor: fade(theme.palette.text.primary, 1),
    },
    "&:hover": {
      borderColor: fade(theme.palette.text.primary, 1),
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  input: {
    padding: 0,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "36px",
  },
  button: {
    height: "36px",
  },
}));

const fieldMap = [
  { label: "last name", name: "lastName" },
  { label: "app id", name: "id" },
  { label: "email", name: "email" },
  { label: "phone number", name: "phoneNumber" },
];

const Search = () => {
  const [search, setSearch] = useState("");
  const [field, setField] = useState("lastName");

  const history = useHistory();
  const { search: searchParams } = useLocation();
  const classes = useStyles();

  function handleSearch() {
    // take search, pass as state to history for next page
    console.log("search", search);
    history.push({
      pathname: `/applications/search`,
      search: `?field=${field}&value=${search}`,
    });
  }

  function handleFieldChange(e) {
    setField(e.target.value);
  }

  useEffect(() => {
    function setStateFromSearchParams() {
      if (searchParams) {
        const query = new URLSearchParams(searchParams);
        const field = query.get("field");
        const value = query.get("value");
        if (field && value) {
          setField(field);
          setSearch(value);
        }
      }
    }
    setStateFromSearchParams();
  }, [searchParams]);

  // console.log("rendering search");
  return (
    <div className={classes.search}>
      <Box mr={1}>
        <Select
          margin="dense"
          className={classes.select}
          variant="outlined"
          labelId="select field"
          id="field"
          value={field}
          onChange={handleFieldChange}
        >
          {fieldMap.map(({ name, label }) => (
            <MenuItem key={name} value={name} dense>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <div className={classes.inputContainer}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="..."
          className={classes.input}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          inputProps={{ "aria-label": "search" }}
        />
      </div>
      <Button
        onClick={handleSearch}
        variant="contained"
        color="primary"
        disabled={!search}
        className={classes.button}
      >
        Search
      </Button>
    </div>
  );
};

export default Search;
