import React, { useContext } from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { UserContext } from "components/User";

const Account = () => {
  const { isLoggedIn, signOut } = useContext(UserContext);
  return (
    <main>
      <Container maxWidth="sm">
        <Typography gutterBottom={true} variant="h4">
          My Account
        </Typography>
        {isLoggedIn && (
          <Button variant="contained" color="primary" onClick={signOut}>
            Sign out
          </Button>
        )}
        <Box mt={6}>
          <Link to="/instructions">Documentation</Link>
        </Box>
      </Container>
    </main>
  );
};

export default Account;
