import { isEqual, isEmpty, last } from "lodash";
import { DateTime } from "luxon";
import {
  CHECKING_ACCOUNT_TYPE_TRUST,
  ACCOUNT_TYPE_EQRP,
  ACCOUNT_TYPE_SOLO_401K,
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_BUSINESS_ACCOUNT,
  // ACCOUNT_TYPE_CUSTODIAL_IRA,
  ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY,
} from "config/constants";

const isoCountry = require("iso-3166-1");

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFileExtFromUrl(url) {
  try {
    return last(url.split("?")[0].split("%2F")).split(".")[1];
  } catch (_) {
    return ".jpeg";
  }
}

export function getFilenameFromHeader(
  headers,
  headerName = "Content-Disposition"
) {
  const content = headers.get(headerName);
  if (!content) return "file";
  const filename = content.match(/(?<=")(.*)(?=")/g);
  if (isEmpty(filename)) return "file";
  return filename[0];
}

export function filterTouchedData(dirtyKeys, data) {
  const payload = dirtyKeys.reduce((acc, curr) => {
    acc[curr] = data[curr];
    return acc;
  }, {});
  return payload;
}

export function convertTimestampToText(
  ts,
  format = "LL/dd/yyyy hh:mm a (ZZZZ)"
) {
  if (!ts) return "";
  try {
    return DateTime.fromJSDate(ts.toDate()).toFormat(format);
  } catch (err) {
    return DateTime.fromJSDate(ts).toFormat(format);
  }
}

export function timeDiff(tsBefore, tsAfter, format = ["hours", "minutes"]) {
  try {
    const before = DateTime.fromJSDate(tsBefore.toDate());
    const after = DateTime.fromJSDate(tsAfter.toDate());
    const diff = after.diff(before, format);

    const format1 = [format[0]];
    const val1 = diff[format[0]].toFixed(0);
    const format2 = [format[1]];
    const val2 = diff[format[1]].toFixed(0);

    return val1 > 0
      ? `${val1} ${format1}, ${val2} ${format2}`
      : `${val2} ${format2}`;
  } catch (e) {
    console.log(e);
    return "cannot calculate";
  }
}

export function cleanData(data) {
  const transform = {
    phoneNumber: (item) => item.replace(/\D/g, ""),
    additionalParticipantPhone: (item) => item.replace(/\D/g, ""),
    primarySocialSecurity: (item) => item.replace(/\D/g, ""),
    additionalParticipantSocialSecurity: (item) => item.replace(/\D/g, ""),
    trustTaxId: (item) => item.replace(/\D/g, ""),
    llcTaxId: (item) => item.replace(/\D/g, ""),
    spouseSocialSecurity: (item) => item.replace(/\D/g, ""),
    ssn: (item) => item.replace(/\D/g, ""),
    taxId: (item) => item.replace(/\D/g, ""),
    inheritedIraHolderSocialSecurityNumber: (item) => item.replace(/\D/g, ""),
    birthDate: (item) => DateTime.fromJSDate(item).toFormat("LL/dd/yyyy"),
    additionalParticipantBirthDate: (item) =>
      DateTime.fromJSDate(item).toFormat("LL/dd/yyyy"),
    spouseBirthDate: (item) => DateTime.fromJSDate(item).toFormat("LL/dd/yyyy"),
    trustCreationDate: (item) =>
      DateTime.fromJSDate(item).toFormat("LL/dd/yyyy"),
    inheritedIraHolderDateOfDeath: (item) =>
      DateTime.fromJSDate(item).toFormat("LL/dd/yyyy"),
    inheritedIraHolderDob: (item) =>
      DateTime.fromJSDate(item).toFormat("LL/dd/yyyy"),
    designatedBeneficiaries: (arr) => {
      arr.forEach((item) => {
        if (item.phoneNumber) {
          item.phoneNumber = item.phoneNumber.replace(/\D/g, "");
        }
        if (item.beneficiarySsn) {
          item.beneficiarySsn = item.beneficiarySsn.replace(/\D/g, "");
        }
        if (item.beneficiaryTaxId) {
          item.beneficiaryTaxId = item.beneficiaryTaxId.replace(/\D/g, "");
        }
      });
      return arr;
    },
  };
  const cleaned = Object.keys(data).reduce((acc, curr) => {
    let val = data[curr];
    if (transform[curr]) {
      val = transform[curr](val);
    }
    acc[curr] = val;
    return acc;
  }, {});
  console.log("cleaned", cleaned);
  return cleaned;
}

export function firstInitial(str) {
  if (!str) return "";
  return `${str.split("")[0].toUpperCase()}.`;
}

export function equality(prev, next, fields = []) {
  return fields.every((field) => {
    return isEqual(prev[field], next[field]);
  });
}

export function handleFieldLogic(name, value, allData) {
  const data = {};
  if (name === "accountType") {
    data.checkingAccountTaxTypes = [];
    data.iraType = "";
    data.createTraditionalCheckingForTaxDeferredFunds = false;
    data.createRothCheckingForPostTaxFunds = false;
    data.checkingAccountNicknameRoth = "";
    data.checkingAccountNicknameTraditional = "";
    data.designatedBeneficiaries = [];
    data.shouldNameBeneficiary = null;
    if (value === ACCOUNT_TYPE_SOLO_401K || value === ACCOUNT_TYPE_EQRP) {
      data.checkingAccountType = CHECKING_ACCOUNT_TYPE_TRUST;
    }
    if (value === ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL) {
      data.addAdditionalParticipant = false;
      data.additionalParticipantId = "";
      data.shouldNameBeneficiary = true;
    }
  }
  if (name === "iraType") {
    if (allData.accountType === ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL) {
      if (value.toLowerCase().includes("traditional")) {
        data.checkingAccountNicknameRoth = "";
        data.createTraditionalCheckingForTaxDeferredFunds = true;
        data.createRothCheckingForPostTaxFunds = false;
        data.checkingAccountTaxTypes = ["tax-defer"];
      }
      if (value.toLowerCase().includes("roth")) {
        data.checkingAccountNicknameTraditional = "";
        data.createRothCheckingForPostTaxFunds = true;
        data.createTraditionalCheckingForTaxDeferredFunds = false;
        data.checkingAccountTaxTypes = ["post-tax"];
      }
    }
  }
  if (name === "checkingAccountTaxTypes") {
    if (!isEmpty(allData.checkingAccountTaxTypes)) {
      data.checkingAccountNicknameRoth = "";
      data.checkingAccountNicknameTraditional = "";
    }

    if (allData.accountType === ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL) {
      if (value.includes("tax-defer")) {
        data.createTraditionalCheckingForTaxDeferredFunds = true;
        data.createRothCheckingForPostTaxFunds = false;
      } else if (value.includes("post-tax")) {
        data.createTraditionalCheckingForTaxDeferredFunds = false;
        data.createRothCheckingForPostTaxFunds = true;
      }
    } else {
      if (value.includes("tax-defer")) {
        data.createTraditionalCheckingForTaxDeferredFunds = true;
      } else {
        data.createTraditionalCheckingForTaxDeferredFunds = false;
      }
      if (value.includes("post-tax")) {
        data.createRothCheckingForPostTaxFunds = true;
      } else {
        data.createRothCheckingForPostTaxFunds = false;
      }
    }
  }
  if (name === "trustAddressEquals") {
    if (value === "residential") {
      if (allData.resAddressLine1)
        data.trustAddressLine1 = allData.resAddressLine1;
      if (allData.resCity) data.trustCity = allData.resCity;
      if (allData.resState) data.trustState = allData.resState;
      if (allData.resAptNumber) data.trustApt = allData.resAptNumber;
      if (allData.resPostcode) data.trustPostcode = allData.resPostcode;
    }
    if (value === "mailing") {
      if (allData.mailingAddressLine1)
        data.trustAddressLine1 = allData.mailingAddressLine1;
      if (allData.mailingCity) data.trustCity = allData.mailingCity;
      if (allData.mailingState) data.trustState = allData.mailingState;
      if (allData.mailingAptNumber) data.trustApt = allData.mailingAptNumber;
      if (allData.mailingPostcode) data.trustPostcode = allData.mailingPostcode;
    }
    if (value === "other") {
      data.trustAddressLine1 = "";
      data.trustCity = "";
      data.trustState = "";
      data.trustApt = "";
      data.trustPostcode = "";
    }
  }
  if (name === "llcAddressEquals") {
    if (value === "residential") {
      if (allData.resAddressLine1)
        data.llcAddressLine1 = allData.resAddressLine1;
      if (allData.resCity) data.llcCity = allData.resCity;
      if (allData.resState) data.llcState = allData.resState;
      if (allData.resAptNumber) data.llcUnitOrSuite = allData.resAptNumber;
      if (allData.resPostcode) data.llcPostcode = allData.resPostcode;
    }
    if (value === "mailing") {
      if (allData.mailingAddressLine1)
        data.llcAddressLine1 = allData.mailingAddressLine1;
      if (allData.mailingCity) data.llcCity = allData.mailingCity;
      if (allData.mailingState) data.llcState = allData.mailingState;
      if (allData.mailingAptNumber)
        data.llcUnitOrSuite = allData.mailingAptNumber;
      if (allData.mailingPostcode) data.llcPostcode = allData.mailingPostcode;
    }
    if (value === "other") {
      data.llcAddressLine1 = "";
      data.llcCity = "";
      data.llcState = "";
      data.llcUnitOrSuite = "";
      data.llcPostcode = "";
    }
  }
  if (name === "llcWhollyOwnedByIra") {
    if (value === true) {
      data.iraPercentOwnershipInLlc = 100;
    } else {
      data.iraPercentOwnershipInLlc = 0;
    }
  }
  if (name === "usaCitizen") {
    // set countryCitizenship && usaPermanentResident
    if (value === true) {
      data.countryCitizenship = "US";
      data.hasUSAddress = true;
    } else {
      data.countryCitizenship = "";
      data.hasUSAddress = true;
    }

    data.usaPermanentResident = true;
    data.hasForeignAddress = false;
    data.foreignAddressLine1 = "";
    data.foreignAddressApt = "";
    data.foreignAddressCity = "";
    data.foreignAddressState = "";
    data.foreignAddressPostcode = "";
    data.foreignAddressCountry = "";
  }
  if (name === "usaPermanentResident") {
    if (value === false) {
      data.hasUSAddress = true;
    }
  }
  if (name === "foreignAddressLine1" || name === "foreignAddressCountry") {
    if (!!value) {
      data.hasForeignAddress = true;
    }
  }
  if (name === "resAddressEqualsMailing") {
    if (value === false) {
      data.mailingCity = "";
      data.mailingState = "";
      data.mailingAddressLine1 = "";
      data.mailingAptNumber = "";
      data.mailingPostcode = "";
    }
    if (value === true) {
      data.mailingCity = allData.resCity || "";
      data.mailingState = allData.resState || "";
      data.mailingAddressLine1 = allData.resAddressLine1 || "";
      data.mailingAptNumber = allData.resAptNumber || "";
      data.mailingPostcode = allData.resPostcode || "";
    }
  }
  if (name === "gender") {
    data.maidenName = "";
  }
  if (name === "employmentStatus") {
    if (value === "employed" || value === "self-employed") {
      data.formerEmployer = "";
    } else {
      data.employer = "";
    }
  }
  if (name === "willInvestInternationally") {
    data.countriesForInternationalTrans = [];
  }
  if (name === "shouldNameBeneficiary") {
    if (value === true) {
      console.log("should add true", allData.designatedBeneficiaries);
      if (
        Array.isArray(allData.designatedBeneficiaries) &&
        allData.designatedBeneficiaries.length > 0
      ) {
        // do nothing
      } else if (Array.isArray(allData.designatedBeneficiaries)) {
        data.designatedBeneficiaries = [
          {
            email: "",
            entityType: "",
            dob: "",
            gender: "",
            legalName: "",
            relationshipToAccountHolder: "",
            sharePercentage: "",
            type: "primary",
          },
        ];
      }
    } else {
      data.designatedBeneficiaries = [];
    }
  }
  if (name === "addAdditionalParticipant") {
    data.additionalParticipantFirstName = "";
    data.additionalParticipantMiddleName = "";
    data.additionalParticipantLastName = "";
    data.additionalParticipantEmail = "";
    data.additionalParticipantPhone = "";
  }
  // additional participant
  if (name === "additionalParticipantUsaCitizen") {
    // set countryCitizenship && usaPermanentResident
    data.additionalParticipantCountryCitizenship = "US";
    data.additionalParticipantUsaPermanentResident = true;
    data.additionalParticipantHasForeignAddress = false;
    data.additionalParticipantForeignAddressLine1 = "";
    data.additionalParticipantForeignAddressApt = "";
    data.additionalParticipantForeignAddressCity = "";
    data.additionalParticipantForeignAddressState = "";
    data.additionalParticipantForeignAddressPostcode = "";
    data.additionalParticipantForeignAddressCountry = "";

    if (value === true) {
      data.additionalParticipantHasUSAddress = true;
    }
    if (value === false) {
      data.additionalParticipantHasUSAddress = true;
    }
  }
  if (name === "additionalParticipantUsaPermanentResident") {
    if (value === false) {
      data.additionalParticipantHasUSAddress = true;
    }
  }
  if (
    name === "additionalParticipantForeignAddressLine1" ||
    name === "additionalParticipantForeignAddressCountry"
  ) {
    if (!!value) {
      data.additionalParticipantHasForeignAddress = true;
    }
  }
  if (name === "additionalParticipantResAddressEqualsMailing") {
    if (value === false) {
      data.additionalParticipantMailingCity = "";
      data.additionalParticipantMailingState = "";
      data.additionalParticipantMailingAddressLine1 = "";
      data.additionalParticipantMailingAptNumber = "";
      data.additionalParticipantMailingPostcode = "";
    }
    if (value === true) {
      data.additionalParticipantMailingCity =
        allData.additionalParticipantResCity || "";
      data.additionalParticipantMailingState =
        allData.additionalParticipantResState || "";
      data.additionalParticipantMailingAddressLine1 =
        allData.additionalParticipantResAddressLine1 || "";
      data.additionalParticipantMailingAptNumber =
        allData.additionalParticipantResAptNumber || "";
      data.additionalParticipantMailingPostcode =
        allData.additionalParticipantResPostcode || "";
    }
  }

  return data;
}

export function dataBlock(data) {
  let message = "";
  if (data.hasUSAddress === false && data.usaCitizen === false) {
    message = "The applicant must have a US address to create an account.";
  }
  return message ? message : null;
}

export function formatPhoneNumber(str) {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
}

export function jumioLinkInstructions(customerName, link) {
  return `
Dear ${customerName},\n\n
Solera National Bank takes your financial security seriously.\n
That’s why we’ve partnered with Stripe, an online identity verification company that uses advanced technology to protect against fraud and identity theft.\n
By clicking the link below, Stripe will walk you through using either your computer’s webcam or your mobile device to capture two things:\n
1. An image of your ID (US citizens) or passport (non-US citizens)
2. A live selfie\n
These steps will ensure that the only person who can open an account in your name is you.\n
Please click the link below to get started:\n 
${link}`;
}

export function mapAccountTypesToOptions() {
  return accountTypes.map((option) => ({
    value: option,
    name: option,
  }));
}

export function mapStatesToOptions() {
  return Object.keys(USStates).map((key) => ({
    value: key,
    name: USStates[key],
  }));
}

export function mapCountriesToOptions() {
  return countries.map(({ name, code }) => ({
    value: code,
    name,
  }));
}

export function mapIndustriesToOptions() {
  return Object.keys(industries).map((key) => ({
    value: industries[key],
    name: key,
  }));
}

export function getTwoLetterCountryCode(countryCode) {
  if (!countryCode) return "";
  if (countryCode.length === 2) return countryCode;
  const obj = isoCountry.whereAlpha3(countryCode) || {};
  const twoletter = obj.alpha2 || null;
  return twoletter;
}

export function mapInvestmentsToOptions() {
  return investmentOptions.map((option) => ({
    value: option,
    name: option,
  }));
}

export const accountTypes = [
  ACCOUNT_TYPE_SOLO_401K,
  ACCOUNT_TYPE_EQRP,
  ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY,
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  // ACCOUNT_TYPE_BUSINESS_ACCOUNT,
  // ACCOUNT_TYPE_CUSTODIAL_IRA
];

export const investmentOptions = [
  "Real estate - rental",
  "Real estate - Flip",
  "Precious metals",
  "Private equity/companies",
  "Stocks/Bonds/Securities",
  "Cryptocurrency",
  "Commodities",
  "Purchase Notes",
  "Tax Liens",
];

// NAICS codes
export const industries = {
  "Accommodation and Food Services": 722310,
  "Accounting & Legal": 541200,
  "Agriculture, Forestry, Fishing and Hunting": 111110,
  "Airlines/Aviation": 488190,
  "Architecture & Design": 541310,
  "Arts, Entertainment, and Recreation": 711110,
  Automotive: 811111,
  "Business Services": 561499,
  Cannabis: 111998,
  "Construction, Repair & Maintenance": 811310,
  "Educational Services": 611710,
  Engineering: 541330,
  "Environmental Services": 541620,
  "Financial Services": 523930,
  Insurance: 524210,
  "Health Care and Social Assistance": 621111,
  "Information Technology and Web Development": 541512,
  "Law Enforcement": 922120,
  "Management Consulting": 541611,
  Manufacturing: 322220,
  "Media (inc Marketing and Advertising)": 541840,
  Military: 928110,
  "Non-Profit Organization Management": 813219,
  "Oil & Energy": 213112,
  "Professional, Scientific, and Technical Services": 541690,
  "Public Administration": 921110,
  "Real Estate and Rental and Leasing": 531390,
  "Retail and Ecommerce": 452990,
  "Transportation and Warehousing": 485999,
  Utilities: 221118,
  "Waste Management and Remediation Services": 562998,
  "Wholesale Trade": 423990,
};

export const USStates = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
