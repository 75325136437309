import { FIREBASE_URLS } from "config/firebase";
import { firebase as firebaseAdmin } from "@firebase/app";
import * as Sentry from '@sentry/react'

import { STATUS } from "config/constants";
import { getParticipant } from "./participant";
import {
  handleCreateSSNs,
  handleSaveNestedBeneficiarySSNs,
  handleCreateAdditionalParticipantSSN,
} from "./ssn";
import { getReferrerFromAuthority, retrieveReferrers } from "./referrers";
import {
  createAdditionalParticipant,
  removeAdditionalParticipant,
} from "./additionalParticipants";

import resolveDataMap, {
  mapParticipantDataToApplication,
  mapApplicationDataToParticipant,
  mapAdditionalParticipantDataToApplication,
  mapAdditionalParticipantData,
} from "../dataMap";

import {
  request,
  getToken,
  forceDownload,
  applicationsCollection,
  participantsCollection,
  addTimestamps,
} from "../requests";

import { getFilenameFromHeader } from "util/helpers";
import { isEmpty } from "lodash";

export async function getApplication(id) {
  if (!id) return null;
  try {
    const doc = await applicationsCollection.doc(id).get();
    if (!doc.exists) return null;
    const data = doc.data();
    data.id = doc.id;
    return data;
  } catch (error) {
    console.error(error);
    // rollbar.error(error, "getApplication");
    Sentry.captureException(error, { extra: { msg: "getApplications" } })
    return null;
  }
}

export async function createApplication(payload, authority) {
  if (!payload) return;
  let savedApplicationId;
  let savedParticipantId;
  try {
    const applicationData = resolveDataMap(payload, "applications");
    const primaryParticipantData = resolveDataMap(payload, "participants");
    const additionalParticipantData = resolveDataMap(
      payload,
      "additionalParticipants"
    );

    // supplement data ////
    [applicationData, primaryParticipantData].forEach((dat) =>
      addTimestamps(dat)
    );
    const backupReferrer = await getReferrerFromAuthority(authority);
    applicationData.referralSource = payload.referralSource || backupReferrer; // backup from authority
    applicationData.providerSource = authority;
    applicationData.submittedAt = null;
    primaryParticipantData.applicationReferrer =
      payload.referralSource || backupReferrer;
    primaryParticipantData.applicationProvider = authority;
    primaryParticipantData.isPrimary = true;
    ///////////////////////
    if (!applicationData.status) {
      applicationData.status = STATUS.DRAFT;
    }
    // // first create an application
    console.log(
      "application data for creation",
      JSON.stringify(applicationData)
    );
    const { id: applicationId } = await applicationsCollection.add(
      applicationData
    );
    savedApplicationId = applicationId;
    // now add the primary participant

    primaryParticipantData.applicationId = applicationId;
    console.log(
      "participant data for creation",
      JSON.stringify(primaryParticipantData)
    );
    const { id: participantId } = await participantsCollection.add(
      primaryParticipantData
    );
    savedParticipantId = participantId;

    console.log("primary participant id", participantId);

    // // now update the application
    async function updateAfterWrite(retries = 3, backoff = 300) {
      try {
        console.log("running update after write");
        await applicationsCollection.doc(applicationId).update({
          primaryParticipantId: participantId,
          applicationRefId: applicationId,
        });
      } catch (err) {
        console.error("failed update after write", err);
        if (retries < 1) {
          console.log("no more retries", err);
          throw err;
        }
        await new Promise((resolve) =>
          setTimeout(() => {
            resolve(updateAfterWrite(retries - 1, backoff * 2));
          }, backoff)
        );
      }
    }

    await updateAfterWrite();
    // handle secondary
    if (applicationData.addAdditionalParticipant) {
      console.log(
        "creating add part",
        JSON.stringify(additionalParticipantData)
      );
      await createAdditionalParticipant(
        additionalParticipantData,
        applicationId,
        null,
        authority,
        applicationData.referralSource || backupReferrer,
        participantId
      );
    }
    // now handle ssn numbers in the data
    console.log("adding nested bens ssns");
    await handleSaveNestedBeneficiarySSNs(
      payload,
      applicationId,
      participantId
    );
    console.log("adding main ssns");
    await handleCreateSSNs(payload, applicationId, participantId);

    return { applicationId, participantId };
  } catch (error) {
    if (!savedParticipantId && savedApplicationId) {
      // we saved an application fine, but not the primary participant
      // we need to rollback the application
      console.log("rollback the application", savedApplicationId);
      await applicationsCollection.doc(savedApplicationId).delete();
    }
    // rollbar.error(error, "createApplication");
    Sentry.captureException(error, { extra: { msg: "createApplication" } })
    throw error;
  }
}

export async function updateApplication(
  applicationId,
  payload,
  primaryParticipantId,
  additionalParticipantId,
  authority,
  referralSource,
  shouldHaveAdditionalParticipant
) {
  if (!applicationId || !payload || !authority) return null;
  console.log("updating application");
  const backupReferrer = await getReferrerFromAuthority(authority);
  console.log("additionall parti", additionalParticipantId);
  const applicationData = resolveDataMap(payload, "applications");
  const primaryParticipantData = resolveDataMap(payload, "participants");
  const additionalParticipantData = resolveDataMap(
    payload,
    "additionalParticipants"
  );
  console.log("add part data", additionalParticipantData);
  console.log("app data", applicationData);

  // update referrer and provider (authority) if present in payload
  if (applicationData && applicationData.referralSource) {
    console.log("updating referrer");
    const referrers = await retrieveReferrers();
    const referrer = referrers.find(
      (item) => item.referralSource === applicationData.referralSource
    );
    const newAuthority = referrer && referrer.authority;
    if (newAuthority) applicationData.providerSource = newAuthority;
    primaryParticipantData.applicationReferrer = applicationData.referralSource;
    if (newAuthority) primaryParticipantData.applicationProvider = newAuthority;
    if (shouldHaveAdditionalParticipant) {
      additionalParticipantData.applicationReferrer =
        applicationData.referralSource;
      if (newAuthority)
        additionalParticipantData.applicationReferrer = newAuthority;
    }
  }

  try {
    if (!isEmpty(applicationData)) {
      applicationData.updatedAt = firebaseAdmin.firestore.FieldValue.serverTimestamp();
      await applicationsCollection.doc(applicationId).update(applicationData);
      console.log("updated application data");
    }
    if (!isEmpty(primaryParticipantData) && primaryParticipantId) {
      primaryParticipantData.updatedAt = firebaseAdmin.firestore.FieldValue.serverTimestamp();
      console.log(
        "updating participant",
        primaryParticipantData,
        "id",
        primaryParticipantId
      );
      await participantsCollection
        .doc(primaryParticipantId)
        .update(primaryParticipantData);
      console.log("saved pri part");
    }
    let addPartId = "";
    if (
      !isEmpty(additionalParticipantData) &&
      shouldHaveAdditionalParticipant
    ) {
      console.log("additional part data", additionalParticipantData);
      // if id already exists we return null here does not duplicate below
      addPartId = await createAdditionalParticipant(
        applicationData,
        applicationId,
        additionalParticipantId,
        authority,
        referralSource || backupReferrer,
        primaryParticipantId
      );
      console.log("add part in req", addPartId);
    }
    console.log(
      "add part data next",
      additionalParticipantData,
      additionalParticipantId
    );
    if (
      !isEmpty(additionalParticipantData) &&
      additionalParticipantId &&
      shouldHaveAdditionalParticipant
    ) {
      // for existing additional participants
      const payload = mapAdditionalParticipantData(additionalParticipantData);
      console.log("@log: payload add part for update", payload);
      payload.updatedAt = firebaseAdmin.firestore.FieldValue.serverTimestamp();
      payload.applicationReferrer = referralSource || backupReferrer;
      await participantsCollection.doc(additionalParticipantId).update(payload);
    }
    // now handle ssn numbers in the data
    console.log("@log: updating app nested bens ssns");
    await handleSaveNestedBeneficiarySSNs(
      payload,
      applicationId,
      primaryParticipantId
    );
    console.log("@log: updating app main ssns");
    await handleCreateSSNs(payload, applicationId, primaryParticipantId);
    console.log("updating add part ssns");
    await handleCreateAdditionalParticipantSSN(
      payload,
      applicationId,
      additionalParticipantId
    );
    if (additionalParticipantId && shouldHaveAdditionalParticipant === false) {
      console.log("@log: removing add part in update app");
      await removeAdditionalParticipant(additionalParticipantId, applicationId);
      addPartId = null;
    }
    return addPartId;
  } catch (error) {
    console.error("update application", error);
    // rollbar.error(error, "updateApplication");
    Sentry.captureException(error, {
      extra: {
        msg: "updateApplication",
        applicationId,
        payload,
        primaryParticipantId,
        additionalParticipantId,
        authority,
        referralSource,
        shouldHaveAdditionalParticipant
      }
  })
    throw error;
  }
}

export async function getAllApplicationData(applicationId) {
  // get application data
  try {
    const application = await getApplication(applicationId);
    if (!application) return null;
    const {
      primaryParticipantId = "",
      additionalParticipantId = "",
    } = application;
    // add primary participants
    const primaryParticipant = await getParticipant(primaryParticipantId);
    if (!primaryParticipant) return application;

    // get additional participant if present and add to data
    if (additionalParticipantId) {
      console.log("fetching additional part data");
      const additionalParticipant = await getParticipant(
        additionalParticipantId
      );
      console.log("additionall particpaont", additionalParticipant);

      mapAdditionalParticipantDataToApplication(
        application,
        additionalParticipant
      );
    }
    mapParticipantDataToApplication(application, primaryParticipant);
    return application;
  } catch (error) {
    console.error(error);
    // rollbar.error(error, "getAllApplicationData");
    Sentry.captureException(error, { extra: { msg: "getAllApplicationData" } })
    return null;
  }
}

/***
 * Used in search
 */
export async function mapApplicationsToParticipants(participants) {
  try {
    await Promise.all(
      participants.map(async (part) => {
        const id = part.applicationId;
        const application = await getApplication(id);
        if (!application) return participants;
        mapApplicationDataToParticipant(part, application);
        return part;
      })
    );
    return participants;
  } catch (error) {
    console.error(error);
    // rollbar.error(error, "map application to participants");
    Sentry.captureException(error, { extra: { msg: "map application to participants" } })
    return [];
  }
}

// download an application
export async function downloadApplications(ids = "") {
  if (!ids) return null;
  try {
    const token = await getToken();
    const res = await request(
      `${FIREBASE_URLS.downloadApplications}?ids=${ids}`,
      {
        headers: {
          "Content-Type": "application/zip",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const filename = getFilenameFromHeader(res.headers);
    const blob = await res.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    forceDownload(blobUrl, filename, false);
  } catch (e) {
    // rollbar.error(e, "download zip");
    Sentry.captureException(e, { extra: { msg: "download zip" } })
    throw e;
  }
}
