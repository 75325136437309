import React from "react";
import { Container, Typography } from "@material-ui/core";

import ApplicationFormWrapper from "components/ApplicationFormWrapper";

const ApplicationsNew = () => {
  return (
    <main>
      <Container maxWidth="md">
        <Typography variant="h4">New Application</Typography>
      </Container>
      <div>
        <ApplicationFormWrapper />
      </div>
    </main>
  );
};

export default ApplicationsNew;
