// for custodial accounts only

import React, { memo, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Button,
  Paper,
  Divider,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Radio,
  Input,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import Accordion from "components/Accordion";
import SSNInput from "components/SSNInput";
import PhoneInput from "components/PhoneInput";
import DatePicker from "components/DatePicker";

import {
  equality,
  mapStatesToOptions,
  mapCountriesToOptions,
} from "util/helpers";
import { ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL } from "config/constants";

import { cloneDeep, sumBy } from "lodash";
import { DateTime } from "luxon";

const Beneficiary = ({
  section,
  classes,
  accountType,
  shouldNameBeneficiary,
  designatedBeneficiaries,
  doesRequireSpousalConsent,
  maritalStatus,
  handleChange,
  updateField,
  spouseApt,
  spouseCity,
  spouseCountry,
  spouseEmail,
  spouseAddressLine1,
  spouseLegalName,
  spousePostcode,
  spouseState,
  spouseSocialSecurity,
  spouseBirthDate,
  readOnly,
  isLoadingBeneficiarySSNs,
  isLoadingSSNs,
}) => {
  // console.log("rendering beneficiary", isLoadingSSNs);

  const handleBeneficiaryDateChange = useCallback(
    (index, type, value) => {
      const formatted = DateTime.fromJSDate(value).toFormat("LL/dd/yyyy");
      if (designatedBeneficiaries === null) return;
      const newBenType = cloneDeep(
        designatedBeneficiaries.filter((ben) => ben.type === type)
      );
      const newBenOther = cloneDeep(
        designatedBeneficiaries.filter((ben) => ben.type !== type)
      );
      newBenType[index]["dob"] = formatted;
      updateField("designatedBeneficiaries", [...newBenType, ...newBenOther]);
    },
    /* eslint-disable-next-line */
    [designatedBeneficiaries]
  );

  const handleBeneficiaryChange = useCallback(
    (e, index, type) => {
      const name = e.target.name;
      const value = e.target.value;
      if (name === "sharePercentage") {
        if (value && parseInt(value) > 100) {
          return;
        }
      }
      if (designatedBeneficiaries === null) return;
      const newBenType = cloneDeep(
        designatedBeneficiaries.filter((ben) => ben.type === type)
      );
      const newBenOther = cloneDeep(
        designatedBeneficiaries.filter((ben) => ben.type !== type)
      );

      newBenType[index][name] = value;
      if (name === "entityType") {
        if (value === "person") {
          newBenType[index]["gender"] = null;
          newBenType[index]["relationshipToAccountHolder"] = "";
          newBenType[index]["beneficiaryTaxId"] = null;
        } else {
          newBenType[index]["relationshipToAccountHolder"] = "";
          newBenType[index]["beneficiarySsn"] = null;
          newBenType[index]["dob"] = "";
        }
      }
      updateField("designatedBeneficiaries", [...newBenType, ...newBenOther]);
    },
    /* eslint-disable-next-line */
    [designatedBeneficiaries]
  );

  useEffect(() => {
    function resolveSpousalConsent() {
      if (!maritalStatus || maritalStatus !== "married") {
        if (doesRequireSpousalConsent) {
          updateField("doesRequireSpousalConsent", false);
        }
        return;
      }
      const spousalReq =
        designatedBeneficiaries &&
        designatedBeneficiaries.find((ben) => {
          if (ben.type === "primary") {
            if (ben.entityType === "nonPerson") return true;
            return (
              ben.relationshipToAccountHolder &&
              ben.relationshipToAccountHolder !== "spouse"
            );
          }
          return false;
        });
      if (spousalReq) {
        if (!doesRequireSpousalConsent) {
          updateField("doesRequireSpousalConsent", true);
        }
      } else {
        if (doesRequireSpousalConsent) {
          updateField("doesRequireSpousalConsent", false);
        }
      }
    }
    resolveSpousalConsent();
  }, [
    designatedBeneficiaries,
    maritalStatus,
    doesRequireSpousalConsent,
    updateField,
  ]);

  function addBeneficiary(type) {
    const obj = {
      email: "",
      phoneNumber: "",
      mailingAddressLine1: "",
      mailingAddressApt: "",
      mailingAddressCity: "",
      mailingAddressState: "",
      mailingAddressCountry: "",
      mailingAddressPostcode: "",
      entityType: "",
      dob: "",
      gender: "",
      legalName: "",
      relationshipToAccountHolder: "",
      preferredContactMethod: null,
      sharePercentage: "",
      type: type,
    };
    const newBen = cloneDeep(designatedBeneficiaries);

    newBen.push(obj);
    updateField("designatedBeneficiaries", newBen);
  }

  function removeBeneficiary(index, type) {
    let newBenType = cloneDeep(
      designatedBeneficiaries.filter((ben) => ben.type === type)
    );
    let newBenOther = cloneDeep(
      designatedBeneficiaries.filter((ben) => ben.type !== type)
    );
    newBenType = newBenType.filter((_, i) => i !== index);
    updateField("designatedBeneficiaries", [...newBenType, ...newBenOther]);
  }

  const totalPrimaryShare =
    (designatedBeneficiaries &&
      sumBy(
        designatedBeneficiaries.filter((ben) => ben.type === "primary"),
        (item) => {
          return parseFloat(item.sharePercentage || 0);
        }
      )) ||
    0;

  const totalContingentShare =
    (designatedBeneficiaries &&
      sumBy(
        designatedBeneficiaries.filter((ben) => ben.type === "contingent"),
        (item) => {
          return parseFloat(item.sharePercentage || 0);
        }
      )) ||
    0;

  const SpouseSsn = useMemo(
    () => (
      <Box mt={2} mb={4}>
        <SSNInput
          label="Spouse Social Security Number"
          name="spouseSocialSecurity"
          value={spouseSocialSecurity}
          handleChange={handleChange}
          readOnly={readOnly || isLoadingSSNs}
          isLoading={isLoadingSSNs}
        />
      </Box>
    ),
    /* eslint-disable-next-line */
    [spouseSocialSecurity, readOnly, isLoadingSSNs]
  );
  return (
    <>
      {accountType === ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section}-content`}
            id={`${section}-header`}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Typography variant="h6">Beneficiary Information</Typography>
              {/* <Typography variant="button">Incomplete</Typography> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.section}>
              <Box>
                <Box my={3}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" disabled>
                      {
                        "The applicant must provide a beneficiary for this account type"
                      }
                    </FormLabel>
                    <RadioGroup
                      aria-label={"shouldNameBeneficiary"}
                      name={"shouldNameBeneficiary"}
                      value={shouldNameBeneficiary || designatedBeneficiaries.length > 0}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio disabled />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio disabled />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <>
                  {(shouldNameBeneficiary || designatedBeneficiaries.length > 0) && (
                    <Box>
                      {/* primary */}
                      <Box mb={3}>
                        <Paper>
                          <Box p={2}>
                            <Box mb={1}>
                              <Typography>
                                <b>Primary Beneficiary Information</b>
                              </Typography>
                            </Box>
                            {designatedBeneficiaries.length > 0
                              ? designatedBeneficiaries
                                  .filter((ben) => ben.type === "primary")
                                  .map((beneficiary, i) => {
                                    return (
                                      <Box key={`pri-${i}`}>
                                        {i === 0 && <Divider />}
                                        <Box key={i} mt={i > 0 ? 4 : 1}>
                                          <Typography
                                            variant="body2"
                                            color="secondary"
                                          >
                                            Primary Beneficiary {i + 1}
                                          </Typography>
                                          <Box mt={3}>
                                            <Box mb={3}>
                                              <FormLabel component="legend">
                                                Entity type
                                              </FormLabel>
                                              <FormControl component="fieldset">
                                                <RadioGroup
                                                  aria-label="entityType"
                                                  name="entityType"
                                                  value={beneficiary.entityType}
                                                  onChange={(e) =>
                                                    handleBeneficiaryChange(
                                                      e,
                                                      i,
                                                      "primary"
                                                    )
                                                  }
                                                  row
                                                >
                                                  <FormControlLabel
                                                    value="person"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Person"
                                                  />
                                                  <FormControlLabel
                                                    value="nonPerson"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Non-Person (Business, Trust, etc)"
                                                  />
                                                </RadioGroup>
                                              </FormControl>
                                            </Box>
                                            <Box mb={3}>
                                              <TextField
                                                placeholder="Legal name"
                                                fullWidth
                                                value={
                                                  beneficiary.legalName || ""
                                                }
                                                name="legalName"
                                                onChange={(e) =>
                                                  handleBeneficiaryChange(
                                                    e,
                                                    i,
                                                    "primary"
                                                  )
                                                }
                                                inputProps={{
                                                  readOnly: Boolean(readOnly),
                                                }}
                                              />
                                            </Box>
                                            <Box>
                                              {beneficiary.entityType ? (
                                                beneficiary.entityType ===
                                                "person" ? (
                                                  <Box mb={3}>
                                                    <SSNInput
                                                      label="Social Security Number"
                                                      name="beneficiarySsn"
                                                      id={`beneficiarySsn-${i}`}
                                                      value={
                                                        beneficiary.beneficiarySsn ||
                                                        ""
                                                      }
                                                      handleChange={(e) =>
                                                        handleBeneficiaryChange(
                                                          e,
                                                          i,
                                                          "primary"
                                                        )
                                                      }
                                                      readOnly={
                                                        readOnly ||
                                                        isLoadingBeneficiarySSNs
                                                      }
                                                      isLoading={
                                                        isLoadingBeneficiarySSNs
                                                      }
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Box mb={3}>
                                                    <>
                                                      <SSNInput
                                                        label="Tax id"
                                                        name="beneficiaryTaxId"
                                                        id={`beneficiaryTaxId-${i}`}
                                                        maskType="taxId"
                                                        value={
                                                          beneficiary.beneficiaryTaxId ||
                                                          ""
                                                        }
                                                        handleChange={(e) =>
                                                          handleBeneficiaryChange(
                                                            e,
                                                            i,
                                                            "primary"
                                                          )
                                                        }
                                                        readOnly={
                                                          readOnly ||
                                                          isLoadingBeneficiarySSNs
                                                        }
                                                        isLoading={
                                                          isLoadingBeneficiarySSNs
                                                        }
                                                      />
                                                    </>
                                                  </Box>
                                                )
                                              ) : null}
                                              <Box mb={3}>
                                                <>
                                                  <TextField
                                                    type="number"
                                                    fullWidth
                                                    placeholder="Enter %"
                                                    value={
                                                      beneficiary.sharePercentage ||
                                                      ""
                                                    }
                                                    name="sharePercentage"
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    inputProps={{
                                                      min: 0,
                                                      max: 100,
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                    InputProps={{
                                                      endAdornment: "%",
                                                    }}
                                                  />
                                                </>
                                              </Box>
                                            </Box>
                                            {beneficiary.entityType ===
                                              "person" && (
                                              <Box mb={3}>
                                                <FormLabel component="legend">
                                                  Beneficiary gender
                                                </FormLabel>
                                                <FormControl component="fieldset">
                                                  <RadioGroup
                                                    aria-label="gender"
                                                    name="gender"
                                                    value={
                                                      beneficiary.gender || null
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    row
                                                  >
                                                    <FormControlLabel
                                                      value="female"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Female"
                                                    />
                                                    <FormControlLabel
                                                      value="male"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Male"
                                                    />
                                                  </RadioGroup>
                                                </FormControl>
                                              </Box>
                                            )}
                                            {beneficiary.entityType ===
                                              "person" && (
                                              <Box mb={3}>
                                                <FormLabel component="legend">
                                                  {"Birth date"}
                                                </FormLabel>
                                                <DatePicker
                                                  id={`datepicker-ben-birthDate-${i}`}
                                                  initialDate={beneficiary.dob}
                                                  dateHandler={(val) =>
                                                    handleBeneficiaryDateChange(
                                                      i,
                                                      "primary",
                                                      val
                                                    )
                                                  }
                                                />
                                                {/* <FormLabel component="legend">
                                                  Beneficiary gender
                                                </FormLabel>
                                                <FormControl component="fieldset">
                                                  <RadioGroup
                                                    aria-label="gender"
                                                    name="gender"
                                                    value={
                                                      beneficiary.gender || null
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    row
                                                  >
                                                    <FormControlLabel
                                                      value="female"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Female"
                                                    />
                                                    <FormControlLabel
                                                      value="male"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Male"
                                                    />
                                                  </RadioGroup>
                                                </FormControl> */}
                                              </Box>
                                            )}
                                            {beneficiary.entityType ===
                                              "person" && (
                                              <Box mb={3}>
                                                <FormLabel component="legend">
                                                  Relationship to account holder
                                                </FormLabel>
                                                <FormControl component="fieldset">
                                                  <RadioGroup
                                                    aria-label="relationshipToAccountHolder"
                                                    name="relationshipToAccountHolder"
                                                    value={
                                                      beneficiary.relationshipToAccountHolder ||
                                                      null
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    row
                                                  >
                                                    <FormControlLabel
                                                      value="spouse"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Spouse"
                                                    />
                                                    <FormControlLabel
                                                      value="non-spouse"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Non-spouse"
                                                    />
                                                  </RadioGroup>
                                                </FormControl>
                                              </Box>
                                            )}
                                            <Box mb={3}>
                                              <FormLabel component="legend">
                                                Preferred Contact Method
                                              </FormLabel>
                                              <FormControl component="fieldset">
                                                <RadioGroup
                                                  aria-label="preferredContactMethod"
                                                  name="preferredContactMethod"
                                                  value={
                                                    beneficiary.preferredContactMethod ||
                                                    null
                                                  }
                                                  onChange={(e) =>
                                                    handleBeneficiaryChange(
                                                      e,
                                                      i,
                                                      "primary"
                                                    )
                                                  }
                                                  row
                                                >
                                                  <FormControlLabel
                                                    value="email"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Email"
                                                  />
                                                  <FormControlLabel
                                                    value="phoneNumber"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Phone"
                                                  />
                                                  <FormControlLabel
                                                    value="mailingAddress"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Mailing address"
                                                  />
                                                </RadioGroup>
                                              </FormControl>
                                            </Box>
                                            {beneficiary.preferredContactMethod ===
                                              "email" && (
                                              <Box mb={3}>
                                                <TextField
                                                  name="email"
                                                  value={
                                                    beneficiary.email || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleBeneficiaryChange(
                                                      e,
                                                      i,
                                                      "primary"
                                                    )
                                                  }
                                                  placeholder="Enter email address"
                                                  fullWidth
                                                  inputProps={{
                                                    readOnly: Boolean(readOnly),
                                                  }}
                                                />
                                              </Box>
                                            )}
                                            {beneficiary.preferredContactMethod ===
                                              "phoneNumber" && (
                                              <Box mb={3}>
                                                <FormControl fullWidth>
                                                  <InputLabel htmlFor="phoneNumber">
                                                    Phone Number
                                                  </InputLabel>
                                                  <Input
                                                    fullWidth
                                                    value={
                                                      beneficiary.phoneNumber ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    name="phoneNumber"
                                                    id={`phoneNumber-${i}-primary`}
                                                    inputComponent={PhoneInput}
                                                    readOnly={readOnly}
                                                  />
                                                </FormControl>
                                              </Box>
                                            )}
                                            {beneficiary.preferredContactMethod ===
                                              "mailingAddress" && (
                                              <>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressLine1"
                                                    value={
                                                      beneficiary.mailingAddressLine1 ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    placeholder="Address line 1"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressApt"
                                                    value={
                                                      beneficiary.mailingAddressApt ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    placeholder="Apt or Suite"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressCity"
                                                    value={
                                                      beneficiary.mailingAddressCity ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    placeholder="City"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <InputLabel
                                                    className={
                                                      classes.selectLabel
                                                    }
                                                  >
                                                    State
                                                  </InputLabel>
                                                  <Select
                                                    fullWidth
                                                    labelId="mailingAddressState"
                                                    name="mailingAddressState"
                                                    value={
                                                      beneficiary.mailingAddressState ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    readOnly={readOnly}
                                                  >
                                                    {mapStatesToOptions().map(
                                                      (opt) => (
                                                        <MenuItem
                                                          key={opt.value}
                                                          value={opt.value}
                                                        >
                                                          {opt.name}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </Select>
                                                </Box>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressPostcode"
                                                    value={
                                                      beneficiary.mailingAddressPostcode ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    placeholder="zip or postcode"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <InputLabel
                                                    className={
                                                      classes.selectLabel
                                                    }
                                                  >
                                                    Country
                                                  </InputLabel>
                                                  <Select
                                                    fullWidth
                                                    name="mailingAddressCountry"
                                                    id="mailingAddressCountry"
                                                    value={
                                                      beneficiary.mailingAddressCountry ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "primary"
                                                      )
                                                    }
                                                    readOnly={readOnly}
                                                  >
                                                    {mapCountriesToOptions().map(
                                                      (opt) => (
                                                        <MenuItem
                                                          key={opt.value}
                                                          value={opt.value}
                                                        >
                                                          {opt.name}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </Select>
                                                </Box>
                                              </>
                                            )}
                                          </Box>
                                          <Box
                                            justifyContent="left"
                                            alignItems="left"
                                          >
                                            <Button
                                              variant="text"
                                              color="secondary"
                                              size="small"
                                              disabled={readOnly}
                                              onClick={() =>
                                                removeBeneficiary(i, "primary")
                                              }
                                            >
                                              <RemoveIcon />
                                              {`  REMOVE BENEFICIARY`}
                                            </Button>
                                          </Box>
                                        </Box>
                                        <Divider />
                                      </Box>
                                    );
                                  })
                              : null}
                            {designatedBeneficiaries.length !== 0 && (
                              <Box my={4}>
                                <Box
                                  display="flex"
                                  flex={1}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography variant="body1">
                                    Total primary share % allocated
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    color={
                                      totalPrimaryShare === 100
                                        ? "inherit"
                                        : "error"
                                    }
                                  >
                                    {totalPrimaryShare}/100%
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="caption">
                                    Must add up to 100%
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                variant="text"
                                color="primary"
                                size="small"
                                disabled={
                                  designatedBeneficiaries.length >= 3 ||
                                  readOnly
                                }
                                onClick={() => addBeneficiary("primary")}
                              >
                                <AddIcon />
                                {`  ADD PRIMARY BENEFICIARY`}
                              </Button>
                            </Box>
                            <Box>
                              {designatedBeneficiaries.length >= 3 && (
                                <Typography variant="caption">
                                  Up to 3 beneficiaries allowed
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                      {/* contingent */}
                      <Box>
                        <Paper>
                          <Box p={2}>
                            <Box mb={1}>
                              <Typography>
                                <b>Contingent Beneficiary Information</b>
                              </Typography>
                            </Box>
                            {designatedBeneficiaries.length > 0
                              ? designatedBeneficiaries
                                  .filter((ben) => ben.type === "contingent")
                                  .map((beneficiary, i) => {
                                    return (
                                      <Box key={`cont-${i}`}>
                                        {i === 0 && <Divider />}
                                        <Box key={i} mt={i > 0 ? 4 : 1}>
                                          <Typography
                                            variant="body2"
                                            colosr="secondary"
                                          >
                                            Contingent Beneficiary {i + 1}
                                          </Typography>
                                          <Box mt={3}>
                                            <Box mb={3}>
                                              <FormLabel component="legend">
                                                Entity type
                                              </FormLabel>
                                              <FormControl component="fieldset">
                                                <RadioGroup
                                                  aria-label="entityType"
                                                  name="entityType"
                                                  value={beneficiary.entityType}
                                                  onChange={(e) =>
                                                    handleBeneficiaryChange(
                                                      e,
                                                      i,
                                                      "contingent"
                                                    )
                                                  }
                                                  row
                                                >
                                                  <FormControlLabel
                                                    value="person"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Person"
                                                  />
                                                  <FormControlLabel
                                                    value="nonPerson"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Non-Person (Business, Trust, etc)"
                                                  />
                                                </RadioGroup>
                                              </FormControl>
                                            </Box>
                                            <Box mb={3}>
                                              <TextField
                                                placeholder="Legal name"
                                                fullWidth
                                                value={
                                                  beneficiary.legalName || ""
                                                }
                                                name="legalName"
                                                onChange={(e) =>
                                                  handleBeneficiaryChange(
                                                    e,
                                                    i,
                                                    "contingent"
                                                  )
                                                }
                                                inputProps={{
                                                  readOnly: Boolean(readOnly),
                                                }}
                                              />
                                            </Box>
                                            <Box>
                                              {beneficiary.entityType ? (
                                                beneficiary.entityType ===
                                                "person" ? (
                                                  <Box mb={3}>
                                                    <SSNInput
                                                      id={`beneficiarySsn-${i}`}
                                                      label="Social Security Number"
                                                      name="beneficiarySsn"
                                                      value={
                                                        beneficiary.beneficiarySsn ||
                                                        ""
                                                      }
                                                      handleChange={(e) =>
                                                        handleBeneficiaryChange(
                                                          e,
                                                          i,
                                                          "contingent"
                                                        )
                                                      }
                                                      readOnly={
                                                        readOnly ||
                                                        isLoadingBeneficiarySSNs
                                                      }
                                                      isLoading={
                                                        isLoadingBeneficiarySSNs
                                                      }
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Box mb={3}>
                                                    <>
                                                      <SSNInput
                                                        label="Tax id"
                                                        name="beneficiaryTaxId"
                                                        id={`beneficiaryTaxId-${i}`}
                                                        value={
                                                          beneficiary.beneficiaryTaxId ||
                                                          ""
                                                        }
                                                        handleChange={(e) =>
                                                          handleBeneficiaryChange(
                                                            e,
                                                            i,
                                                            "contingent"
                                                          )
                                                        }
                                                        readOnly={
                                                          readOnly ||
                                                          isLoadingBeneficiarySSNs
                                                        }
                                                        isLoading={
                                                          isLoadingBeneficiarySSNs
                                                        }
                                                      />
                                                    </>
                                                  </Box>
                                                )
                                              ) : null}
                                              <Box mb={3}>
                                                <>
                                                  <TextField
                                                    type="number"
                                                    fullWidth
                                                    placeholder="Enter %"
                                                    value={
                                                      beneficiary.sharePercentage ||
                                                      ""
                                                    }
                                                    name="sharePercentage"
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    inputProps={{
                                                      min: 0,
                                                      max: 100,
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                    InputProps={{
                                                      endAdornment: "%",
                                                    }}
                                                  />
                                                </>
                                              </Box>
                                            </Box>
                                            {beneficiary.entityType ===
                                              "person" && (
                                              <Box mb={3}>
                                                <FormLabel component="legend">
                                                  Beneficiary gender
                                                </FormLabel>
                                                <FormControl component="fieldset">
                                                  <RadioGroup
                                                    aria-label="gender"
                                                    name="gender"
                                                    value={
                                                      beneficiary.gender || null
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    row
                                                  >
                                                    <FormControlLabel
                                                      value="female"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Female"
                                                    />
                                                    <FormControlLabel
                                                      value="male"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Male"
                                                    />
                                                  </RadioGroup>
                                                </FormControl>
                                              </Box>
                                            )}
                                            {beneficiary.entityType ===
                                              "person" && (
                                              <Box mb={3}>
                                                <FormLabel component="legend">
                                                  Relationship to account holder
                                                </FormLabel>
                                                <FormControl component="fieldset">
                                                  <RadioGroup
                                                    aria-label="relationshipToAccountHolder"
                                                    name="relationshipToAccountHolder"
                                                    value={
                                                      beneficiary.relationshipToAccountHolder ||
                                                      null
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    row
                                                  >
                                                    <FormControlLabel
                                                      value="spouse"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Spouse"
                                                    />
                                                    <FormControlLabel
                                                      value="non-spouse"
                                                      control={
                                                        <Radio
                                                          disabled={readOnly}
                                                        />
                                                      }
                                                      label="Non-spouse"
                                                    />
                                                  </RadioGroup>
                                                </FormControl>
                                              </Box>
                                            )}
                                            <Box mb={3}>
                                              <FormLabel component="legend">
                                                Preferred Contact Method
                                              </FormLabel>
                                              <FormControl component="fieldset">
                                                <RadioGroup
                                                  aria-label="preferredContactMethod"
                                                  name="preferredContactMethod"
                                                  value={
                                                    beneficiary.preferredContactMethod ||
                                                    null
                                                  }
                                                  onChange={(e) =>
                                                    handleBeneficiaryChange(
                                                      e,
                                                      i,
                                                      "contingent"
                                                    )
                                                  }
                                                  row
                                                >
                                                  <FormControlLabel
                                                    value="email"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Email"
                                                  />
                                                  <FormControlLabel
                                                    value="phoneNumber"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Phone"
                                                  />
                                                  <FormControlLabel
                                                    value="mailingAddress"
                                                    control={
                                                      <Radio
                                                        disabled={readOnly}
                                                      />
                                                    }
                                                    label="Mailing address"
                                                  />
                                                </RadioGroup>
                                              </FormControl>
                                            </Box>
                                            {beneficiary.preferredContactMethod ===
                                              "email" && (
                                              <Box mb={3}>
                                                <TextField
                                                  name="email"
                                                  value={
                                                    beneficiary.email || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleBeneficiaryChange(
                                                      e,
                                                      i,
                                                      "contingent"
                                                    )
                                                  }
                                                  placeholder="Enter email address"
                                                  fullWidth
                                                  inputProps={{
                                                    readOnly: Boolean(readOnly),
                                                  }}
                                                />
                                              </Box>
                                            )}
                                            {beneficiary.preferredContactMethod ===
                                              "phoneNumber" && (
                                              <Box mb={3}>
                                                <FormControl fullWidth>
                                                  <InputLabel htmlFor="phoneNumber">
                                                    Phone Number
                                                  </InputLabel>
                                                  <Input
                                                    fullWidth
                                                    value={
                                                      beneficiary.phoneNumber ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    name="phoneNumber"
                                                    id={`phoneNumber-${i}-contingent`}
                                                    inputComponent={PhoneInput}
                                                    readOnly={readOnly}
                                                  />
                                                </FormControl>
                                              </Box>
                                            )}
                                            {beneficiary.preferredContactMethod ===
                                              "mailingAddress" && (
                                              <>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressLine1"
                                                    value={
                                                      beneficiary.mailingAddressLine1 ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    placeholder="Address line 1"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressApt"
                                                    value={
                                                      beneficiary.mailingAddressApt ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    placeholder="Apt or Suite"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressCity"
                                                    value={
                                                      beneficiary.mailingAddressCity ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    placeholder="City"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <InputLabel
                                                    className={
                                                      classes.selectLabel
                                                    }
                                                  >
                                                    State
                                                  </InputLabel>
                                                  <Select
                                                    fullWidth
                                                    labelId="mailingAddressState"
                                                    name="mailingAddressState"
                                                    value={
                                                      beneficiary.mailingAddressState ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    readOnly={readOnly}
                                                  >
                                                    {mapStatesToOptions().map(
                                                      (opt) => (
                                                        <MenuItem
                                                          key={opt.value}
                                                          value={opt.value}
                                                        >
                                                          {opt.name}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </Select>
                                                </Box>
                                                <Box mb={3}>
                                                  <TextField
                                                    name="mailingAddressPostcode"
                                                    value={
                                                      beneficiary.mailingAddressPostcode ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    placeholder="zip or postcode"
                                                    fullWidth
                                                    inputProps={{
                                                      readOnly: Boolean(
                                                        readOnly
                                                      ),
                                                    }}
                                                  />
                                                </Box>
                                                <Box mb={3}>
                                                  <InputLabel
                                                    className={
                                                      classes.selectLabel
                                                    }
                                                  >
                                                    Country
                                                  </InputLabel>
                                                  <Select
                                                    fullWidth
                                                    name="mailingAddressCountry"
                                                    id="mailingAddressCountry"
                                                    value={
                                                      beneficiary.mailingAddressCountry ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBeneficiaryChange(
                                                        e,
                                                        i,
                                                        "contingent"
                                                      )
                                                    }
                                                    readOnly={readOnly}
                                                  >
                                                    {mapCountriesToOptions().map(
                                                      (opt) => (
                                                        <MenuItem
                                                          key={opt.value}
                                                          value={opt.value}
                                                        >
                                                          {opt.name}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </Select>
                                                </Box>
                                              </>
                                            )}
                                          </Box>
                                          <Box
                                            justifyContent="left"
                                            alignItems="left"
                                          >
                                            <Button
                                              variant="text"
                                              color="secondary"
                                              size="small"
                                              disabled={readOnly}
                                              onClick={() =>
                                                removeBeneficiary(
                                                  i,
                                                  "contingent"
                                                )
                                              }
                                            >
                                              <RemoveIcon />
                                              {`  REMOVE BENEFICIARY`}
                                            </Button>
                                          </Box>
                                        </Box>
                                        <Divider />
                                      </Box>
                                    );
                                  })
                              : null}
                            {designatedBeneficiaries.length !== 0 && (
                              <Box my={4}>
                                <Box
                                  display="flex"
                                  flex={1}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography variant="body1">
                                    Total contingent share % allocated
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    color={
                                      totalContingentShare === 100
                                        ? "inherit"
                                        : "error"
                                    }
                                  >
                                    {totalContingentShare}/100%
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="caption">
                                    Must add up to 100% if contingent present
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                variant="text"
                                color="primary"
                                size="small"
                                disabled={
                                  designatedBeneficiaries.length >= 3 ||
                                  readOnly
                                }
                                onClick={() => addBeneficiary("contingent")}
                              >
                                <AddIcon />
                                {`  ADD CONTINGENT BENEFICIARY`}
                              </Button>
                            </Box>
                            <Box>
                              {designatedBeneficiaries.length >= 3 && (
                                <Typography variant="caption">
                                  Up to 3 beneficiaries allowed
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    </Box>
                  )}
                </>
              </Box>
              <Box>
                {doesRequireSpousalConsent && (
                  <Box my={3}>
                    <Box mb={2}>
                      <Typography>Spousal consent required</Typography>
                    </Box>
                    <Box>
                      <Box mb={3}>
                        <TextField
                          placeholder="Spouse legal name"
                          fullWidth
                          value={spouseLegalName || ""}
                          name="spouseLegalName"
                          id="spouseLegalName"
                          onChange={handleChange}
                        />
                      </Box>
                      <Box mb={3}>
                        <TextField
                          name="spouseEmail"
                          id="spouseEmail"
                          value={spouseEmail || ""}
                          type="email"
                          onChange={handleChange}
                          placeholder="Enter spouse email address"
                          fullWidth
                          inputProps={{ readOnly: Boolean(readOnly) }}
                        />
                      </Box>
                      <Box mb={3}>
                        <FormLabel component="legend">
                          {"Enter spouse birth date"}
                        </FormLabel>
                        <DatePicker
                          id={`datepicker-spouseBirthDate`}
                          initialDate={spouseBirthDate}
                          dateHandler={updateField.bind(
                            null,
                            "spouseBirthDate"
                          )}
                          disabled={readOnly}
                        />
                      </Box>
                      {SpouseSsn}
                      <Box mt={3} mb={1}>
                        <Typography>Spouse Address Info</Typography>
                      </Box>
                      <Box mb={3}>
                        <TextField
                          id="spouseAddressLine1"
                          name="spouseAddressLine1"
                          value={spouseAddressLine1 || ""}
                          onChange={handleChange}
                          placeholder="Spouse Address line 1"
                          fullWidth
                          inputProps={{ readOnly: Boolean(readOnly) }}
                        />
                      </Box>
                      <Box mb={3}>
                        <TextField
                          id="spouseApt"
                          name="spouseApt"
                          value={spouseApt || ""}
                          onChange={handleChange}
                          placeholder="Apt or Suite"
                          fullWidth
                          inputProps={{ readOnly: Boolean(readOnly) }}
                        />
                      </Box>
                      <Box mb={3}>
                        <TextField
                          id="spouseCity"
                          name="spouseCity"
                          value={spouseCity || ""}
                          onChange={handleChange}
                          placeholder="City"
                          fullWidth
                          inputProps={{ readOnly: Boolean(readOnly) }}
                        />
                      </Box>
                      <Box mb={3}>
                        <InputLabel className={classes.selectLabel}>
                          State
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="spouseState"
                          name="spouseState"
                          value={spouseState || ""}
                          onChange={handleChange}
                          readOnly={readOnly}
                        >
                          {mapStatesToOptions().map((opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                              {opt.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mb={3}>
                        <TextField
                          id="spousePostcode"
                          name="spousePostcode"
                          value={spousePostcode || ""}
                          onChange={handleChange}
                          placeholder="zip or postcode"
                          fullWidth
                          inputProps={{ readOnly: Boolean(readOnly) }}
                        />
                      </Box>
                      <Box mb={3}>
                        <InputLabel className={classes.selectLabel}>
                          Country
                        </InputLabel>
                        <Select
                          fullWidth
                          name="spouseCountry"
                          id="spouseCountry"
                          value={spouseCountry || ""}
                          onChange={handleChange}
                          readOnly={readOnly}
                        >
                          {mapCountriesToOptions().map((opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                              {opt.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

Beneficiary.defaultProps = {
  shouldNameBeneficiary: null,
  accountType: "",
  designatedBeneficiaries: [],
  doesRequireSpousalConsent: false,
  maritalStatus: "",
  spouseApt: "",
  spouseCity: "",
  spouseCountry: "",
  spouseEmail: "",
  spouseAddressLine1: "",
  spouseLegalName: "",
  spousePostcode: "",
  spouseState: "",
  readOnly: false,
};

Beneficiary.propTypes = {
  shouldNameBeneficiary: PropTypes.bool,
  accountType: PropTypes.string,
  designatedBeneficiaries: PropTypes.array,
  doesRequireSpousalConsent: PropTypes.bool,
  maritalStatus: PropTypes.string,
  spouseApt: PropTypes.string,
  spouseCity: PropTypes.string,
  spouseCountry: PropTypes.string,
  spouseEmail: PropTypes.string,
  spouseAddressLine1: PropTypes.string,
  spouseLegalName: PropTypes.string,
  spousePostcode: PropTypes.string,
  spouseState: PropTypes.string,
  readOnly: PropTypes.bool,
  isLoadingBeneficiarySSNs: PropTypes.bool,
};

const inputs = [
  "shouldNameBeneficiary",
  "accountType",
  "designatedBeneficiaries",
  "doesRequireSpousalConsent",
  "maritalStatus",
  "spouseApt",
  "spouseCity",
  "spouseCountry",
  "spouseEmail",
  "spouseBirthDate",
  "spouseSocialSecurity",
  "spouseAddressLine1",
  "spouseLegalName",
  "spousePostcode",
  "spouseState",
  "readOnly",
  "isLoadingBeneficiarySSNs",
  "isLoadingSSNs",
];

export default memo(Beneficiary, (prev, next) => equality(prev, next, inputs));
