import { DateTime } from "luxon";
import { convertTimestampToText } from "util/helpers";

const screenWidth = window.innerWidth;
const widthPadding = 40;

const APPLICATION_CREATION_ERROR =
  "ERROR CREATING APPLICATION! PLEASE CREATE A NEW APPLICATION.";

const colWidths = {
  fullName: 0.18,
  accountType: 0.15,
  additionalParticipantId: 0.07,
  assignedTo: 0.13,
  createdBy: 0.13,
  referralSource: 0.13,
  updatedAt: 0.15,
};

function width(col) {
  return (screenWidth - widthPadding) * colWidths[col];
}

export const columns = [
  {
    field: "id",
    hide: process.env.NODE_ENV === "development" ? false : true,
  },
  { field: "status", hide: true },
  {
    field: "fullName",
    headerName: "Full name",
    width: width("fullName"),
    cellClassName: (params) => {
      return params.value === APPLICATION_CREATION_ERROR ? "warning" : "";
    },
  },
  {
    field: "accountType",
    headerName: "Account type",
    width: width("accountType"),
  },
  {
    field: "addAdditionalParticipant",
    headerName: "Add. participant",
    width: width("additionalParticipantId"),
  },
  { field: "assignedTo", headerName: "Assigned", width: width("assignedTo") },
  { field: "createdBy", headerName: "Created by", width: width("createdBy") },
  {
    field: "referralSource",
    headerName: "Referrer",
    width: width("referralSource"),
  },
  {
    field: "updatedAt",
    headerName: `Last updated (${DateTime.local().toFormat("ZZZZ")})`,
    valueFormatter: (data) => {
      return convertTimestampToText(data.value, "LL/dd/yyyy hh:mm a");
    },
    width: width("updatedAt"),
  },
];

export function transform(data, other) {
  return data.map((row, index) => {
    other.index = index;
    return colMap(row, other);
  });
}

export function transformRow(data, other) {
  return colMap(data, other);
}

export function colMap(data, other) {
  const allAdmins = (other && other.allAdmins) || [];
  const updatedAt = (data.updatedAt && data.updatedAt.toDate()) || "";

  let assignedTo = ""
  if (other && Array.isArray(other.allAdmins)) {
    const admin = allAdmins.find((it) => it.adminId === data.assignedTo)
    if (admin) {
      assignedTo = admin.lastName || admin.email
    }
  }

  let fullName = ""
  if (data.lastName) {
    fullName = `${data.lastName || ""}, ${data.firstName || ""} ${data.middleName || ""}`
  } else if (!data.primaryParticipantId) {
    fullName = APPLICATION_CREATION_ERROR
  }

  // RM: Swapped the above if for the original code below. I am not really sure
  //     why the fullName should be set to APPLICATION_CREATION_ERROR when the
  //     lastName field and the primaryParticipantId field are falsey. This
  //     function has been a source of Sentry errors so my intention is to fix
  //     logical problems without changing functionality. Will have to revisit.
  //
  // Original code handling fullName:
  /*
    data.lastName
      ? `${data.lastName || ""}, ${data.firstName || ""} ${data.middleName || ""
      }`
      : data.primaryParticipantId
        ? ""
        : APPLICATION_CREATION_ERROR,
  */

  return {
    id: `${data.id}-${other?.index || Math.random()}` || "",
    status: data.status || "",
    accountType: data.accountType || "",
    addAdditionalParticipant: !!data.addAdditionalParticipant ? "Yes" : "No",
    referralSource: data.referralSource || "",
    createdBy: data.createdByUserType || "",
    assignedTo,
    fullName,
    updatedAt
  };
}

export const sortModel = [
  {
    field: "updatedAt",
    sort: "desc",
  },
];
