import crypto from "crypto-js";
import { FIREBASE_URLS } from "config/firebase";
import {
  getToken,
  request,
  applicationsCollection,
  participantsCollection,
} from "../requests";
import resolveDataMap, {
  ssnDataMap,
  additionalParticipantSsnDataMap,
  nestedSSNDataMap,
} from "../dataMap";
import { cleanData } from "../helpers";
import { cloneDeep } from "lodash";

import * as Sentry from '@sentry/react'

const publicKey = process.env.REACT_APP_ENCRYPT_PUBLIC_KEY;

export async function handleCreateSSNs(data, ...ids) {
  console.log("data in func", data);
  try {
    if (!data) return;
    const arr = Object.keys(data).filter((item) => {
      return ssnDataMap[item];
    });

    const promises = () =>
      Promise.all(
        arr.map((key) => {
          const collection = ssnDataMap[key][1];
          const firestoreKey = ssnDataMap[key][0];
          const value = data[key];

          const callback = async (val) => {
            if (val.stack) throw Error(val);

            if (collection === "participants") {
              await participantsCollection.doc(ids[1]).update({
                [firestoreKey]: val,
              });
            }
            if (collection === "applications") {
              await applicationsCollection.doc(ids[0]).update({
                [firestoreKey]: val,
              });
            }
          };
          const ssn = new SocialSecurity();
          return ssn.createSSN(value, callback);
        })
      );
    return await promises();
  } catch (error) {
    console.error("handle create ssn", error);
    // rollbar.error(error, "handleCreateSSNs");
    Sentry.captureException(error, { extra: { msg: "handleCreateSSNs" } })
    throw Error(`${error.message}: create ssn`);
  }
}

export async function handleFetchNestedBeneficiarySSNs(data) {
  // add data to beneficiary and return updated array
  try {
    const key = "designatedBeneficiaries";
    const copyBen = (data[key] && cloneDeep(data[key])) || null;
    if (!copyBen) return null;
    await Promise.all(
      // loop through designatedBenficiaries
      copyBen.map((beneficiary) => {
        let keyToFetch = "";
        let keyToUpdate = "";
        const keysToFetch = [
          { dbRef: "taxIdRef", ref: "beneficiaryTaxId" },
          { dbRef: "ssnRef", ref: "beneficiarySsn" },
        ];
        keysToFetch.forEach((key) => {
          if (beneficiary[key.dbRef]) {
            keyToFetch = key.dbRef;
            keyToUpdate = key.ref;
          }
        });
        const value = beneficiary[keyToFetch];
        if (!value) return null;
        const callback = async (val) => {
          if (val.stack) throw Error(val);
          beneficiary[keyToUpdate] = val;
        };
        const ssn = new SocialSecurity();
        return ssn.getSSN(value, keyToUpdate, callback);
      })
    );

    return copyBen;
  } catch (error) {
    console.error(error);
    // rollbar.error(error, "handleFetchNestedBeneficiarySSNs");
    Sentry.captureException(error, { extra: { msg: "handleFetchNestedBeneficiarySSNs" } })
    throw error;
  }
}

export async function handleSaveNestedBeneficiarySSNs(data, ...ids) {
  try {
    if (Object.keys(data).some((key) => key === "designatedBeneficiaries")) {
      // get [{key: value},...] from Promise.all
      const benRefs = await Promise.all(
        data["designatedBeneficiaries"].map(async (item, index) => {
          // map through each beneficiary
          const cleaned = cleanData(item) || {};
          const arr = Object.keys(cleaned).filter((cleanedKey) => {
            return cleaned[cleanedKey] && nestedSSNDataMap[cleanedKey];
          });
          if (!arr) return null;
          const k = arr[0];
          if (!k) return null;
          const value = cleaned[k];
          const ref = nestedSSNDataMap[k][0];
          if (!value) return null;
          const SSN = new SocialSecurity();
          const ssnRef = await SSN.createSSN(value);
          return { [ref]: ssnRef, index };
        })
      );
      const applicationData = resolveDataMap(data, "applications");
      const copy = cloneDeep(applicationData.designatedBeneficiaries);
      if (!benRefs) return;
      benRefs.filter(Boolean).forEach((obj) => {
        const key = Object.keys(obj)[0];
        copy[obj.index][key] = obj[key];
      });
      console.log("updating application with nested bens of id ", ids[0]);
      try {
        console.log("data updating nested bens", JSON.stringify(copy));
      } catch (e) { }
      await applicationsCollection.doc(ids[0]).update({
        designatedBeneficiaries: copy,
      });
    }
  } catch (error) {
    console.error("saving nested ben ssns", error);
    // rollbar.error(error, "handleSaveNestedBeneficiarySSNs");
    Sentry.captureException(error, { extra: { msg: "handleSaveNestedBeneficiarySSNs" } })
    throw Error(`${error.message}: create beneficiary ssn`);
  }
}

export async function handleRetrieveSSNs(data) {
  // handles getting ssn's for non-nested in array keys
  try {
    let toFetch = {};
    let toSet = [];
    Object.keys(ssnDataMap).forEach((k) => {
      const [key] = ssnDataMap[k];
      if (data[key]) {
        toFetch[key] = data[key];

        toSet.push(k);
      }
    });
    // do additional too
    Object.keys(additionalParticipantSsnDataMap).forEach((k) => {
      const [key] = additionalParticipantSsnDataMap[k];
      if (data[key]) {
        toFetch["ssnRef2"] = data[key];

        toSet.push(k);
      }
    });

    const promises = async () => {
      const ssnObj = {};
      const values = await Promise.all(
        Object.keys(toFetch).map((k, index) => {
          const value = toFetch[k];
          const SSN = new SocialSecurity();
          const ssnVal = SSN.getSSN(value, k);
          return ssnVal;
        })
      );
      // console.log("values decrypt", values);
      values.forEach((ssnV, i) => {
        const k = toSet[i];
        ssnObj[k] = ssnV;
      });
      return ssnObj;
    };

    const ssns = await promises();
    return ssns;
  } catch (error) {
    console.error(error);
    // rollbar.error(error, "handleRetrieveSSNs");
    Sentry.captureException(error, { extra: { msg: "handleRetrieveSSNs" } })
    throw error;
  }
}

// here we do this explictly to avoid clashing of similar keys with primary part
export async function handleCreateAdditionalParticipantSSN(data, ...ids) {
  try {
    if (!ids[1]) return null;
    const value = data.additionalParticipantSocialSecurity;
    if (!value) return null;

    const ssn = new SocialSecurity();
    const ssnRef = await ssn.createSSN(value);
    await participantsCollection.doc(ids[1]).update({
      ssnRef: ssnRef,
    });
  } catch (error) {
    console.error("create add part ssn", error);
    // rollbar.error(error, "handleCreateAdditionalParticipantSSN");
    Sentry.captureException(error, { extra: { msg: "handleCreateAdditionalParticipantSSN" } })
    throw Error(`${error.message}: create add part ssn`);
  }
}

export class SocialSecurity {
  async getSSN(id, key, callback) {
    try {
      const token = await getToken();
      // var t0 = performance.now();
      // console.log("sending request for id ", id);
      const res = await request(`${FIREBASE_URLS.manageSSN}/admin/${id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ key }),
      });
      // var t1 = performance.now();
      // console.log(
      //   `req completed for id ${id} of key ${key} in ms: `,
      //   t1 - t0
      // );
      if (!res) throw Error("response not returned");
      if (!res.ok) throw Error("res not ok");
      const data = await res.json();
      const cipher = data.str;
      const bytesPub = crypto.AES.decrypt(cipher, publicKey);
      const ssn = bytesPub.toString(crypto.enc.Utf8);
      // console.log("ssn", ssn);
      return callback ? callback(ssn) : ssn;
    } catch (error) {
      console.error(error);
      // rollbar.error(error, "getSSN");
      Sentry.captureException(error, { extra: { msg: "getSSN" } })
      return callback ? callback(error) : null;
    }
  }

  async createSSN(socialSecurityNumber, callback) {
    // console.log("ssn", socialSecurityNumber);
    try {
      const token = await getToken();
      const cipher = crypto.AES.encrypt(
        socialSecurityNumber.toString(),
        publicKey
      ).toString();

      const res = await request(`${FIREBASE_URLS.manageSSN}/admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ssn: cipher }),
      });
      if (!res) throw Error("response not returned");
      if (!res.ok) throw Error("res not ok", await res.text());
      const data = await res.json();
      const id = data.str;
      return callback ? await callback(id) : id;
    } catch (error) {
      console.error(error);
      // rollbar.error(error, "postSSN");
      Sentry.captureException(error, { extra: { msg: "postSSN" } })
      if (callback) {
        return await callback(error);
      }
      throw error;
    }
  }
}
