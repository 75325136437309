import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  makeStyles,
  Chip,
  List,
  ListItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { statusTypes } from "util/dataMap";
import { equality, convertTimestampToText, timeDiff } from "util/helpers";
import { resolveFlag, flagOptions } from "util/flags";
import { SOLERA, STATUS } from "config/constants";
import { KEYS } from "config/constants";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  headline: {
    background: theme.palette.secondary.light,
    padding: "0.5rem",
    marginBottom: "0.5rem",
  },
  draftStatus: {
    "& svg": {
      display: "none",
    },
  },
  log: {
    background: theme.palette.info.main,
    padding: "0.5rem",
  },
  activitySection: {
    marginTop: "25px",
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    paddingTop: "10px",
  },
  flags: {
    display: "flex",
    flexWrap: "wrap",
  },
  referrerFixed: {
    textTransform: "uppercase",
    letterSpacing: "0.05rem",
  },
  flagPrimary: {
    background: theme.palette.error.light,
    color: "#fff",
  },
  flagSecondary: {
    background: theme.palette.secondary.dark,
    color: "#fff",
  },
  checkbox: {
    cursor: "initial",
    padding: 5,
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  bold: {
    fontWeight: 700,
  },
}));

const ActivityLog = memo(
  ({ id, createdAt, submittedAt, updatedAt, createdByUserType }) => {
    const classes = useStyles();
    // console.log("rendering activity log");
    const createdText = convertTimestampToText(createdAt);
    const submittedText = convertTimestampToText(submittedAt);
    const updatedText = convertTimestampToText(updatedAt);
    const completionTimeText = submittedAt
      ? timeDiff(createdAt, submittedAt)
      : null;

    return (
      <>
        {id && (
          <Box className={classes.log}>
            <Typography variant="h6">Activity Log</Typography>
            <Box pt={1} pb={2}>
              <Typography variant="body2" className={classes.bold}>
                App. {id}
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography variant="body2" className={classes.bold}>
                Created at:
              </Typography>
              <Typography variant="body2">{createdText}</Typography>
            </Box>
            <Box pt={1}>
              <Typography variant="body2" className={classes.bold}>
                Last updated at:
              </Typography>
              <Typography variant="body2">{updatedText}</Typography>
            </Box>
            {!!submittedAt && (
              <Box pt={1}>
                <Typography variant="body2" className={classes.bold}>
                  Submitted at:
                </Typography>
                <Typography variant="body2">{submittedText}</Typography>
              </Box>
            )}
            {!!completionTimeText && (
              <Box pt={1}>
                <Typography variant="body2" className={classes.bold}>
                  Completion time:
                </Typography>
                <Typography variant="body2">{completionTimeText}</Typography>
              </Box>
            )}
            {!!createdByUserType && (
              <Box pt={3}>
                <Typography variant="body2" className={classes.bold}>
                  Created by:
                </Typography>
                <Typography variant="body2">{createdByUserType}</Typography>
              </Box>
            )}

            <Box className={classes.activitySection}></Box>
          </Box>
        )}
      </>
    );
  }
);

const NewAppGuidance = memo(
  ({
    id,
    email,
    gender,
    employmentStatus,
    firstName,
    lastName,
    accountType,
    checkingAccountType,
    maritalStatus,
    phoneNumber,
    birthDate,
    countryCitizenship,
    validationErrors,
  }) => {
    const classes = useStyles();
    const minFields = {
      email,
      gender,
      employmentStatus,
      firstName,
      lastName,
      accountType,
      checkingAccountType,
      maritalStatus,
      birthDate,
      phoneNumber,
      countryCitizenship,
    };

    let shouldDisplay = !id
      ? true
      : KEYS.MIN_KEYS.some(({ field }) => {
          return !minFields[field];
        });

    return (
      <>
        {shouldDisplay && (
          <Box className={classes.log}>
            <Box pt={2}>
              <Typography variant="body1" color="error">
                These fields are required to {id ? "update an" : "create a new"}{" "}
                application
              </Typography>
            </Box>
            <Box pt={1} pb={2}>
              <List>
                {KEYS.MIN_KEYS.map(({ field, label }) => {
                  return (
                    <ListItem
                      key={field}
                      role={undefined}
                      dense
                      className={classes.listItem}
                    >
                      <Checkbox
                        edge="start"
                        checked={
                          !!minFields[field] &&
                          !validationErrors.includes(field)
                        }
                        tabIndex={-1}
                        size="small"
                        disableRipple
                        classes={{
                          root: classes.checkbox,
                          colorSecondary: classes.checkbox,
                        }}
                        inputProps={{ "aria-labelledby": field }}
                      />

                      <ListItemText id={field} primary={`${label}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>

            <Box className={classes.activitySection}></Box>
          </Box>
        )}
      </>
    );
  }
);

// these need to be listed to update change in flags
const headlineFields = [
  "status",
  "assignedTo",
  "allAdmins",
  "firstName",
  "lastName",
  "usaCitizen",
  "usaPermanentResident",
  "birthDate",
  "doesRequireSpousalConsent",
  "referralSource",
  "industry",
  "employmentStatus",
  "addAdditionalParticipant",
  "additionalParticipantBirthDate",
  "additionalParticipantUsaCitizen",
  "additionalParticipantIndustry",
  "additionalParticipantEmploymentStatus",
  "additionalParticipantUsaPermanentResident",
  "additionalParticipantIdentityJumioFailAt",
  "additionalParticipantIdentityIdType",
  "additionalParticipantIdentityIssueDateOfId",
  "additionalParticipantIdentityNumberOnId",
  "w8BENStorageRef",
  "additionalParticipantW8BENStorageRef",
  "createdByUserType",
];

const Flag = ({ label, type, classes }) => {
  return (
    <Box mr={"auto"} mb={1}>
      <Chip
        label={label}
        className={
          type === "primary" ? classes.flagPrimary : classes.flagSecondary
        }
      />
    </Box>
  );
};

const resolveFlags = (props, callback) => {
  Promise.all(
    flagOptions.map(({ name, label, type }) => {
      if (typeof name === "object") {
        return resolveFlag({ key: name, allValues: props, label, type });
      } else {
        return resolveFlag({
          key: name,
          value: props[name],
          label,
          allValues: props,
          type,
        });
      }
    })
  ).then((flags) => {
    callback(flags.filter(Boolean));
  });
};

const Headline = memo(
  (props) => {
    // console.log("props in Headline", props);
    const classes = useStyles();
    const [flags, setFlags] = useState([]);
    useEffect(() => {
      /**
       * list out props to below to see update in flags from inputs
       */
      resolveFlags(props, setFlags);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      props.birthDate,
      props.usaCitizen,
      props.usaPermanentResident,
      props.hasSubmitted,
      props.identityJumioFailAt,
      props.identityIdType,
      props.identityIssueDateOfId,
      props.identityNumberOnId,
      props.w8BENStorageRef,
      props.doesRequireSpousalConsent,
      props.industry,
      props.employmentStatus,
      // additional participant
      props.addAdditionalParticipant,
      props.additionalParticipantIndustry,
      props.additionalParticipantEmploymentStatus,
      props.additionalParticipantBirthDate,
      props.additionalParticipantUsaCitizen,
      props.additionalParticipantUsaPermanentResident,
      props.additionalParticipantIdentityJumioFailAt,
      props.additionalParticipantIdentityIdType,
      props.additionalParticipantIdentityIssueDateOfId,
      props.additionalParticipantIdentityNumberOnId,
      props.additionalParticipantW8BENStorageRef,
      props.createdByUserType,
    ]);

    const referrersFiltered = props.referrers.filter(
      (item) => item.authority === props.authorityDomain
    );

    const statusDisabled =
      props.authorityDomain === SOLERA
        ? props.status === STATUS.DRAFT && !props.submittedAt
        : true;

    // admins might get removed from the database so protect component
    const allAdminIds = props.allAdmins.map((item) => item.adminId);
    let assignedTo = props.assignedTo || "";
    if (!allAdminIds.includes(assignedTo)) {
      assignedTo = "";
    }
    return (
      <Box className={classes.headline}>
        {/* status */}
        <Box>
          <Box>
            <Typography>{"Status"}</Typography>
          </Box>
          <Box>
            <Select
              fullWidth
              variant="standard"
              labelId={"status"}
              id={"status"}
              name={"status"}
              value={props.status || ""}
              onChange={props.handleChange}
              readOnly={statusDisabled}
              className={statusDisabled ? classes.draftStatus : ""}
              disableUnderline={statusDisabled}
            >
              {statusTypes.map(({ name, value }) => {
                return (
                  <MenuItem
                    key={name}
                    value={value}
                    dense
                    disabled={value === "ARCHIVED" ? true : false}
                  >
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        {/* assignedTo */}
        {props.authorityDomain === SOLERA && (
          <Box mt={3}>
            <Box>
              <Typography>{"Assigned to"}</Typography>
            </Box>
            <Box>
              <Select
                fullWidth
                variant="standard"
                labelId={"assignedTo"}
                id={"assignedTo"}
                name={"assignedTo"}
                value={assignedTo}
                onChange={props.handleChange}
              >
                {props.allAdmins.map(({ email, adminId }, i) => (
                  <MenuItem key={`${adminId}-${i}`} value={adminId} dense>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        )}

        {/* referralSource */}
        {props.authorityDomain === SOLERA ? (
          <Box mt={3}>
            <Box>
              <Typography>{"Referrer"}</Typography>
            </Box>
            <Box>
              <Select
                fullWidth
                variant="standard"
                labelId={"referralSource"}
                id={"referralSource"}
                name={"referralSource"}
                value={
                  props.referralSource === "0000-NOT ASSIGNED"
                    ? ""
                    : props.referralSource || ""
                }
                onChange={props.handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled dense>
                  <em>Not assigned</em>
                </MenuItem>
                {props.referrers
                  .filter(({ value }) => value !== "")
                  .map(({ name, value, referralSource }, i) => (
                    <MenuItem
                      key={`${value}-${i}`}
                      value={referralSource}
                      dense
                    >
                      {value === SOLERA
                        ? referralSource
                        : value === "0000-NOT ASSIGNED"
                        ? null
                        : name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
        ) : (
          <Box mt={3}>
            <Box>
              <Typography>{"Referrer"}</Typography>
            </Box>
            <Box mt={2}>
              <Select
                fullWidth
                variant="standard"
                labelId={"referralSource"}
                id={"referralSource"}
                name={"referralSource"}
                value={props.referralSource || ""}
                onChange={props.handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled dense>
                  <em>Not assigned</em>
                </MenuItem>
                {referrersFiltered.map(({ name, value, referralSource }, i) => (
                  <MenuItem key={`${value}-${i}`} value={referralSource} dense>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        )}

        {/* flags */}
        {!isEmpty(flags) && (
          <Box mt={3}>
            <Typography>{"Flags"}</Typography>
            <Box mt={2} className={classes.flags}>
              {flags.map(({ name, label, type }, index) => (
                <Flag
                  key={`${name}-${index}`}
                  label={label}
                  type={type}
                  index={index}
                  classes={classes}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  },
  (prev, next) => {
    return equality(prev, next, headlineFields);
  }
);

Headline.defaultProps = {
  submittedAt: null,
  firstName: "",
  lastName: "",
  status: STATUS.DRAFT,
  assignedTo: "",
  referralSource: "",
  createdByUserType: "",
  doesRequireSpousalConsent: null,
};

Headline.propTypes = {
  status: PropTypes.oneOf([
    STATUS.DRAFT,
    STATUS.REQUIRES_REVIEW,
    STATUS.READY_FOR_BATCH,
    STATUS.IN_PROCESS,
    STATUS.APPROVED,
    STATUS.DECLINED,
    STATUS.ON_HOLD,
    STATUS.ARCHIVED,
  ]),
  assignedTo: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birthDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  doesRequireSpousalConsent: PropTypes.bool,
  referralSource: PropTypes.string,
  submittedAt: PropTypes.object,
  createdByUserType: PropTypes.string,
};

const ApplicationDrawer = (props) => {
  // console.log("props in drawer", props);
  return (
    <Box className={props.classes.drawer}>
      <Headline {...props} />
      <ActivityLog
        id={props.id}
        createdAt={props.createdAt}
        submittedAt={props.submittedAt}
        updatedAt={props.updatedAt}
        createdByUserType={props.createdByUserType}
      />

      <NewAppGuidance
        email={props.email}
        gender={props.gender}
        employmentStatus={props.employmentStatus}
        firstName={props.firstName}
        lastName={props.lastName}
        accountType={props.accountType}
        checkingAccountType={props.checkingAccountType}
        maritalStatus={props.maritalStatus}
        phoneNumber={props.phoneNumber}
        birthDate={props.birthDate}
        countryCitizenship={props.countryCitizenship}
        id={props.id}
        validationErrors={props.validationErrors}
      />
    </Box>
  );
};

export default ApplicationDrawer;
