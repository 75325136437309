import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

const mask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PhoneInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
      guide={false}
      showMask
    />
  );
};

PhoneInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default PhoneInput;
