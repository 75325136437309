import React, { useContext, useCallback, memo, useMemo } from "react";
import { Container, Box, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";

import { ApplicationsDataContext } from "components/ApplicationsData";
import DashboardFilters from "components/DashboardFilters";
import StatusFilters from "components/StatusFilters";
import DashboardNoData from "components/DashboardNoData";
import Search from "components/Search";
import { DASHBOARD_RECORD_LIMIT } from "../../config/constants";

import { isEqual } from "lodash";

const useStyles = makeStyles(
  {
    table: {
      height: `calc(100vh - 205px)`,
      width: "100%",
    },
    cell: {
      lineHeight: "16px !important",
      whiteSpace: "pre-wrap !important",
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
    },
  },
  { name: "MuiDataGrid" }
);

const Table = memo(
  ({
    data,
    columns,
    sortModel,
    isLoading,
    error,
    classes,
    goToApplication,
    handlePageChange,
    count,
    page,
  }) => {
    return (
      <DataGrid
        rows={data}
        sortModel={sortModel}
        columns={columns}
        loading={isLoading}
        components={{
          noRowsOverlay: DashboardNoData,
        }}
        classes={{
          cell: classes.cell,
        }}
        rowHeight={65}
        error={error}
        onRowClick={goToApplication}
        paginationMode="server"
        pageSize={DASHBOARD_RECORD_LIMIT}
        rowCount={count}
        page={page.currPage}
        onPageChange={(newPage) => handlePageChange(newPage)}
      />
    );
  },
  compTable
);

function compTable(prev, next) {
  if (isEqual(prev.data, next.data)) {
    if (prev.isLoading === next.isLoading) return true;
  }
}

const Dashboard = () => {
  const {
    columns,
    sortModel,
    data,
    isLoading,
    error,
    filters,
    filterValues,
    updateFilter,
    authorityDomain,
    handlePageChange,
    countsByStatus,
    page,
  } = useContext(ApplicationsDataContext);
  const classes = useStyles();

  const history = useHistory();
  const goToApplication = useCallback(
    ({ data }) => {
      const id = data.id.split("-")[0];
      return setTimeout(
        () =>
          history.push({
            pathname: `/applications/${id}`,
          }),
        100
      );
    },
    [history]
  );

  const status = filterValues?.status;
  const count =
    countsByStatus?.find((statCounts) => statCounts.status === status)?.count ||
    0;

  return (
    <main>
      <Container maxWidth="xl">
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={0.5}
          >
            <div>
              <DashboardFilters
                filters={filters}
                values={filterValues}
                updateFilter={updateFilter}
              />
            </div>
            <div>
              {useMemo(
                () => (
                  <Search />
                ),
                []
              )}
            </div>
          </Box>
          <StatusFilters
            filters={filters}
            values={filterValues}
            updateFilter={updateFilter}
            authorityDomain={authorityDomain}
          />
          <div className={classes.table}>
            <Table
              data={data}
              columns={columns}
              sortModel={sortModel}
              isLoading={isLoading}
              error={error}
              classes={classes}
              goToApplication={goToApplication}
              handlePageChange={handlePageChange}
              count={count || 100}
              page={page}
            />
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Dashboard;
