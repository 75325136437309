import React from "react";

const FourOFour = () => {
  return (
    <main>
      <div>
        <h1>404</h1>
      </div>
    </main>
  );
};

export default FourOFour;
