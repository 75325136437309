import React, { memo, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Link,
  TextField,
  Typography,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Button,
  IconButton,
  CircularProgress,
  makeStyles,
  Input,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";
import MuiAlert from "@material-ui/lab/Alert";

import { isEmpty, last } from "lodash";

import DatePicker from "components/DatePicker";
import Accordion from "components/Accordion";
import PhoneInput from "components/PhoneInput";
import SSNInput from "components/SSNInput";
import FileUpload from "components/FileUpload";

import {
  equality,
  mapCountriesToOptions,
  mapStatesToOptions,
  mapIndustriesToOptions,
  jumioLinkInstructions,
  getTwoLetterCountryCode,
  getFileExtFromUrl,
} from "util/helpers";
import {
  generateIdentityRedirect,
  downloadFile,
  getImageUrl,
  downloadResource,
} from "util/requests";

import { getAdditionalParticipantLink } from "util/requests/additionalParticipants";

import * as Sentry from "@sentry/react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  linkStyleButton: {
    lineHeight: "1.8",
    textDecoration: "underline",
  },
  idImages: {
    width: "100%",
    display: "flex",
    paddingTop: "30px",
    justifyContent: "space-between",
  },
  imgWrapper: {
    maxWidth: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      width: "100%",
    },
  },
}));

const AdditionalParticipant = ({
  section,
  classes,
  id,
  files,
  onSelectFile,
  additionalParticipantId,
  addAdditionalParticipant,
  additionalParticipantFirstName,
  additionalParticipantMiddleName,
  additionalParticipantLastName,
  additionalParticipantEmail,
  additionalParticipantPhone,
  additionalParticipantAgreesToTextNotifications,
  additionalParticipantUsaCitizen,
  additionalParticipantCountryCitizenship,
  additionalParticipantUsaPermanentResident,
  additionalParticipantHasUSAddress,
  additionalParticipantForeignAddressLine1,
  additionalParticipantForeignAddressApt,
  additionalParticipantForeignAddressCity,
  additionalParticipantForeignAddressState,
  additionalParticipantForeignAddressPostcode,
  additionalParticipantForeignAddressCountry,
  additionalParticipantGender,
  additionalParticipantBirthDate,
  additionalParticipantMaritalStatus,
  additionalParticipantMaidenName,
  additionalParticipantEmploymentStatus,
  additionalParticipantCurrentOrRecentJobTitle,
  additionalParticipantEmployer,
  additionalParticipantFormerEmployer,
  additionalParticipantIndustry,
  additionalParticipantIdentityJumioCallback,
  additionalParticipantIdentityExpiryDateOfId,
  additionalParticipantIdentityIdType,
  additionalParticipantIdentityIssueDateOfId,
  additionalParticipantIdentityIssuingCountryOfPassport,
  additionalParticipantIdentityIssuingStateOfId,
  additionalParticipantIdentityJumioFailAt,
  additionalParticipantIdentityJumioSuccessAt,
  additionalParticipantIdentityNumberOnId,
  additionalParticipantIdentityJumioReason,
  additionalParticipantSocialSecurity,
  additionalParticipantResCity,
  additionalParticipantResState,
  additionalParticipantResAddressLine1,
  additionalParticipantResPostcode,
  additionalParticipantResAptNumber,
  additionalParticipantResAddressEqualsMailing,
  additionalParticipantMailingCity,
  additionalParticipantMailingState,
  additionalParticipantMailingAddressLine1,
  additionalParticipantMailingAptNumber,
  additionalParticipantMailingPostcode,
  additionalParticipantPreviousAddressLine1,
  additionalParticipantPreviousAptNumber,
  additionalParticipantPreviousCity,
  additionalParticipantPreviousState,
  additionalParticipantPreviousPostcode,
  additionalParticipantHasResidedAtAddressForRequiredDuration,
  additionalParticipantGreenCardStorageRef,
  additionalParticipantW8BENStorageRef,
  primaryParticipantId,
  readOnly,
  handleChange,
  updateField,
  isLoadingSSNs,
}) => {
  const isExistingApplication = !!id;
  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [idImages, setIdImages] = useState({
    front: "",
    back: "",
    selfie: "",
  });
  const [additionalParticipantLink, setAdditionalParticipantLink] = useState(
    ""
  );
  const [errorImages, setErrorImages] = useState(null);
  const localClasses = useStyles();
  const shouldDisplayNotReadyNoApplication = !id;
  const shouldDisplayCustomerNotBegun =
    additionalParticipantIdentityJumioSuccessAt === undefined &&
    additionalParticipantIdentityJumioFailAt === undefined;
  const shouldDisplayLinkGeneration =
    additionalParticipantIdentityJumioSuccessAt === null ||
    additionalParticipantIdentityJumioSuccessAt === undefined;
  const shouldDislayMaidenName =
    additionalParticipantMaritalStatus === "married" ||
    additionalParticipantMaritalStatus === "divorced" ||
    additionalParticipantMaritalStatus === "widowed";

  // console.log(
  //   "rendering additional participant",
  //   shouldDisplayLinkGeneration,
  //   additionalParticipantIdentityJumioSuccessAt
  // );

  async function getImages() {
    if (additionalParticipantIdentityJumioCallback) {
      const frontPath = `/participants/${additionalParticipantId}/identity/id_front.jpeg`;
      const backPath = `/participants/${additionalParticipantId}/identity/id_back.jpeg`;
      const selfiePath = `/participants/${additionalParticipantId}/identity/face.jpeg`;
      const pathArr = [frontPath, selfiePath];
      const hasBackPath = last(additionalParticipantIdentityJumioCallback)
        .idScanImageBackside;
      if (hasBackPath) pathArr.push(backPath);

      const [front, selfie, back] = await Promise.all(
        pathArr.map((path) => {
          return getImageUrl(path, ".png");
        })
      );

      if (!front || !selfie) handleErrorImages();
      setIdImages({
        front,
        back,
        selfie,
      });
    }
  }

  function handleErrorImages() {
    if (errorImages === null) {
      setErrorImages("could not load images");
    }
  }

  async function downloadAllImages() {
    if (errorImages) return null;
    try {
      await Promise.all(
        Object.keys(idImages).map((key) => {
          return downloadResource(
            idImages[key],
            `${key}.${getFileExtFromUrl(idImages[key])}`
          );
        })
      );
    } catch (error) {
      console.error(error, "download all id images");
      // rollbar.error(error, "download all images");
      Sentry.captureException(error, { extra: { msg: "download all images" } });
    }
  }

  useEffect(() => {
    getImages();
  }, [additionalParticipantIdentityJumioCallback]); // eslint-disable-line

  const fileObjs = [
    {
      name: "additionalParticipantGreenCardStorageRef",
      label: "Green card",
      ref: additionalParticipantGreenCardStorageRef,
    },
    {
      name: "additionalParticipantW8BENStorageRef",
      label: "W8-BEN (non-US citizens)",
      ref: additionalParticipantW8BENStorageRef,
    },
  ];

  const docsToUpload = [];
  fileObjs.forEach((doc) => {
    if (additionalParticipantUsaCitizen === false) {
      if (doc.name === "additionalParticipantW8BENStorageRef")
        docsToUpload.push(doc);
      if (additionalParticipantUsaPermanentResident === true) {
        if (doc.name === "additionalParticipantGreenCardStorageRef")
          docsToUpload.push(doc);
      }
    }
  });
  const filesToDownload =
    Array.isArray(fileObjs) && fileObjs.filter((file) => !!file.ref);

  async function generateLink() {
    if (!additionalParticipantId) return;
    setIsLoading(true);
    const redirectLink = await generateIdentityRedirect(
      additionalParticipantId
    );
    console.log("redirect", redirectLink);
    setLink(redirectLink);
    setIsLoading(false);
  }

  async function copyLinkAndInstructions() {
    const instructions = jumioLinkInstructions(
      additionalParticipantFirstName && additionalParticipantLastName
        ? `${additionalParticipantFirstName} ${additionalParticipantLastName}`
        : "Customer",
      link
    );
    await navigator.clipboard.writeText(instructions);
  }

  function copyAdditionalParticipantLink() {
    navigator.clipboard.writeText(additionalParticipantLink);
  }

  const ssn = useMemo(
    () => (
      <SSNInput
        label="Social Security Number"
        name="additionalParticipantSocialSecurity"
        value={additionalParticipantSocialSecurity}
        handleChange={handleChange}
        readOnly={readOnly || isLoadingSSNs}
        isLoading={isLoadingSSNs}
      />
    ),
    /* eslint-disable-next-line */
    [additionalParticipantSocialSecurity, isLoadingSSNs, readOnly]
  );

  useEffect(() => {
    async function getUserLink() {
      // retrieve link that can be sent to a customer for completion
      console.log(
        "running function",
        additionalParticipantId,
        primaryParticipantId
      );
      const link = await getAdditionalParticipantLink(
        primaryParticipantId,
        additionalParticipantId
      );
      setAdditionalParticipantLink(link);
    }
    if (additionalParticipantId && primaryParticipantId) {
      getUserLink();
    }
  }, [additionalParticipantId, primaryParticipantId]);

  const shouldDisplayEmployer =
    additionalParticipantEmploymentStatus === null ||
    additionalParticipantEmploymentStatus === "self-employed" ||
    additionalParticipantEmploymentStatus === "employed";

  return (
    <>
      {addAdditionalParticipant === true && isExistingApplication === true && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section}-content`}
            id={`${section}-header`}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Typography variant="h6">Additional Participant</Typography>
              {/* <Typography variant="button">Incomplete</Typography> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.section}>
              <>
                {/* User completion link */}
                <Box>
                  {additionalParticipantLink && (
                    <Box mb={3}>
                      <Paper>
                        <Box p={3}>
                          <Typography variant="body2">
                            Additional participants can complete this section
                            themselves through the app. If the additional
                            participant did not receive a secure link to the app
                            or if you are creating the application through the
                            portal, you can copy the secure link here.
                          </Typography>

                          <Box mt={2}>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={copyAdditionalParticipantLink}
                            >
                              copy link to clipboard
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  )}
                </Box>
                {/* Contact info */}
                <Box>
                  <Box>
                    <TextField
                      className={classes.input}
                      variant="standard"
                      label="First Name"
                      type="text"
                      fullWidth
                      name="additionalParticipantFirstName"
                      id="additionalParticipantFirstName"
                      value={additionalParticipantFirstName || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <TextField
                      className={classes.input}
                      variant="standard"
                      label="Middle Name"
                      type="text"
                      fullWidth
                      name="additionalParticipantMiddleName"
                      id="additionalParticipantMiddleName"
                      value={additionalParticipantMiddleName || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <TextField
                      className={classes.input}
                      variant="standard"
                      label="Last Name"
                      type="text"
                      fullWidth
                      name="additionalParticipantLastName"
                      id="additionalParticipantLastName"
                      value={additionalParticipantLastName || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <TextField
                      className={classes.input}
                      variant="standard"
                      label="Email"
                      type="text"
                      fullWidth
                      name="additionalParticipantEmail"
                      id="additionalParticipantEmail"
                      value={additionalParticipantEmail || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="additionalParticipantPhone">
                        Phone Number
                      </InputLabel>
                      <Input
                        value={additionalParticipantPhone || ""}
                        onChange={handleChange}
                        name="additionalParticipantPhone"
                        id="additionalParticipantPhone"
                        inputComponent={PhoneInput}
                        readOnly={readOnly}
                      />
                    </FormControl>
                  </Box>
                  <div className={classes.section}>
                    <Box mt={4}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          {"Agrees to text notifications"}
                        </FormLabel>
                        <RadioGroup
                          aria-label={
                            "additionalParticipantAgreesToTextNotifications"
                          }
                          name={
                            "additionalParticipantAgreesToTextNotifications"
                          }
                          value={additionalParticipantAgreesToTextNotifications}
                          onChange={handleChange}
                          row
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio disabled={readOnly} />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio disabled={readOnly} />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box mt={2}>
                      <Box mb={3}>
                        <Typography variant="body1">
                          <b>Residential Address</b>
                        </Typography>
                      </Box>

                      <Box>
                        <TextField
                          fullWidth
                          className={classes.input}
                          variant="standard"
                          label="Residential address line 1"
                          type="text"
                          name="additionalParticipantResAddressLine1"
                          id="additionalParticipantResAddressLine1"
                          value={additionalParticipantResAddressLine1 || ""}
                          onChange={handleChange}
                          margin="dense"
                          inputProps={{
                            readOnly: Boolean(readOnly),
                          }}
                        ></TextField>
                        <TextField
                          fullWidth
                          className={classes.input}
                          variant="standard"
                          label="Apartment"
                          type="text"
                          name="additionalParticipantResAptNumber"
                          id="additionalParticipantResAptNumber"
                          value={additionalParticipantResAptNumber || ""}
                          onChange={handleChange}
                          margin="dense"
                          inputProps={{
                            readOnly: Boolean(readOnly),
                          }}
                        ></TextField>
                        <TextField
                          fullWidth
                          className={classes.input}
                          variant="standard"
                          label="City"
                          type="text"
                          name="additionalParticipantResCity"
                          id="additionalParticipantResCity"
                          value={additionalParticipantResCity || ""}
                          onChange={handleChange}
                          margin="dense"
                          inputProps={{
                            readOnly: Boolean(readOnly),
                          }}
                        ></TextField>
                        <InputLabel
                          id="resState"
                          className={classes.selectLabel}
                        >
                          State
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="additionalParticipantResState"
                          name="additionalParticipantResState"
                          id="additionalParticipantResState"
                          value={additionalParticipantResState || ""}
                          onChange={handleChange}
                          readOnly={readOnly}
                        >
                          {mapStatesToOptions().map((opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                              {opt.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          fullWidth
                          className={classes.input}
                          variant="standard"
                          label="Zip code"
                          type="text"
                          name="additionalParticipantResPostcode"
                          id="additionalParticipantResPostcode"
                          value={additionalParticipantResPostcode || ""}
                          onChange={handleChange}
                          margin="dense"
                          inputProps={{
                            readOnly: Boolean(readOnly),
                          }}
                        ></TextField>
                      </Box>
                      {/* prev address */}
                      <Box my={3} pt={1}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {
                              "Have they been at this address for longer than 18 months?"
                            }
                          </FormLabel>
                          <RadioGroup
                            aria-label={
                              "additionalParticipantHasResidedAtAddressForRequiredDuration"
                            }
                            name={
                              "additionalParticipantHasResidedAtAddressForRequiredDuration"
                            }
                            value={
                              additionalParticipantHasResidedAtAddressForRequiredDuration
                            }
                            onChange={handleChange}
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio disabled={readOnly} />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio disabled={readOnly} />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {additionalParticipantHasResidedAtAddressForRequiredDuration ===
                        false && (
                        <Box mt={3}>
                          <Box mb={3}>
                            <Typography variant="body1">
                              <b>Previous Address</b>
                            </Typography>
                          </Box>

                          <Box>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Previous address line 1"
                              type="text"
                              name="additionalParticipantPreviousAddressLine1"
                              id="additionalParticipantPreviousAddressLine1"
                              value={
                                additionalParticipantPreviousAddressLine1 || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Apartment"
                              type="text"
                              name="additionalParticipantPreviousAptNumber"
                              id="additionalParticipantPreviousAptNumber"
                              value={
                                additionalParticipantPreviousAptNumber || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="City"
                              type="text"
                              name="additionalParticipantPreviousCity"
                              id="additionalParticipantPreviousCity"
                              value={additionalParticipantPreviousCity || ""}
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <InputLabel
                              id="previousState"
                              className={classes.selectLabel}
                            >
                              State
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="additionalParticipantPreviousState"
                              name="additionalParticipantPreviousState"
                              id="additionalParticipantPreviousState"
                              value={additionalParticipantPreviousState || ""}
                              onChange={handleChange}
                              readOnly={readOnly}
                            >
                              {mapStatesToOptions().map((opt) => (
                                <MenuItem key={opt.value} value={opt.value}>
                                  {opt.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Zip code"
                              type="text"
                              name="additionalParticipantPreviousPostcode"
                              id="additionalParticipantPreviousPostcode"
                              value={
                                additionalParticipantPreviousPostcode || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                          </Box>
                        </Box>
                      )}
                      <Box my={3}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {
                              "Is the mailing address the same as residential address"
                            }
                          </FormLabel>
                          <RadioGroup
                            aria-label={
                              "additionalParticipantResAddressEqualsMailing"
                            }
                            name={
                              "additionalParticipantResAddressEqualsMailing"
                            }
                            value={additionalParticipantResAddressEqualsMailing}
                            onChange={handleChange}
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio disabled={readOnly} />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio disabled={readOnly} />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {additionalParticipantResAddressEqualsMailing ===
                        false && (
                        <>
                          <Box mb={3}>
                            <Typography variant="body1">
                              <b>Mailing Address</b>
                            </Typography>
                          </Box>

                          <Box>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Mailing address line 1"
                              type="text"
                              name="additionalParticipantMailingAddressLine1"
                              id="additionalParticipantMailingAddressLine1"
                              value={
                                additionalParticipantMailingAddressLine1 || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Apartment"
                              type="text"
                              name="additionalParticipantMailingAptNumber"
                              id="additionalParticipantMailingAptNumber"
                              value={
                                additionalParticipantMailingAptNumber || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="City"
                              type="text"
                              name="additionalParticipantMailingCity"
                              id="additionalParticipantMailingCity"
                              value={additionalParticipantMailingCity || ""}
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <InputLabel
                              id="mailingState"
                              className={classes.selectLabel}
                            >
                              State
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="additionalParticipantMailingState"
                              name="additionalParticipantMailingState"
                              id="additionalParticipantMailingState"
                              value={additionalParticipantMailingState || ""}
                              onChange={handleChange}
                              readOnly={readOnly}
                            >
                              {mapStatesToOptions().map((opt) => (
                                <MenuItem key={opt.value} value={opt.value}>
                                  {opt.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Zip code"
                              type="text"
                              name="additionalParticipantMailingPostcode"
                              id="additionalParticipantMailingPostcode"
                              value={additionalParticipantMailingPostcode || ""}
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                          </Box>
                        </>
                      )}
                    </Box>
                  </div>

                  <Box mt={5}>
                    <Box mb={3}>
                      <Typography variant="body1">
                        <b>Citizenship</b>
                      </Typography>
                    </Box>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{"US Citizen"}</FormLabel>
                      <RadioGroup
                        aria-label={"additionalParticipantUsaCitizen"}
                        name={"additionalParticipantUsaCitizen"}
                        value={additionalParticipantUsaCitizen}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio disabled={readOnly} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio disabled={readOnly} />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {additionalParticipantUsaCitizen === false && (
                    <>
                      <InputLabel
                        id="additionalParticipantCountryCitizenship"
                        className={classes.selectLabel}
                      >
                        Country of citizenship
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="additionalParticipantCountryCitizenship"
                        name="additionalParticipantCountryCitizenship"
                        id="additionalParticipantCountryCitizenship"
                        value={additionalParticipantCountryCitizenship || ""}
                        onChange={handleChange}
                        displayEmpty
                        readOnly={readOnly}
                      >
                        <MenuItem value="" disabled dense>
                          Select a country
                        </MenuItem>
                        {mapCountriesToOptions().map((opt) => (
                          <MenuItem key={opt.value} value={opt.value}>
                            {opt.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Box mt={4}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {"Permanent resident"}
                          </FormLabel>
                          <RadioGroup
                            aria-label={
                              "additionalParticipantUsaPermanentResident"
                            }
                            name={"additionalParticipantUsaPermanentResident"}
                            value={additionalParticipantUsaPermanentResident}
                            onChange={handleChange}
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio disabled={readOnly} />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio disabled={readOnly} />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {additionalParticipantUsaPermanentResident === false && (
                        <>
                          <Box mt={4}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                {"Has US address?"}
                              </FormLabel>
                              <RadioGroup
                                aria-label={"additionalParticipantHasUSAddress"}
                                name={"additionalParticipantHasUSAddress"}
                                value={additionalParticipantHasUSAddress}
                                onChange={handleChange}
                                row
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio disabled={readOnly} />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio disabled={readOnly} />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                          <Box mt={4}>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Foreign address line 1"
                              type="text"
                              name="additionalParticipantForeignAddressLine1"
                              id="additionalParticipantForeignAddressLine1"
                              value={
                                additionalParticipantForeignAddressLine1 || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Foreign address apartment"
                              type="text"
                              name="additionalParticipantForeignAddressApt"
                              id="additionalParticipantForeignAddressApt"
                              value={
                                additionalParticipantForeignAddressApt || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Foreign address city"
                              type="text"
                              name="additionalParticipantForeignAddressCity"
                              id="additionalParticipantForeignAddressCity"
                              value={
                                additionalParticipantForeignAddressCity || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Foreign address state, province, or county"
                              type="text"
                              name="additionalParticipantForeignAddressState"
                              id="additionalParticipantForeignAddressState"
                              value={
                                additionalParticipantForeignAddressState || ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>
                            <TextField
                              fullWidth
                              className={classes.input}
                              variant="standard"
                              label="Foreign address postcode"
                              type="text"
                              name="additionalParticipantForeignAddressPostcode"
                              id="additionalParticipantForeignAddressPostcode"
                              value={
                                additionalParticipantForeignAddressPostcode ||
                                ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            ></TextField>

                            <InputLabel
                              id="additionalParticipantForeignAddressCountry"
                              className={classes.selectLabel}
                            >
                              Foreign address country
                            </InputLabel>
                            <Select
                              fullWidth
                              className={classes.input}
                              labelId="additionalParticipantForeignAddressCountry"
                              name="additionalParticipantForeignAddressCountry"
                              id="additionalParticipantForeignAddressCountry"
                              value={
                                additionalParticipantForeignAddressCountry ||
                                additionalParticipantCountryCitizenship ||
                                ""
                              }
                              onChange={handleChange}
                              margin="dense"
                              inputProps={{
                                readOnly: Boolean(readOnly),
                              }}
                            >
                              <MenuItem value="" disabled dense>
                                Select a country
                              </MenuItem>
                              {mapCountriesToOptions().map((opt) => (
                                <MenuItem key={opt.value} value={opt.value}>
                                  {opt.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </Box>
                {/* Personal info */}
                <Box mt={4}>
                  <Box mb={3}>
                    <Typography variant="body1">
                      <b>Personal Information</b>
                    </Typography>
                  </Box>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {"Applicant gender"}
                    </FormLabel>
                    <RadioGroup
                      aria-label={"additionalParticipantGender"}
                      name={"additionalParticipantGender"}
                      value={additionalParticipantGender}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value={"female"}
                        control={<Radio disabled={readOnly} />}
                        label="Female"
                      />
                      <FormControlLabel
                        value={"male"}
                        control={<Radio disabled={readOnly} />}
                        label="male"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Box mt={3} display="flex" flexDirection="column">
                    <FormLabel component="legend">{"Birth date"}</FormLabel>
                    <DatePicker
                      id={`datepicker-addpart-birthDate`}
                      initialDate={additionalParticipantBirthDate}
                      dateHandler={updateField.bind(
                        null,
                        "additionalParticipantBirthDate"
                      )}
                      disabled={readOnly}
                    />
                  </Box>
                  <Box mt={3}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {"Marital status"}
                      </FormLabel>
                      <RadioGroup
                        aria-label={"additionalParticipantMaritalStatus"}
                        name={"additionalParticipantMaritalStatus"}
                        value={additionalParticipantMaritalStatus}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value={"single"}
                          control={<Radio disabled={readOnly} />}
                          label="Single"
                        />
                        <FormControlLabel
                          value={"married"}
                          control={<Radio disabled={readOnly} />}
                          label="Married"
                        />
                        <FormControlLabel
                          value={"divorced"}
                          control={<Radio disabled={readOnly} />}
                          label="Divorced"
                        />
                        <FormControlLabel
                          value={"widowed"}
                          control={<Radio disabled={readOnly} />}
                          label="Widowed"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {shouldDislayMaidenName && (
                    <TextField
                      className={classes.input}
                      variant="standard"
                      label="Maiden name"
                      type="text"
                      name="additionalParticipantMaidenName"
                      id="additionalParticipantMaidenName"
                      value={additionalParticipantMaidenName || ""}
                      onChange={handleChange}
                      margin="dense"
                      fullWidth
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                  )}
                  <Box mt={3}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {"Employment status"}
                      </FormLabel>
                      <RadioGroup
                        aria-label={"additionalParticipantEmploymentStatus"}
                        name={"additionalParticipantEmploymentStatus"}
                        value={additionalParticipantEmploymentStatus}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value={"employed"}
                          control={<Radio disabled={readOnly} />}
                          label="Employed"
                        />
                        <FormControlLabel
                          value={"self-employed"}
                          control={<Radio disabled={readOnly} />}
                          label="Self-employed"
                        />
                        <FormControlLabel
                          value={"unemployed"}
                          control={<Radio disabled={readOnly} />}
                          label="Unemployed"
                        />
                        <FormControlLabel
                          value={"retired"}
                          control={<Radio disabled={readOnly} />}
                          label="Retired"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <TextField
                    className={classes.input}
                    variant="standard"
                    label="Current or Recent Job Title"
                    type="text"
                    name="additionalParticipantCurrentOrRecentJobTitle"
                    id="additionalParticipantCurrentOrRecentJobTitle"
                    value={additionalParticipantCurrentOrRecentJobTitle}
                    onChange={handleChange}
                    margin="dense"
                    fullWidth
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>

                  <TextField
                    className={classes.input}
                    variant="standard"
                    label={
                      shouldDisplayEmployer
                        ? "Employer"
                        : "Most recent employer"
                    }
                    type="text"
                    name={
                      shouldDisplayEmployer
                        ? "additionalParticipantEmployer"
                        : "additionalParticipantFormerEmployer"
                    }
                    id={
                      shouldDisplayEmployer
                        ? "additionalParticipantEmployer"
                        : "additionalParticipantFormerEmployer"
                    }
                    value={
                      shouldDisplayEmployer
                        ? additionalParticipantEmployer || ""
                        : additionalParticipantFormerEmployer || ""
                    }
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <InputLabel id="industry" className={classes.selectLabel}>
                    Select an industry
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="additionalParticipantIndustry"
                    name="additionalParticipantIndustry"
                    id="additionalParticipantIndustry"
                    value={additionalParticipantIndustry || ""}
                    onChange={handleChange}
                    readOnly={readOnly}
                  >
                    {mapIndustriesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {/* ID verification */}
                <Box mt={4}>
                  <Paper>
                    <Box p={3}>
                      <Box>
                        <Typography variant="body1" color="primary">
                          <b>Identity verification</b>
                        </Typography>
                      </Box>
                      <Box pt={3}>
                        {shouldDisplayNotReadyNoApplication ? (
                          <Box>
                            <Alert variant="outlined" severity="error">
                              Please save the application first before
                              completing this section.
                            </Alert>
                          </Box>
                        ) : (
                          <Box>
                            {shouldDisplayCustomerNotBegun && (
                              <Alert variant="outlined" severity="error">
                                The applicant has not completed the
                                identification process{" "}
                              </Alert>
                            )}
                            <Box mb={0.5}>
                              {additionalParticipantIdentityJumioFailAt &&
                                !additionalParticipantIdentityJumioSuccessAt && (
                                  <Alert severity="error">
                                    Identity verification failed
                                    <Box mt={1}>
                                      <Typography variant="body2">
                                        Reason:{" "}
                                        {
                                          additionalParticipantIdentityJumioReason
                                        }
                                      </Typography>
                                    </Box>
                                  </Alert>
                                )}
                              {!!additionalParticipantIdentityJumioSuccessAt && (
                                <Alert severity="success">
                                  Identity verification passed
                                </Alert>
                              )}
                              {!!additionalParticipantIdentityJumioCallback && (
                                <>
                                  <Box className={localClasses.idImages}>
                                    {idImages.selfie && (
                                      <Box className={localClasses.imgWrapper}>
                                        <a
                                          href={idImages.selfie}
                                          download="face.jpeg"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            id="id-img-selfie-add-part"
                                            src={idImages.selfie}
                                            alt="selfie"
                                            onError={handleErrorImages}
                                          />
                                        </a>
                                        <Typography variant="caption">
                                          Face
                                        </Typography>
                                      </Box>
                                    )}
                                    {idImages.front && (
                                      <Box className={localClasses.imgWrapper}>
                                        <a
                                          href={idImages.front}
                                          download="id_front.jpeg"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            id="id-img-front-add-part"
                                            src={idImages.front}
                                            alt="ID Front"
                                            onError={handleErrorImages}
                                          />
                                        </a>
                                        <Typography variant="caption">
                                          ID Front
                                        </Typography>
                                      </Box>
                                    )}
                                    {idImages.back && (
                                      <Box className={localClasses.imgWrapper}>
                                        <a
                                          href={idImages.back}
                                          download="id_back.jpeg"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            id="id-img-back-add-part"
                                            src={idImages.back}
                                            alt="ID back"
                                            onError={handleErrorImages}
                                          />
                                        </a>
                                        <Typography variant="caption">
                                          ID Back
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  <Box mt={1} mb={4}>
                                    <>
                                      {errorImages ? (
                                        <Typography
                                          color="error"
                                          variant="body2"
                                        >
                                          There was an issue loading id images
                                        </Typography>
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          onClick={downloadAllImages}
                                        >
                                          Download all images
                                        </Button>
                                      )}
                                    </>
                                  </Box>
                                </>
                              )}
                              <Box pt={2}>
                                <Typography variant="body2">
                                  <b>Identity data</b>
                                </Typography>
                              </Box>
                              <Box>
                                <InputLabel
                                  id="addPartIdentityId"
                                  className={classes.selectLabel}
                                >
                                  ID type`
                                </InputLabel>
                                <Select
                                  fullWidth
                                  labelId="addPartIdentityId"
                                  name="additionalParticipantIdentityIdType"
                                  id="additionalParticipantIdentityIdType"
                                  value={
                                    additionalParticipantIdentityIdType || ""
                                  }
                                  onChange={handleChange}
                                  readOnly={readOnly}
                                >
                                  <MenuItem
                                    key={"DRIVING LICENSE"}
                                    value={"DRIVING LICENSE"}
                                  >
                                    Driving License
                                  </MenuItem>
                                  <MenuItem key={"ID CARD"} value={"ID CARD"}>
                                    ID Card
                                  </MenuItem>
                                  <MenuItem key={"PASSPORT"} value={"PASSPORT"}>
                                    Passport
                                  </MenuItem>
                                </Select>
                              </Box>
                              <Box my={2}>
                                <TextField
                                  id={`additionalParticipantIdentityNumberOnId`}
                                  name={`additionalParticipantIdentityNumberOnId`}
                                  label={`Number on ID`}
                                  value={
                                    additionalParticipantIdentityNumberOnId ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  fullWidth
                                  inputProps={{
                                    readOnly: Boolean(readOnly),
                                  }}
                                />
                              </Box>
                              <Box my={2}>
                                <FormLabel component="legend">
                                  {"Expiry on ID"}
                                </FormLabel>
                                <DatePicker
                                  id={`datepicker-addPartidentityExpiryDateOfId`}
                                  initialDate={
                                    additionalParticipantIdentityExpiryDateOfId
                                  }
                                  dateHandler={updateField.bind(
                                    null,
                                    "additionalParticipantIdentityExpiryDateOfId"
                                  )}
                                  disabled={readOnly}
                                  fullWidth
                                  shouldInitToday={false}
                                  formatToSave="LL/dd/yyyy"
                                />
                              </Box>
                              <Box my={2}>
                                <FormLabel component="legend">
                                  {"Issue date on ID"}
                                </FormLabel>
                                <DatePicker
                                  id={`datepicker-addPartidentityIssueDateOfId`}
                                  initialDate={
                                    additionalParticipantIdentityIssueDateOfId
                                  }
                                  dateHandler={updateField.bind(
                                    null,
                                    "additionalParticipantIdentityIssueDateOfId"
                                  )}
                                  disabled={readOnly}
                                  fullWidth
                                  shouldInitToday={false}
                                  formatToSave="LL/dd/yyyy"
                                />
                              </Box>
                              <Box my={2}>
                                <InputLabel
                                  id="addPartidentityState"
                                  className={classes.selectLabel}
                                >
                                  State
                                </InputLabel>
                                <Select
                                  fullWidth
                                  labelId="addPartidentityState"
                                  name="additionalParticipantIdentityIssuingStateOfId"
                                  id="additionalParticipantIdentityIssuingStateOfId"
                                  value={
                                    additionalParticipantIdentityIssuingStateOfId ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  readOnly={readOnly}
                                >
                                  {mapStatesToOptions().map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                      {opt.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                              <Box my={2}>
                                <InputLabel
                                  id="addPartIdentityCountry"
                                  className={classes.selectLabel}
                                >
                                  Country on Passport
                                </InputLabel>
                                <Select
                                  fullWidth
                                  labelId="addPartIdentityCountry"
                                  name="additionalParticipantIdentityIssuingCountryOfPassport"
                                  id="additionalParticipantIdentityIssuingCountryOfPassport"
                                  value={
                                    getTwoLetterCountryCode(
                                      additionalParticipantIdentityIssuingCountryOfPassport
                                    ) || ""
                                  }
                                  onChange={handleChange}
                                  displayEmpty
                                  readOnly={readOnly}
                                >
                                  <MenuItem value="" disabled dense>
                                    Select a country
                                  </MenuItem>
                                  {mapCountriesToOptions().map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                      {opt.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </Box>
                            {shouldDisplayLinkGeneration && (
                              <Box mt={4}>
                                <Box>
                                  <Typography variant="body2">
                                    <b>
                                      Instructions for Identity Verification
                                    </b>
                                  </Typography>
                                  <Typography variant="body2">
                                    Clicking the button below will generate a
                                    link to Stripe, the online identity
                                    verification platform we use for background
                                    and identity checks.
                                  </Typography>
                                </Box>
                                <Box mt={3}>
                                  <Typography variant="body2">
                                    Email the applicant this link with
                                    completion instructions.
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  mt={4}
                                  flexDirection="column"
                                >
                                  <Box position="relative">
                                    <Button
                                      variant="outlined"
                                      disabled={isLoading}
                                      onClick={
                                        link
                                          ? copyLinkAndInstructions
                                          : generateLink
                                      }
                                    >
                                      {isLoading
                                        ? "Generating link"
                                        : link
                                        ? "Copy link and instructions"
                                        : "Generate link"}
                                    </Button>
                                    {isLoading && (
                                      <Box
                                        position="absolute"
                                        top="50%"
                                        left="50%"
                                        mt="-12px"
                                        ml="-12px"
                                      >
                                        <CircularProgress
                                          size={24}
                                          color="secondary"
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                  <Box mt={3} textAlign="center">
                                    <Typography variant="body2">
                                      {link
                                        ? "The button above will copy the Stripe along with instructions for the applicant for completing the process. Paste this in an email to the applicant."
                                        : "This link will be active for 2 weeks."}
                                    </Typography>
                                    {link ? (
                                      <Box
                                        mt={1}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Box>
                                          <Typography variant="body2">
                                            If you need to create a new link,
                                          </Typography>
                                        </Box>
                                        <Box ml={0.5}>
                                          <Link
                                            className={classes.linkStyleButton}
                                            component="button"
                                            variant="body2"
                                            onClick={generateLink}
                                            type="button"
                                          >
                                            click here
                                          </Link>
                                        </Box>
                                      </Box>
                                    ) : (
                                      <Typography variant="body2">
                                        If the applicant does not complete the
                                        process before then, you can create a
                                        new link here.
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                {/* Security */}
                <Box>
                  <Box mt={6}>
                    <Typography variant="body1" color="primary">
                      <b>Security Information</b>
                    </Typography>
                  </Box>
                  <Box mt={1}>{ssn}</Box>
                </Box>
                {/* Docs */}
                <Box>
                  <Box display="flex" flexDirection="column">
                    {docsToUpload.map(({ name, label, ref }) => {
                      const shouldDisplayIcon = files[name] || ref;
                      return (
                        <Box key={name} mb={4}>
                          <Typography>{label}</Typography>
                          <Box display="flex" alignItems="center">
                            <FileUpload
                              name={name}
                              changeHandler={onSelectFile}
                            />
                            <Box ml={1}>
                              <Typography variant="body2">
                                {shouldDisplayIcon && <DescriptionIcon />}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box>
                    {isEmpty(filesToDownload) ? (
                      <Box>
                        <Typography>No files to download</Typography>
                      </Box>
                    ) : (
                      filesToDownload.map(({ name, label, ref }) => {
                        return (
                          <Box
                            key={name}
                            display="flex"
                            alignItems="center"
                            mr={6}
                          >
                            <Box mr={0.5}>
                              <Typography variant="body2">
                                <IconButton onClick={() => downloadFile(ref)}>
                                  <DescriptionIcon />
                                </IconButton>
                              </Typography>
                            </Box>
                            <Typography>{label}</Typography>
                          </Box>
                        );
                      })
                    )}
                  </Box>
                </Box>
              </>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

AdditionalParticipant.defaultProps = {
  addAdditionalParticipant: null,
  additionalParticipantFirstName: "",
  additionalParticipantMiddleName: "",
  additionalParticipantLastName: "",
  additionalParticipantEmail: "",
  additionalParticipantPhone: "",
  additionalParticipantUsaCitizen: null,
  additionalParticipantCountryCitizenship: "",
  additionalParticipantUsaPermanentResident: null,
  additionalParticipantHasUSAddress: null,
  additionalParticipantForeignAddressLine1: "",
  additionalParticipantForeignAddressApt: "",
  additionalParticipantForeignAddressCity: "",
  additionalParticipantForeignAddressState: "",
  additionalParticipantForeignAddressPostcode: "",
  additionalParticipantForeignAddressCountry: "",
  additionalParticipantHasForeignAddress: null,
  additionalParticipantIdentityExpiryDateOfId: "",
  additionalParticipantIdentityIdType: "",
  additionalParticipantIdentityIssueDateOfId: "",
  additionalParticipantIdentityIssuingCountryOfPassport: "",
  additionalParticipantIdentityIssuingStateOfId: "",
  additionalParticipantIdentityJumioFailAt: undefined,
  additionalParticipantIdentityJumioSuccessAt: undefined,
  additionalParticipantIdentityNumberOnId: "",
  additionalParticipantIdentityJumioReason: "",
  additionalParticipantSecurityAnswer1: "",
  additionalParticipantSecurityAnswer2: "",
  additionalParticipantSecurityAnswer3: "",
  additionalParticipantSecurityQuestion1: "",
  additionalParticipantSecurityQuestion2: "",
  additionalParticipantSecurityQuestion3: "",
  additionalParticipantSocialSecurity: "",
  additionalParticipantGender: null,
  additionalParticipantBirthDate: new Date(),
  additionalParticipantMaritalStatus: "",
  additionalParticipantMaidenName: "",
  additionalParticipantEmploymentStatus: "",
  additionalParticipantCurrentOrRecentJobTitle: "",
  additionalParticipantEmployer: "",
  additionalParticipantFormerEmployer: "",
  additionalParticipantIndustry: "",
  additionalParticipantAgreesToTextNotifications: null,
  additionalParticipantResCity: "",
  additionalParticipantResState: "",
  additionalParticipantResAddressLine1: "",
  additionalParticipantResPostcode: "",
  additionalParticipantResAptNumber: "",
  additionalParticipantResAddressEqualsMailing: null,
  additionalParticipantMailingCity: "",
  additionalParticipantMailingState: "",
  additionalParticipantMailingAddressLine1: "",
  additionalParticipantMailingAptNumber: "",
  additionalParticipantMailingPostcode: "",
  additionalParticipantPreviousAddressLine1: "",
  additionalParticipantPreviousAptNumber: "",
  additionalParticipantPreviousCity: "",
  additionalParticipantPreviousState: "",
  additionalParticipantPreviousPostcode: "",
  additionalParticipantHasResidedAtAddressForRequiredDuration: null,
  additionalParticipantGreenCardStorageRef: "",
  additionalParticipantW8BENStorageRef: "",
  readOnly: false,
};

AdditionalParticipant.propTypes = {
  addAdditionalParticipant: PropTypes.bool,
  additionalParticipantFirstName: PropTypes.string,
  additionalParticipantMiddleName: PropTypes.string,
  additionalParticipantLastName: PropTypes.string,
  additionalParticipantEmail: PropTypes.string,
  additionalParticipantPhone: PropTypes.string,
  additionalParticipantUsaCitizen: PropTypes.bool,
  additionalParticipantCountryCitizenship: PropTypes.string,
  additionalParticipantUsaPermanentResident: PropTypes.bool,
  additionalParticipantHasUSAddress: PropTypes.bool,
  additionalParticipantForeignAddressLine1: PropTypes.string,
  additionalParticipantForeignAddressApt: PropTypes.string,
  additionalParticipantForeignAddressCity: PropTypes.string,
  additionalParticipantForeignAddressState: PropTypes.string,
  additionalParticipantForeignAddressPostcode: PropTypes.string,
  additionalParticipantForeignAddressCountry: PropTypes.string,
  additionalParticipantHasForeignAddress: PropTypes.bool,
  additionalParticipantIdentityExpiryDateOfId: PropTypes.string,
  additionalParticipantIdentityIdType: PropTypes.string,
  additionalParticipantIdentityIssueDateOfId: PropTypes.string,
  additionalParticipantIdentityIssuingCountryOfPassport: PropTypes.string,
  additionalParticipantIdentityIssuingStateOfId: PropTypes.string,
  additionalParticipantIdentityJumioFailAt: PropTypes.object,
  additionalParticipantIdentityJumioSuccessAt: PropTypes.object,
  additionalParticipantIdentityNumberOnId: PropTypes.string,
  additionalParticipantIdentityJumioReason: PropTypes.string,
  additionalParticipantSecurityAnswer1: PropTypes.string,
  additionalParticipantSecurityAnswer2: PropTypes.string,
  additionalParticipantSecurityAnswer3: PropTypes.string,
  additionalParticipantSecurityQuestion1: PropTypes.string,
  additionalParticipantSecurityQuestion2: PropTypes.string,
  additionalParticipantSecurityQuestion3: PropTypes.string,
  additionalParticipantSocialSecurity: PropTypes.string,
  additionalParticipantGender: PropTypes.string,
  additionalParticipantBirthDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  additionalParticipantMaritalStatus: PropTypes.string,
  additionalParticipantMaidenName: PropTypes.string,
  additionalParticipantEmploymentStatus: PropTypes.string,
  additionalParticipantCurrentOrRecentJobTitle: PropTypes.string,
  additionalParticipantEmployer: PropTypes.string,
  additionalParticipantFormerEmployer: PropTypes.string,
  additionalParticipantIndustry: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  additionalParticipantAgreesToTextNotifications: PropTypes.bool,
  additionalParticipantResCity: PropTypes.string,
  additionalParticipantResState: PropTypes.string,
  additionalParticipantResAddressLine1: PropTypes.string,
  additionalParticipantResPostcode: PropTypes.string,
  additionalParticipantResAptNumber: PropTypes.string,
  additionalParticipantResAddressEqualsMailing: PropTypes.bool,
  additionalParticipantMailingCity: PropTypes.string,
  additionalParticipantMailingState: PropTypes.string,
  additionalParticipantMailingAddressLine1: PropTypes.string,
  additionalParticipantMailingAptNumber: PropTypes.string,
  additionalParticipantMailingPostcode: PropTypes.string,
  additionalParticipantGreenCardStorageRef: PropTypes.string,
  additionalParticipantW8BENStorageRef: PropTypes.string,
  readOnly: PropTypes.bool,
  isLoadingSSNs: PropTypes.bool,
};

const inputs = [
  "addAdditionalParticipant",
  "additionalParticipantFirstName",
  "additionalParticipantMiddleName",
  "additionalParticipantLastName",
  "additionalParticipantEmail",
  "additionalParticipantPhone",
  "additionalParticipantUsaCitizen",
  "additionalParticipantCountryCitizenship",
  "additionalParticipantUsaPermanentResident",
  "additionalParticipantHasUSAddress",
  "additionalParticipantForeignAddressLine1",
  "additionalParticipantForeignAddressApt",
  "additionalParticipantForeignAddressCity",
  "additionalParticipantForeignAddressState",
  "additionalParticipantForeignAddressPostcode",
  "additionalParticipantForeignAddressCountry",
  "additionalParticipantHasForeignAddress",
  "additionalParticipantIdentityExpiryDateOfId",
  "additionalParticipantIdentityIdType",
  "additionalParticipantIdentityIssueDateOfId",
  "additionalParticipantIdentityIssuingCountryOfPassport",
  "additionalParticipantIdentityIssuingStateOfId",
  "additionalParticipantIdentityJumioFailAt",
  "additionalParticipantIdentityJumioSuccessAt",
  "additionalParticipantIdentityNumberOnId",
  "additionalParticipantIdentityJumioReason",
  "additionalParticipantSecurityAnswer1",
  "additionalParticipantSecurityAnswer2",
  "additionalParticipantSecurityAnswer3",
  "additionalParticipantSecurityQuestion1",
  "additionalParticipantSecurityQuestion2",
  "additionalParticipantSecurityQuestion3",
  "additionalParticipantSocialSecurity",
  "additionalParticipantGender",
  "additionalParticipantBirthDate",
  "additionalParticipantMaritalStatus",
  "additionalParticipantMaidenName",
  "additionalParticipantEmploymentStatus",
  "additionalParticipantCurrentOrRecentJobTitle",
  "additionalParticipantEmployer",
  "additionalParticipantFormerEmployer",
  "additionalParticipantIndustry",
  "additionalParticipantAgreesToTextNotifications",
  "additionalParticipantResCity",
  "additionalParticipantResState",
  "additionalParticipantResAddressLine1",
  "additionalParticipantResPostcode",
  "additionalParticipantResAptNumber",
  "additionalParticipantResAddressEqualsMailing",
  "additionalParticipantMailingCity",
  "additionalParticipantMailingState",
  "additionalParticipantMailingAddressLine1",
  "additionalParticipantMailingAptNumber",
  "additionalParticipantMailingPostcode",
  "additionalParticipantPreviousAddressLine1",
  "additionalParticipantPreviousAptNumber",
  "additionalParticipantPreviousCity",
  "additionalParticipantPreviousState",
  "additionalParticipantPreviousPostcode",
  "additionalParticipantHasResidedAtAddressForRequiredDuration",
  "additionalParticipantGreenCardStorageRef",
  "additionalParticipantW8BENStorageRef",
  "files",
  "readOnly",
  "isLoadingSSNs",
];

export default memo(AdditionalParticipant, (prev, next) =>
  equality(prev, next, inputs)
);
