import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Input,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import proptypes from "prop-types";

import MaskedInput from "react-text-mask";

const mask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
const mask2 = [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const useStyles = makeStyles({
  ssn: {
    display: "flex",
    "& svg": {
      cursor: "pointer",
    },
  },
});

// Custom Input for SSN which can be passed to Material UI TextField/Input
function SSNMask(props) {
  const { inputRef, ...other } = props;
  // console.log("other", other);
  const maskType = other.mask === "ssn" ? mask : mask2;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskType}
      placeholderChar={"\u2000"}
      showMask
      guide={false}
    />
  );
}

SSNMask.propTypes = {
  inputRef: proptypes.func.isRequired,
};

const SSNInput = ({
  label,
  name,
  id,
  value,
  handleChange,
  maskType = "ssn",
  disabled = false,
  readOnly = false,
  isLoading = false,
}) => {
  const [isSSNVisible, handleSSNVisibility] = useState(false);
  const classes = useStyles();
  // console.log("ssn input rerendered for ", name);
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input
        value={value || ""}
        onChange={handleChange}
        name={name}
        type={isSSNVisible ? "text" : "password"}
        id={id || name}
        inputProps={{ mask: maskType }}
        inputComponent={SSNMask}
        endAdornment={
          <Box className={classes.ssn}>
            <Box onClick={() => handleSSNVisibility(!isSSNVisible)}>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : isSSNVisible ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </Box>
          </Box>
        }
        readOnly={readOnly}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default SSNInput;
