import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { UserContext } from "components/User";
// we route here after authentication if not authorized
// for authorization we check subdomain against authority on auth object
const NotAuthorized = () => {
  const user = useContext(UserContext);
  return (
    <main>
      {user.isLoggedIn === false && user.isLoadingAuthListener === false ? (
        <Redirect to="/login" />
      ) : (
        <div>
          <h1>You are not authorized to be at this page</h1>
        </div>
      )}
    </main>
  );
};

export default NotAuthorized;
