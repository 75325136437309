import { getToken, request } from "../requests";
import { FIREBASE_URLS } from "config/firebase";

export async function sendSms(msg, phoneNumber) {
  try {
    const token = await getToken();
    // don't do retries with this request
    const res = await request(
      FIREBASE_URLS.sendCustomerSms,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          msg,
          to: phoneNumber,
        }),
      },
      0
    );
    if (!res) throw Error(JSON.stringify({ msg: "response not returned" }));
    const data = await res.json();
    return data;
  } catch (error) {
    throw error;
  }
}
