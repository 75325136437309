import { getToken, request } from "../requests";
import { FIREBASE_URLS } from "config/firebase";
import * as Sentry from '@sentry/react'

import {
  addTimestamps,
  participantsCollection,
  applicationsCollection,
} from "../requests";

export async function createAdditionalParticipant(
  data,
  applicationId,
  additionalParticipantId,
  authority,
  referralSource,
  primaryParticipantId
) {
  try {
    if (!data) return null;
    if (additionalParticipantId) return null;
    const payload = {
      firstName: data.additionalParticipantFirstName || "",
      middleName: data.additionalParticipantMiddleName || "",
      lastName: data.additionalParticipantLastName || "",
      applicationReferrer: referralSource,
      applicationProvider: authority,
      primaryAccountParticipantId: primaryParticipantId,
    };
    if (data.additionalParticipantEmail)
      payload.email = data.additionalParticipantEmail;
    if (data.additionalParticipantPhone)
      payload.phoneNumber = data.additionalParticipantPhone;
    addTimestamps(payload);
    payload.isPrimary = false;
    payload.applicationId = applicationId;
    const { id: participantId } = await participantsCollection.add(payload);
    // update application now with this ref
    await applicationsCollection.doc(applicationId).update({
      additionalParticipantId: participantId,
    });
    return participantId;
  } catch (error) {
    console.error(error);
    // rollbar.error(error, "createAdditionalParticipant");
    Sentry.captureException(error, { extra: { msg: "createAdditionalParticipant" } })
    throw error;
  }
}

export async function removeAdditionalParticipant(addPartId, applicationId) {
  try {
    // rollbar.log(
    //   `removing add part: partId: ${addPartId}, app: ${applicationId}`
    // );
    Sentry.captureMessage(`removing add part: partId: ${addPartId}, app: ${applicationId}`)
    // delete additional participant from firestore
    await participantsCollection.doc(addPartId).delete();
    // remove additional participant fields
    console.log("now updating the application");
    await applicationsCollection.doc(applicationId).update({
      // addAdditionalParticipant and other data will be updated from main application update
      additionalParticipantId: null,
      additionalParticipantSubmittedAt: null,
    });
  } catch (error) {
    // rollbar.error(error, "remove additional participant");
    Sentry.captureException(error, { extra: { msg: "remove additional participant" } })
    throw error;
  }
}

export async function getAdditionalParticipantLink(
  primaryParticipantId = "",
  additionalParticipantIds = ""
) {
  try {
    const token = await getToken();
    const res = await request(
      `${FIREBASE_URLS.additionalParticipantLink}?primaryParticipantId=${primaryParticipantId}&additionalParticipantId=${additionalParticipantIds}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res) throw Error("response not returned");
    const data = await res.json();
    const link = data.url;
    return link;
  } catch (error) {
    // rollbar.error(error, "add part link fetch");
    Sentry.captureException(error, { extra: { msg: "add part link fetch" } })
    return "";
  }
}
