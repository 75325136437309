import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

const Spinner = ({ size = 50 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={size} />
    </div>
  );
};

export default Spinner;
