import { DateTime } from "luxon";
import { industries } from "../util/helpers";

const SELF_EMPLOYED = "self-employed";
const REFERRER = "referrer";

function resolveBirthdayFlag(val) {
  if (!val) return false;
  const isString = typeof val === "string";
  const now = DateTime.local();
  let date;
  try {
    if (isString) {
      date = DateTime.fromFormat(val, "LL/dd/yyyy");
    } else {
      date = DateTime.fromJSDate(val);
    }
    const diff = now.diff(date, "years");
    const years = diff.toObject().years;
    return years < 18;
  } catch (e) {
    return true;
  }
}

export const flagFields = {
  usaCitizen: (val) => val === false,
  hasSubmitted: (val) => val === false,
  birthDate: (val) => {
    return resolveBirthdayFlag(val);
  },
  identityJumioFailAt: (val, allValues) => {
    if (!allValues.identityJumioSuccessAt && !!val) {
      return true;
    }
    return false;
  },
  identityIdType: (val) => !val,
  identityIssueDateOfId: (val) => !val,
  identityNumberOnId: (val) => !val,
  // additional participant
  addAdditionalParticipant: (val) => val === true,
  additionalParticipantBirthDate: (val) => {
    return resolveBirthdayFlag(val);
  },
  w8BENStorageRef: (_, allValues) => {
    // always display even if it has been uploaded
    if (allValues.usaPermanentResident === false) {
      return true;
    }
    return false;
  },
  industry: (val) => {
    // Cannabis
    return val === industries["Cannabis"];
  },
  employmentStatus: (val) => {
    return val === SELF_EMPLOYED;
  },
  createdByUserType: (val) => {
    return val === REFERRER;
  },
  doesRequireSpousalConsent: (val) => val === true,
  additionalParticipantUsaCitizen: (val, allValues) =>
    allValues.addAdditionalParticipant === true && val === false,
  additionalParticipantIndustry: (
    val // Cannabis
  ) => {
    return val === industries["Cannabis"];
  },
  additionalParticipantEmploymentStatus: (val) => {
    return val === SELF_EMPLOYED;
  },
  additionalParticipantIdentityJumioFailAt: (val, allValues) => {
    if (
      allValues.addAdditionalParticipant === true &&
      !!val &&
      !allValues.additionalParticipantIdentityJumioSuccessAt
    ) {
      return true;
    }
    return false;
  },
  additionalParticipantIdentityIdType: (val, allValues) =>
    allValues.addAdditionalParticipant === true && !val,
  additionalParticipantIdentityIssueDateOfId: (val, allValues) =>
    allValues.addAdditionalParticipant === true && !val,
  additionalParticipantIdentityNumberOnId: (val, allValues) =>
    allValues.addAdditionalParticipant === true && !val,
  additionalParticipantW8BENStorageRef: (_, allValues) => {
    if (
      allValues.addAdditionalParticipant === true &&
      allValues.additionalParticipantUsaPermanentResident === false
    ) {
      return true;
    }
    return false;
  },
};

export async function resolveFlag({
  key = "",
  value,
  label = "",
  allValues = {},
  type = "",
}) {
  return new Promise(async (resolve) => {
    let shouldFlag = false;
    if (typeof key === "object") {
      key.forEach((field) => {
        shouldFlag = flagFields[field](allValues[field], allValues);
      });
    } else {
      if (flagFields[key]) {
        shouldFlag = flagFields[key](value, allValues);
      }
    }

    if (shouldFlag) {
      resolve({ [key]: value, label, type });
    }
    return resolve(null);
  });
}

// These set flag field and label for flag
export const flagOptions = [
  { name: "usaCitizen", label: "Not USA Citizen", type: "primary" },
  { name: "hasSubmitted", label: "Submitted", type: "primary" },
  { name: "birthDate", label: "Minor", type: "primary" },
  {
    name: "identityJumioFailAt",
    label: "Identity Ver. Failed",
    type: "primary",
  },
  {
    name: ["identityIdType", "identityIssueDateOfId", "identityNumberOnId"],
    label: "Identity Info Missing",
    type: "primary",
  },
  {
    name: "w8BENStorageRef",
    label: "W8BEN required",
    type: "primary",
  },
  {
    name: "doesRequireSpousalConsent",
    label: "Requires spousal consent",
    type: "primary",
  },
  {
    name: "industry",
    label: "Cannabis industry",
    type: "primary",
  },
  {
    name: "employmentStatus",
    label: "Self-employed",
    type: "primary",
  },
  {
    name: "createdByUserType",
    label: "Referrer created",
    type: "primary",
  },
  {
    name: "addAdditionalParticipant",
    label: "Has Additional Participant",
    type: "secondary",
  },
  {
    name: "additionalParticipantUsaCitizen",
    label: "Add Part. Not USA Citizen",
    type: "secondary",
  },
  {
    name: "additionalParticipantBirthDate",
    label: "Add Part. Minor",
    type: "secondary",
  },
  {
    name: "additionalParticipantIdentityJumioFailAt",
    label: "Add Part Identity Ver. Failed",
    type: "secondary",
  },
  {
    name: [
      "additionalParticipantIdentityIdType",
      "additionalParticipantIdentityIssueDateOfId",
      "additionalParticipantIdentityNumberOnId",
    ],
    label: "Add part Identity Info Missing",
    type: "secondary",
  },
  {
    name: "additionalParticipantW8BENStorageRef",
    label: "Add Part. Requires W8BEN",
    type: "secondary",
  },
  {
    name: "additionalParticipantIndustry",
    label: "Add Part. Cannabis industry",
    type: "secondary",
  },
  {
    name: "additionalParticipantEmploymentStatus",
    label: "Add Part. Self-employed",
    type: "secondary",
  },
];
