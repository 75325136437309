import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

import App from "./App";
import manageConsole from "./util/console";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://33ba3cfa480a47f78a19c110dec455de@o1025860.ingest.sentry.io/6396989",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

manageConsole();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
