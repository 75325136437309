import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { UserContext } from "components/User";
import Spinner from "components/Spinner";

import subdomainsMap from "config/subdomain";

const subdomain = window.location.host.split(".").length > 0
  ? window.location.host.split(".")[0]
  : false;

function PrivateRoute({ children, ...rest }) {
  const {
    isLoggedIn,
    authorityDomain,
    isLoadingAuthListener,
    errorLogin,
  } = useContext(UserContext);
  const isAuthorized = subdomainsMap[subdomain] === authorityDomain;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoadingAuthListener ? (
          <main>
            <Spinner />
          </main>
        ) : !!errorLogin ? (
          <Box
            display="flex"
            height="80vh"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6" color="error">
              Error authorizing your account. Please contact an administrator.
            </Typography>
          </Box>
        ) : isLoggedIn ? (
          isAuthorized ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/not-authorized",
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
