import React, { memo } from "react";
import {
  Box,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";

import FileUpload from "components/FileUpload";
import Accordion from "components/Accordion";
import { isEmpty } from "lodash";
import { downloadFile } from "util/requests";
import { equality } from "util/helpers";
import {
  CHECKING_ACCOUNT_TYPE_LLC,
  CHECKING_ACCOUNT_TYPE_TRUST,
} from "config/constants";

const Documents = ({
  section,
  onSelectFile,
  referralSource,
  files,
  trustStorageRef,
  llcOperatingAgreementRef,
  llcArticlesOfOrganizationRef,
  ss4StorageRef,
  greenCardStorageRef,
  w8BENStorageRef,
  checkingAccountType,
  usaPermanentResident,
  usaCitizen,
  readOnly,
}) => {
  console.log("rendering documents", trustStorageRef, files);
  const fileObjs = [
    {
      name: "ss4StorageRef",
      label: "IRA SS4",
      ref: ss4StorageRef,
    },
    { name: "trustStorageRef", label: "Trust", ref: trustStorageRef },
    {
      name: "llcOperatingAgreementRef",
      label: "IRA LLC Operating Agreement",
      ref: llcOperatingAgreementRef,
    },
    {
      name: "llcArticlesOfOrganizationRef",
      label: "IRA LLC Articles of Organization",
      ref: llcArticlesOfOrganizationRef,
    },
    {
      name: "greenCardStorageRef",
      label: "Green card",
      ref: greenCardStorageRef,
    },
    {
      name: "w8BENStorageRef",
      label: "W8-BEN (non-US citizens)",
      ref: w8BENStorageRef,
    },
  ];
  const docsToUpload = [];
  fileObjs.forEach((doc) => {
    if (checkingAccountType === CHECKING_ACCOUNT_TYPE_TRUST) {
      if (doc.name === "trustStorageRef") docsToUpload.push(doc);
      if (doc.name === "ss4StorageRef") {
        if (
          referralSource === "SORTIS" ||
          referralSource === "GRATIS" ||
          referralSource === "JEF BAKER"
        ) {
          docsToUpload.push(doc);
        }
      }
    } else if (checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC) {
      if (doc.name === "llcOperatingAgreementRef") docsToUpload.push(doc);
      if (doc.name === "llcArticlesOfOrganizationRef") docsToUpload.push(doc);
    }
    if (usaCitizen === false) {
      if (doc.name === "w8BENStorageRef") docsToUpload.push(doc);
      if (usaPermanentResident === true) {
        if (doc.name === "greenCardStorageRef") docsToUpload.push(doc);
      }
    }
  });
  const filesToDownload =
    Array.isArray(fileObjs) && fileObjs.filter((file) => !!file.ref);
  console.log("files to download", filesToDownload);
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${section}-content`}
          id={`${section}-header`}
        >
          <Typography variant="h6">
            Upload Documents
            {readOnly === true && (
              <Typography variant="body2" style={{ color: "green" }}>
                *Documents can still be uploaded in 'READ-ONLY'
              </Typography>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            {docsToUpload.map(({ name, label, ref }) => {
              const shouldDisplayIcon = files[name] || ref;
              return (
                <Box key={name} mb={4}>
                  <Typography>{label}</Typography>
                  <Box display="flex" alignItems="center">
                    <FileUpload name={name} changeHandler={onSelectFile} />
                    <Box ml={1}>
                      <Typography variant="body2">
                        {shouldDisplayIcon && <DescriptionIcon />}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${section}-content`}
          id={`${section}-header`}
        >
          <Typography variant="h6">Documents to download</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isEmpty(filesToDownload) ? (
            <Box>
              <Typography>No files to download</Typography>
            </Box>
          ) : (
            filesToDownload.map(({ name, label, ref }) => {
              console.log(name, label, ref);
              return (
                <Box key={name} display="flex" alignItems="center" mr={6}>
                  <Box mr={0.5}>
                    <Typography variant="body2">
                      <IconButton onClick={() => downloadFile(ref)}>
                        <DescriptionIcon />
                      </IconButton>
                    </Typography>
                  </Box>
                  <Typography>{label}</Typography>
                </Box>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const fields = [
  "files",
  "ss4StorageRef",
  "trustStorageRef",
  "llcOperatingAgreementRef",
  "llcArticlesOfOrganizationRef",
  "greenCardStorageRef",
  "w8BENStorageRef",
  "checkingAccountType",
  "usaPermanentResident",
  "usaCitizen",
  "referralSource",
  "readOnly",
];

export default memo(Documents, (prev, next) => equality(prev, next, fields));
