import React, { Component } from "react";
import { Box, Typography, withStyles } from "@material-ui/core";

import * as Sentry from '@sentry/react'

// TODO: probably replace with the Sentry ErrorBoundary

const styles = () => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 60px)",
    flexDirection: "column",
  },
});

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("catching in error boundary");
    // rollbar.error(error, errorInfo);
    Sentry.captureException(error, { extra: { errorInfo } })
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <Box className={classes.container}>
          <Typography variant="h5">Sorry something went wrong.</Typography>
          <Box mt={4}>
            <Typography variant="body1">
              This error has been reported and we're looking into it.
            </Typography>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
