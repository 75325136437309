import React, { useState, createContext, useEffect } from "react";
import firebase from "../../config/firebase";
import { firebase as firebaseAdmin } from "@firebase/app";
import { getAdmins } from "util/requests/admins";
import { uniqBy } from "lodash";
import { useIdleTimer } from "react-idle-timer";

import * as Sentry from '@sentry/react'

export const UserContext = createContext();

const DEV_IDLE_TIMEOUT_MIN = 60 * 24;
const IDLE_TIMEOUT_MIN =
  process.env.NODE_ENV === "development" ? DEV_IDLE_TIMEOUT_MIN : 20;

const INITIAL_STATE = {
  uid: "",
  email: "",
  firstName: "",
  lastName: "",
  isLoggedIn: false,
  authorityDomain: null,
  applicationsManaged: [],
  otherAdmins: [],
  // loading
  isLoadingAuthListener: true,
  isLoadingCreateNewUser: false,
  isLoadingSignIn: false,
  // errors
  errorLogin: "",
};

const usersCollection = firebase.firestore().collection("users");

/////////// DEV ///////////////
const shouldCheckAuthority = true;
const subdomain = window.location.host.split(".").length > 0
  ? window.location.host.split(".")[0]
  : false;
///////////////////////////////

const User = ({ children }) => {
  const [user, setUser] = useState({ ...INITIAL_STATE });

  function getOtherAdmins(domain, currentUser) {
    let local;
    try {
      local = sessionStorage.getItem("allAdmins");
    } catch (e) { }
    console.log("local", local);
    if (local) {
      const sessionObj = JSON.parse(local);
      const hasExpired = new Date().getTime() > sessionObj.expires;
      if (!hasExpired) {
        return setUser((prevState) => ({
          ...prevState,
          allAdmins: sessionObj.data,
        }));
      }
    }
    getAdmins(domain).then((admins) => {
      console.log("admins", admins);
      const me = {
        email: currentUser.email,
        adminId: currentUser.uid,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
      };
      const allAdmins = uniqBy([...admins, me], "email");
      const expiry = new Date().getTime() + 1000 * 60 * 60 * 2; // 2 hours
      sessionStorage.setItem(
        "allAdmins",
        JSON.stringify({ data: allAdmins, expires: expiry })
      );
      setUser((prevState) => ({
        ...prevState,
        allAdmins,
      }));
    });
  }

  function loadUser(user) {
    getOtherAdmins(user.authorityDomain, user);
    setUser((prevState) => ({
      ...prevState,
      ...user,
    }));
  }

  function createNewDBUser(user) {
    try {
      const payload = {
        uid: user.uid,
        email: user.email,
        provider: user.provider,
        createdAt: firebaseAdmin.firestore.Timestamp.now(),
        updatedAt: firebaseAdmin.firestore.Timestamp.now(),
      };
      return usersCollection
        .doc(user.uid)
        .set(payload)
        .then(() => {
          payload.authorityDomain = payload.provider;
          payload.isLoggedIn = true;
          payload.isLoadingAuthListener = false;
          loadUser(payload);
        });
    } catch (error) {
      console.error(error);
      // rollbar.error(error, "createNewDBUser");
      Sentry.captureException(error, { extra: { msg: 'createNewDBUser' } })
      throw error;
    }
  }

  const initAuth = () => {
    console.log("setting auth");
    // signOut();
    const auth = firebase.auth();
    return auth.onAuthStateChanged(
      async (authUser) => {
        if (authUser) {
          // if user exists get user object from firestore
          console.log("we have a logged in user");
          try {
            // get admin claims
            const { claims } = await auth.currentUser.getIdTokenResult();
            let { provider = null } = claims || {};
            if (
              process.env.NODE_ENV === "development" &&
              shouldCheckAuthority === false
            ) {
              provider = subdomain;
            }

            if (provider === null) throw Error("no provider authority is set");

            const doc = await usersCollection.doc(authUser.uid).get();
            if (!doc.exists) {
              const payload = authUser;
              payload.provider = provider;
              console.log("no user, we need to create one");
              await createNewDBUser(payload);
              return;
            }

            const user = doc.data();
            user.uid = doc.id;
            user.authorityDomain = provider;
            user.isLoggedIn = true;
            user.isLoadingAuthListener = false;
            addUserToErrorReporting(user);
            loadUser(user);
          } catch (error) {
            console.error("error in auth", error);
            // rollbar.error(error, "initAuth");
            Sentry.captureException(error, { extra: { msg: 'initAuth' } })
            setUser((prevState) => ({
              ...prevState,
              errorLogin: error.message,
              isLoadingAuthListener: false,
            }));
          }
        } else {
          // is not authenticated
          console.log("init listener but no user");
          setUser((prevState) => ({
            ...prevState,
            isLoadingAuthListener: false,
          }));
        }
      },
      (error) => {
        // rollbar.error(error, "auth listener error");
        Sentry.captureException(error, { extra: { msg: 'auth listener error' } })
      }
    );
  };

  function addUserToErrorReporting(user) {
    // rollbar.configure({
    //   payload: {
    //     person: {
    //       id: user.uid,
    //       email: user.email,
    //     },
    //   },
    // });
    Sentry.setUser({
      id: user.uid,
      email: user.email,
    })
  }

  // mount
  useEffect(() => {
    const unsubscribe = initAuth();
    return () => unsubscribe && unsubscribe();
    /* eslint-disable-next-line */
  }, []);

  function signIn({ email, password }) {
    setUser((prevState) => ({
      ...prevState,
      errorLogin: "",
    }));
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        // Handle Errors here.

        // const errorCode = error.code;
        const errorMessage = error.message;

        setUser((prevState) => ({
          ...prevState,
          errorLogin: errorMessage,
        }));
      });
  }

  function signOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser({ ...INITIAL_STATE, isLoadingAuthListener: false });
      })
      .catch((error) => {
        console.error(error);
        // rollbar.error(error, "signOut");
        Sentry.captureException(error, { extra: { msg: 'signOut' } })
      });
  }

  function endSession() {
    signOut();
  }

  const handleOnIdle = () => {
    console.log("user is idle");
    if (user.isLoggedIn) {
      endSession();
    }
  };

  const handleOnAction = (event) => {
    // we reset the timer here
    reset();
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * IDLE_TIMEOUT_MIN,
    onIdle: handleOnIdle,
    onActive: () => { },
    onAction: handleOnAction,
    debounce: 1000,
  });

  function sendPasswordResetEmail(email) {
    try {
      return firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
      // rollbar.error(error);
      Sentry.captureException(error, { extra: { msg: 'send password reset' } })
      // throw back to parent function to handle error
      throw error;
    }
  }

  return (
    <UserContext.Provider
      value={{
        ...user,
        signIn,
        signOut,
        sendPasswordResetEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default User;
