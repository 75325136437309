import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AddressAutoComplete from "components/AddressAutoComplete";
import Accordion from "components/Accordion";
import PhoneInput from "components/PhoneInput";

import {
  equality,
  mapCountriesToOptions,
  mapStatesToOptions,
} from "util/helpers";

const HomeAddressAutoComplete = ({
  handleAddressAutocomplete,
  homeAddressObj,
}) => (
  <AddressAutoComplete
    onSelectAddress={(addressObj) =>
      handleAddressAutocomplete(addressObj, "homeAddressObj")
    }
    initialValue={homeAddressObj}
    id="homeAddressAutoComplete"
  />
);

const MemoizedHomeAddressAutoComplete = memo(
  HomeAddressAutoComplete,
  (prev, next) => equality(prev, next, ["homeAddressObj"])
);

const MailingAddressAutoComplete = ({
  handleAddressAutocomplete,
  mailingAddressObj,
}) => (
  <AddressAutoComplete
    onSelectAddress={(addressObj) =>
      handleAddressAutocomplete(addressObj, "mailingAddressObj")
    }
    initialValue={mailingAddressObj}
    id="mailingAddressAutoComplete"
  />
);

const MemoizedMailingAddressAutoComplete = memo(
  MailingAddressAutoComplete,
  (prev, next) => equality(prev, next, ["mailingAddressObj"])
);

const PreviousAddressAutoComplete = ({
  handleAddressAutocomplete,
  previousAddressObj,
}) => (
  <AddressAutoComplete
    onSelectAddress={(addressObj) =>
      handleAddressAutocomplete(addressObj, "previousAddressObj")
    }
    initialValue={previousAddressObj}
    id="previousAddressAutoComplete"
  />
);

const MemoizedPreviousAddressAutoComplete = memo(
  PreviousAddressAutoComplete,
  (prev, next) => equality(prev, next, ["previousAddressObj"])
);

const ContactInfo = ({
  section,
  classes,
  firstName,
  lastName,
  middleName,
  email,
  phoneNumber,
  agreesToTextNotifications,
  usaCitizen,
  countryCitizenship,
  usaPermanentResident,
  hasUSAddress,
  foreignAddressLine1,
  foreignAddressApt,
  foreignAddressCity,
  foreignAddressState,
  foreignAddressPostcode,
  foreignAddressCountry,
  homeAddressObj,
  resCity,
  resState,
  resAddressLine1,
  resPostcode,
  resAptNumber,
  resAddressEqualsMailing,
  mailingCity,
  mailingState,
  mailingAddressLine1,
  mailingAptNumber,
  mailingPostcode,
  mailingAddressObj,
  handleChange,
  handleAddressAutocomplete,
  previousAddressLine1,
  previousAddressObj,
  previousAptNumber,
  previousCity,
  previousState,
  previousPostcode,
  hasResidedAtAddressForRequiredDuration,
  readOnly,
}) => {
  const hasAnsweredResQuestions =
    !!resAddressLine1 && !!resCity && !!resState && !!resPostcode;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${section}-content`}
        id={`${section}-header`}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          <Typography variant="h6">Contact Information</Typography>
          {/* <Typography variant="button">Incomplete</Typography> */}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.section}>
          <TextField
            className={classes.input}
            variant="standard"
            label="First Name"
            type="text"
            name="firstName"
            id="firstName"
            value={firstName || ""}
            onChange={handleChange}
            autoComplete="given-name"
            margin="dense"
            inputProps={{
              readOnly: Boolean(readOnly),
            }}
          ></TextField>
          <TextField
            className={classes.input}
            variant="standard"
            label="Middle name"
            type="text"
            name="middleName"
            id="middleName"
            value={middleName || ""}
            onChange={handleChange}
            autoComplete="middle-name"
            margin="dense"
            inputProps={{
              readOnly: Boolean(readOnly),
            }}
          ></TextField>
          <TextField
            className={classes.input}
            variant="standard"
            label="Last Name"
            type="text"
            name="lastName"
            id="lastName"
            value={lastName || ""}
            onChange={handleChange}
            autoComplete="family-name"
            margin="dense"
            inputProps={{
              readOnly: Boolean(readOnly),
            }}
          ></TextField>
          <TextField
            className={classes.input}
            variant="standard"
            label="Email"
            type="email"
            name="email"
            id="email"
            value={email || ""}
            onChange={handleChange}
            autoComplete="email"
            margin="dense"
            inputProps={{
              readOnly: Boolean(readOnly),
            }}
          ></TextField>
          <FormControl>
            <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
            <Input
              value={phoneNumber || ""}
              onChange={handleChange}
              name="phoneNumber"
              id="phoneNumber"
              inputComponent={PhoneInput}
              readOnly={readOnly}
            />
          </FormControl>
          <Box mt={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {"Agrees to text notifications"}
              </FormLabel>
              <RadioGroup
                aria-label={"agreesToTextNotifications"}
                name={"agreesToTextNotifications"}
                value={agreesToTextNotifications}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio disabled={readOnly} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio disabled={readOnly} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box my={4}>
            <Box mb={3}>
              <Typography variant="body1">
                <b>Citizenship</b>
              </Typography>
            </Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">{"US Citizen"}</FormLabel>
              <RadioGroup
                aria-label={"usaCitizen"}
                name={"usaCitizen"}
                value={usaCitizen}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio disabled={readOnly} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio disabled={readOnly} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {usaCitizen === false && (
            <>
              <InputLabel
                id="countryCitizenship"
                className={classes.selectLabel}
              >
                Country of citizenship
              </InputLabel>
              <Select
                fullWidth
                labelId="countryCitizenship"
                name="countryCitizenship"
                id="countryCitizenship"
                value={countryCitizenship || ""}
                onChange={handleChange}
                displayEmpty
                readOnly={readOnly}
              >
                <MenuItem value="" disabled dense>
                  Select a country
                </MenuItem>
                {mapCountriesToOptions().map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
              <Box mt={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {"Permanent resident"}
                  </FormLabel>
                  <RadioGroup
                    aria-label={"usaPermanentResident"}
                    name={"usaPermanentResident"}
                    value={usaPermanentResident}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio disabled={readOnly} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio disabled={readOnly} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {usaPermanentResident === true && usaCitizen === false && (
                <Box p={3}>
                  <Paper>
                    <Box p={3}>
                      <Typography color="error" variant="h6">
                        NOTE:
                      </Typography>
                      <Typography color="error">
                        An upload of a copy of the Green Card will be required
                        in the Document Uploads section below.
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              )}
              {usaPermanentResident === false && (
                <>
                  <Box mt={4}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {"Has US address?"}
                      </FormLabel>
                      <RadioGroup
                        aria-label={"hasUSAddress"}
                        name={"hasUSAddress"}
                        value={hasUSAddress}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio disabled={readOnly} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio disabled={readOnly} />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box mt={4}>
                    <TextField
                      fullWidth
                      className={classes.input}
                      variant="standard"
                      label="Foreign address line 1"
                      type="text"
                      name="foreignAddressLine1"
                      id="foreignAddressLine1"
                      value={foreignAddressLine1 || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <TextField
                      fullWidth
                      className={classes.input}
                      variant="standard"
                      label="Foreign address apartment"
                      type="text"
                      name="foreignAddressApt"
                      id="foreignAddressApt"
                      value={foreignAddressApt || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <TextField
                      fullWidth
                      className={classes.input}
                      variant="standard"
                      label="Foreign address city"
                      type="text"
                      name="foreignAddressCity"
                      id="foreignAddressCity"
                      value={foreignAddressCity || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <TextField
                      fullWidth
                      className={classes.input}
                      variant="standard"
                      label="Foreign address state, province, or county"
                      type="text"
                      name="foreignAddressState"
                      id="foreignAddressState"
                      value={foreignAddressState || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <TextField
                      fullWidth
                      className={classes.input}
                      variant="standard"
                      label="Foreign address postcode"
                      type="text"
                      name="foreignAddressPostcode"
                      id="foreignAddressPostcode"
                      value={foreignAddressPostcode || ""}
                      onChange={handleChange}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                      }}
                    ></TextField>
                    <InputLabel
                      id="foreignAddressCountryLabel"
                      className={classes.selectLabel}
                    >
                      Foreign address country
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="foreignAddressCountryLabel"
                      name="foreignAddressCountry"
                      id="foreignAddressCountry"
                      value={foreignAddressCountry || countryCitizenship || ""}
                      onChange={handleChange}
                      margin="dense"
                      displayEmpty
                      readOnly={readOnly}
                    >
                      <MenuItem value="" disabled dense>
                        Select a country
                      </MenuItem>
                      {mapCountriesToOptions().map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              )}
            </>
          )}
          <Box mt={2}>
            <Box mb={3}>
              <Typography variant="body1">
                <b>Residential Address</b>
              </Typography>
            </Box>
            <Box my={3}>
              <Paper variant="outlined">
                <Box style={{ background: "#fefefe" }} p={2}>
                  <InputLabel
                    id="homeAddressObj"
                    className={classes.selectLabel}
                  >
                    Residential Address Finder
                  </InputLabel>
                  {!readOnly && (
                    <MemoizedHomeAddressAutoComplete
                      handleAddressAutocomplete={handleAddressAutocomplete}
                      initialValue={homeAddressObj}
                    />
                  )}
                </Box>
              </Paper>
            </Box>
            <Box>
              <TextField
                fullWidth
                className={classes.input}
                variant="standard"
                label="Residential address line 1"
                type="text"
                name="resAddressLine1"
                id="resAddressLine1"
                value={resAddressLine1 || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
              <TextField
                fullWidth
                className={classes.input}
                variant="standard"
                label="Apartment"
                type="text"
                name="resAptNumber"
                id="resAptNumber"
                value={resAptNumber || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
              <TextField
                fullWidth
                className={classes.input}
                variant="standard"
                label="City"
                type="text"
                name="resCity"
                id="resCity"
                value={resCity || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
              <InputLabel id="resState" className={classes.selectLabel}>
                State
              </InputLabel>
              <Select
                fullWidth
                labelId="resState"
                name="resState"
                id="resState"
                value={resState || ""}
                onChange={handleChange}
                readOnly={readOnly}
              >
                {mapStatesToOptions().map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                fullWidth
                className={classes.input}
                variant="standard"
                label="Zip code"
                type="text"
                name="resPostcode"
                id="resPostcode"
                value={resPostcode || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
            </Box>
            {/* prev address */}
            <Box my={3} pt={1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {"Have they been at this address for longer than 18 months?"}
                </FormLabel>
                <RadioGroup
                  aria-label={"hasResidedAtAddressForRequiredDuration"}
                  name={"hasResidedAtAddressForRequiredDuration"}
                  value={hasResidedAtAddressForRequiredDuration}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio disabled={readOnly || !hasAnsweredResQuestions} />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio disabled={readOnly || !hasAnsweredResQuestions} />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {hasResidedAtAddressForRequiredDuration === false && (
              <Box mt={3}>
                <Box mb={3}>
                  <Typography variant="body1">
                    <b>Previous Address</b>
                  </Typography>
                </Box>
                <Box my={3}>
                  <Paper variant="outlined">
                    <Box style={{ background: "#fefefe" }} p={2}>
                      <InputLabel
                        id="prevAddressObj"
                        className={classes.selectLabel}
                      >
                        Previous Address Finder
                      </InputLabel>
                      {!readOnly && (
                        <MemoizedPreviousAddressAutoComplete
                          handleAddressAutocomplete={handleAddressAutocomplete}
                          initialValue={previousAddressObj}
                        />
                      )}
                    </Box>
                  </Paper>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Previous address line 1"
                    type="text"
                    name="previousAddressLine1"
                    id="previousAddressLine1"
                    value={previousAddressLine1 || ""}
                    onChange={handleChange}
                    margin="dense"
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Apartment"
                    type="text"
                    name="previousAptNumber"
                    id="previousAptNumber"
                    value={previousAptNumber || ""}
                    onChange={handleChange}
                    margin="dense"
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="City"
                    type="text"
                    name="previousCity"
                    id="previousCity"
                    value={previousCity || ""}
                    onChange={handleChange}
                    margin="dense"
                  ></TextField>
                  <InputLabel
                    id="previousState"
                    className={classes.selectLabel}
                  >
                    State
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="previousState"
                    name="previousState"
                    id="previousState"
                    value={previousState || ""}
                    onChange={handleChange}
                  >
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Zip code"
                    type="text"
                    name="previousPostcode"
                    id="previousPostcode"
                    value={previousPostcode || ""}
                    onChange={handleChange}
                    margin="dense"
                  ></TextField>
                </Box>
              </Box>
            )}
            <Box my={3} pt={1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {"Is the mailing address the same as residential address"}
                </FormLabel>
                <RadioGroup
                  aria-label={"resAddressEqualsMailing"}
                  name={"resAddressEqualsMailing"}
                  value={resAddressEqualsMailing}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio disabled={readOnly || !hasAnsweredResQuestions} />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio disabled={readOnly || !hasAnsweredResQuestions} />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {resAddressEqualsMailing === false && (
              <>
                <Box mb={3}>
                  <Typography variant="body1">
                    <b>Mailing Address</b>
                  </Typography>
                </Box>
                <Box my={3}>
                  <Paper variant="outlined">
                    <Box style={{ background: "#fefefe" }} p={2}>
                      <InputLabel
                        id="homeAddressObj"
                        className={classes.selectLabel}
                      >
                        Mailing Address Finder
                      </InputLabel>
                      {!readOnly && (
                        <MemoizedMailingAddressAutoComplete
                          handleAddressAutocomplete={handleAddressAutocomplete}
                          initialValue={mailingAddressObj}
                        />
                      )}
                    </Box>
                  </Paper>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Mailing address line 1"
                    type="text"
                    name="mailingAddressLine1"
                    id="mailingAddressLine1"
                    value={mailingAddressLine1 || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Apartment"
                    type="text"
                    name="mailingAptNumber"
                    id="mailingAptNumber"
                    value={mailingAptNumber || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="City"
                    type="text"
                    name="mailingCity"
                    id="mailingCity"
                    value={mailingCity || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <InputLabel id="mailingState" className={classes.selectLabel}>
                    State
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="mailingState"
                    name="mailingState"
                    id="mailingState"
                    value={mailingState || ""}
                    onChange={handleChange}
                    readOnly={readOnly}
                  >
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Zip code"
                    type="text"
                    name="mailingPostcode"
                    id="mailingPostcode"
                    value={mailingPostcode || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                </Box>
              </>
            )}
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

ContactInfo.defaultProps = {
  firstName: "",
  lastName: "",
  middleName: "",
  email: "",
  phoneNumber: "",
  agreesToTextNotifications: null,
  usaCitizen: null,
  countryCitizenship: "",
  usaPermanentResident: true,
  hasUSAddress: null,
  foreignAddressLine1: "",
  foreignAddressApt: "",
  foreignAddressCity: "",
  foreignAddressState: "",
  foreignAddressPostcode: "",
  foreignAddressCountry: "",
  resCity: "",
  resState: "",
  resAddressLine1: "",
  resPostcode: "",
  resAptNumber: "",
  resAddressEqualsMailing: null,
  homeAddressObj: null,
  mailingCity: "",
  mailingState: "",
  mailingAddressLine1: "",
  mailingAptNumber: "",
  mailingPostcode: "",
  previousAddressLine1: "",
  previousAptNumber: "",
  previousCity: "",
  previousState: "",
  previousPostcode: "",
  hasResidedAtAddressForRequiredDuration: null,
  readOnly: false,
};

ContactInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  agreesToTextNotifications: PropTypes.bool,
  usaCitizen: PropTypes.bool,
  countryCitizenship: PropTypes.string,
  usaPermanentResident: PropTypes.bool,
  hasUSAddress: PropTypes.bool,
  foreignAddressLine1: PropTypes.string,
  foreignAddressApt: PropTypes.string,
  foreignAddressCity: PropTypes.string,
  foreignAddressState: PropTypes.string,
  foreignAddressPostcode: PropTypes.string,
  foreignAddressCountry: PropTypes.string,
  resCity: PropTypes.string,
  resState: PropTypes.string,
  resAddressLine1: PropTypes.string,
  resPostcode: PropTypes.string,
  resAptNumber: PropTypes.string,
  homeAddressObj: PropTypes.object,
  resAddressEqualsMailing: PropTypes.bool,
  mailingCity: PropTypes.string,
  mailingState: PropTypes.string,
  mailingAddressLine1: PropTypes.string,
  mailingAptNumber: PropTypes.string,
  mailingPostcode: PropTypes.string,
  previousAddressObj: PropTypes.object,
  previousAddressLine1: PropTypes.string,
  previousAptNumber: PropTypes.string,
  previousCity: PropTypes.string,
  previousState: PropTypes.string,
  previousPostcode: PropTypes.string,
  hasResidedAtAddressForRequiredDuration: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const inputs = [
  "firstName",
  "lastName",
  "middleName",
  "email",
  "phoneNumber",
  "agreesToTextNotifications",
  "usaCitizen",
  "countryCitizenship",
  "usaPermanentResident",
  "hasUSAddress",
  "foreignAddressLine1",
  "foreignAddressApt",
  "foreignAddressCity",
  "foreignAddressState",
  "foreignAddressPostcode",
  "foreignAddressCountry",
  "resCity",
  "resState",
  "resAddressLine1",
  "resPostcode",
  "resAptNumber",
  "resAddressEqualsMailing",
  "homeAddressObj",
  "mailingCity",
  "mailingState",
  "mailingAddressLine1",
  "mailingAptNumber",
  "mailingPostcode",
  "previousAddressLine1",
  "previousAptNumber",
  "previousCity",
  "previousState",
  "previousPostcode",
  "previousAddressObj",
  "hasResidedAtAddressForRequiredDuration",
  "readOnly",
];

export default memo(ContactInfo, (prev, next) => equality(prev, next, inputs));
