import { usersCollection } from "../requests";
import * as Sentry from '@sentry/react'

export async function getAdmins(authority) {
  let admins = [];
  if (!authority) {
    return admins;
  }
  try {
    const query = usersCollection.where("provider", "==", authority);
    const snap = await query.get();
    if (snap.empty) return admins;
    snap.forEach((doc) => {
      const { firstName, lastName, email } = doc.data();
      const payload = {
        firstName,
        lastName,
        email,
        adminId: doc.id,
      };
      admins.push(payload);
    });
    return admins;
  } catch (error) {
    console.error("get admins err", error);
    // rollbar.error(error, "getAdmins");
    Sentry.captureException(error, { extra: { msg: "getAdmins" } })
    return [];
  }
}
