import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Container, Typography, Box, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DataGrid } from "@material-ui/data-grid";

import DashboardNoData from "components/DashboardNoData";
import SearchBar from "components/Search";
import { UserContext } from "components/User";

import { getApplicationsSearch } from "util/requests/dash";
import { columns, transform } from "util/dashboard";
import * as Sentry from '@sentry/react'

const Search = () => {
  const [results, setResults] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { search } = useLocation();
  const history = useHistory();
  const { authorityDomain = "", allAdmins = [] } = useContext(UserContext);

  useEffect(() => {
    function sanitizeSearchValue(field, value) {
      let sanitizedValue = value;
      if (field === "phoneNumber") {
        sanitizedValue = sanitizedValue.replace(/\D/g, "");
      }
      return sanitizedValue;
    }

    async function getSearchResults() {
      try {
        setResults([]);
        setLoading(true);
        const query = new URLSearchParams(search);
        let value = query.get("value");
        const field = query.get("field");
        value = sanitizeSearchValue(field, value);
        let authority;
        if (authorityDomain !== "solera") authority = authorityDomain;
        console.log("auth domain, ok to be undefined", authority);
        const results = await getApplicationsSearch(field, value, authority);
        const supporting = { allAdmins };
        const formatted = transform(results, supporting);
        setResults(formatted);
        setLoading(false);
      } catch (error) {
        // rollbar.error("get search results", error);
        Sentry.captureException(error, { extra: { msg: 'get search results' } })
        setLoading(false);
      }
    }
    if (!search) return;
    getSearchResults();
  }, [search, authorityDomain, allAdmins]);

  const goToApplication = ({ data }) => {
    const id = data.id.split("-")[0];
    return history.push({
      pathname: `/applications/${id}`,
    });
  };

  return (
    <main>
      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={"48px"}
          mb={2}
          flex={1}
        >
          <Box>
            <IconButton
              onClick={() => {
                history.push("/");
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <SearchBar />
        </Box>
        <Box mb={2}>
          <Typography variant="button">Search Results</Typography>
        </Box>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={results || []}
            columns={columns}
            loading={isLoading}
            components={{
              noRowsOverlay: DashboardNoData,
            }}
            error={null}
            onRowClick={goToApplication}
          />
        </div>
      </Container>
    </main>
  );
};

export default Search;
