import React, { memo, useState, useEffect, useContext } from "react";
import { Box, withStyles, Slider as MUISlider } from "@material-ui/core";
import { ApplicationsDataContext } from "components/ApplicationsData";

import { isEqual } from "lodash";

const Slider = withStyles({
  thumb: {
    height: 18,
    width: 18,
    marginTop: -7,
    marginLeft: -7,
    zIndex: 11,
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 10,
    width: 10,
    marginTop: -4,
    borderRadius: "50%",
    zIndex: 10,
  },
  markActive: {
    opacity: 1,
  },
  markLabel: {
    marginTop: "3px",
    textTransform: "uppercase",
    letterSpacing: 0.6,
    fontSize: "12px",
  },
})(MUISlider);

const StatusFilters = ({ filters, values, updateFilter, authorityDomain }) => {
  const [pos, setPos] = useState(0);
  const [marksArr, setMarksArr] = useState([]);
  const { countsByStatus } = useContext(ApplicationsDataContext);

  useEffect(() => {
    async function init() {
      const arr = [];
      const optionsArr =
        values && filters.filter((filter) => filter.id === "status")[0].options;
      optionsArr &&
        optionsArr.forEach((option, i) => {
          const count = optionsArr.length;
          arr.push({
            value: i * (100 / (count - 1)),
            label: `${option.name} (${countsByStatus[i].count})`,
            fieldValue: option.field,
          });
        });
      setMarksArr(arr);
      const initStatus = values.status;
      const initObj = arr.find(
        (item) => item.label.split("(")[0].trim() === initStatus
      );
      if (initObj) {
        const initPos = initObj.value;
        if (initPos !== pos) {
          setPos(initPos);
        }
      }
    }
    if (marksArr.length === 0 && !!values && !!countsByStatus) {
      init();
    }
  }, [values, countsByStatus]); //eslint-disable-line react-hooks/exhaustive-deps

  function handleSlider(name, _, val) {
    if (pos === val) return;
    const markObj = marksArr.find((mar) => mar.value === val);
    const value = markObj.fieldValue;
    const payload = {
      target: {
        name,
        value,
      },
    };
    setPos(val);
    updateFilter(payload);
  }

  return (
    <Box mb={3}>
      <Box display="flex" width="80%" px={2}>
        <Slider
          defaultValue={0}
          getAriaValueText={(val) => val}
          aria-labelledby="discrete-slider-restrict"
          step={null}
          valueLabelDisplay="off"
          marks={marksArr}
          onChange={handleSlider.bind(null, "status")}
          name={"status"}
          value={pos}
          component="div"
        />
      </Box>
    </Box>
  );
};

function comp(prev, next) {
  if (isEqual(prev.values, next.values)) return true;
}

export default memo(StatusFilters, comp);
