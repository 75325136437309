import React from "react";

import ApplicationFormWrapper from "components/ApplicationFormWrapper";

const Application = () => {
  return (
    <main>
      <div>
        <ApplicationFormWrapper />
      </div>
    </main>
  );
};

export default Application;
