import React, { useContext, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Box,
  Fab,
  Typography,
  makeStyles,
  Snackbar,
  Popover,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import DoneIcon from "@material-ui/icons/Done";
import HelpIcon from "@material-ui/icons/Help";
import AddIcon from "@material-ui/icons/Add";
import MessageIcon from "@material-ui/icons/Message";
import { isEmpty } from "lodash";

import { UserContext } from "components/User";
import { ApplicationsDataContext } from "components/ApplicationsData";
import FloatingButton from "components/FloatingButton";

import ApplicationDrawer from "./Sections/ApplicationDrawer";
import ApplicationHead from "./Sections/ApplicationHead";
import ContactInfo from "./Sections/ContactInfo";
import PersonalInfo from "./Sections/PersonalInfo";
import AdditionalParticipantBasic from "./Sections/AdditionalParticipantBasic";
import FinancialInstrument from "./Sections/FinancialInstrument";
import Trust from "./Sections/Trust";
import LLC from "./Sections/LLC";
import Beneficiary from "./Sections/Beneficiary";
import IdentitySecurity from "./Sections/IdentitySecurity";
import Documents from "./Sections/Documents";
import AdditionalParticipant from "./Sections/AdditionalParticipant";
import SmsContact from "../SmsContact";

import { dataBlock } from "util/helpers";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  notesContainer: {
    padding: "1rem",
  },
  drawer: {
    flex: 0.4,
    color: theme.palette.text.secondary,
    "& p": {
      fontSize: "0.8rem",
    },
  },
  form: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginRight: "1.5rem",
    paddingBottom: "100px",
  },
  section: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  input: {
    margin: "15px 0",
  },
  selectLabel: {
    marginTop: "30px",
  },
  cta: {
    marginTop: "20px",
  },
  footer: {
    position: "fixed",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "30px",
    paddingTop: "10px",
    paddingBottom: "10px",
    bottom: 0,
    left: 0,
    background: "rgba(195, 226, 239, 0.3)",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  readOnlyLayer: {
    position: "fixed",
    background: "rgba(193, 225, 238, 0.3)",
    height: "100%",
    top: "64px",
    left: 0,
    width: "75px",
  },
  readOnlyContainer: {
    marginTop: "50vh",
    transform: "rotate(-90deg)",
    transformOrigin: "0 0",
    textTransform: "uppercase",
    letterSpacing: "0.08rem",
    width: "100vh",
    marginLeft: "1.5rem",
    display: "flex",
    alignItems: "center",
  },
  popover: {
    padding: "1rem",
  },
  inputRow: {
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
    marginBottom: 35,
  },
  inputCol1: {
    flex: "0 0 100%",
    marginBottom: 15,
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 60%",
      marginBottom: 0,
    },
  },
  inputCol2: {
    flex: "0 0 100%",
    marginBottom: 15,
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 30%",
      marginBottom: 0,
    },
  },
  alertAction: {
    alignItems: "flex-start",
  },
  listItemIcon: {
    minWidth: 25,
  },
}));

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
      style={{ minWidth: "300px" }}
    />
  );
}

function displayWarning(props) {
  return dataBlock(props);
}

const ApplicationForm = (props) => {
  const [displaySavedSuccess, setDisplaySavedSuccess] = useState(false);
  const [showSmsContact, setShowSmsContact] = useState(false);
  const {
    handleSave,
    dirtyKeys,
    dirtyFiles,
    error,
    isLoadingSave,
    isUploadingFiles,
    isNewApplication,
    errorsSubmit,
    handleCloseSubmitErrorAlert,
  } = props;
  const classes = useStyles();

  const prevIsLoading = useRef(false);

  useEffect(() => {
    let timeout;
    function displaySuccess() {
      setDisplaySavedSuccess(true);
      return setTimeout(() => {
        setDisplaySavedSuccess(false);
      }, 2000);
    }
    if (window.location.pathname.includes("new")) return;
    if (
      prevIsLoading.current === true &&
      isLoadingSave === false &&
      error === null
    ) {
      timeout = displaySuccess();
    }
    prevIsLoading.current = isLoadingSave;
    return function cleanup() {
      timeout && clearTimeout(timeout);
    };
  }, [isLoadingSave, error]);

  const sections = [
    { name: "contactInfo", component: ContactInfo },
    { name: "personalInfo", component: PersonalInfo },
    {
      name: "additionalParticipantBasic",
      component: AdditionalParticipantBasic,
    },
    { name: "financialInstrument", component: FinancialInstrument },
    { name: "trust", component: Trust },
    { name: "llc", component: LLC },
    { name: "beneficiary", component: Beneficiary },
    { name: "identitySecurity", component: IdentitySecurity },
    { name: "documents", component: Documents },
    { name: "additionalParticipant", component: AdditionalParticipant },
  ];
  const { allAdmins = [] } = useContext(UserContext);
  const { referrers = [] } = useContext(ApplicationsDataContext);
  const warning = displayWarning(props);

  return (
    <div className={classes.container}>
      <form className={classes.form} autoComplete="on">
        <ApplicationHead {...props} classes={classes} allAdmins={allAdmins} />
        {sections.map(({ name, component: Section }) => (
          <Section
            key={name}
            section={name}
            {...props}
            classes={classes}
            allAdmins={allAdmins}
          />
        ))}
        <Box className={classes.footer}>
          <Box display="flex" alignItems="center" ml={10}>
            {!!error && (
              <Box maxWidth={800}>
                <Typography variant="body2" color="error" noWrap>
                  There was an error saving the data: {error}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex">
            <Box position="relative">
              <Button
                type="submit"
                onClick={handleSave}
                variant="contained"
                color="secondary"
                disabled={
                  (isEmpty(dirtyKeys) && isEmpty(dirtyFiles)) ||
                  isLoadingSave ||
                  !props.validatesMinReqs
                }
              >
                {props.id ? "Update and save" : "Create"}
              </Button>
              {isLoadingSave && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  className={classes.buttonProgress}
                />
              )}
            </Box>

            {isNewApplication ? null : (
              <Box ml={4} position="relative">
                {props.submittedAt ? (
                  <Button
                    type="button"
                    onClick={() => {}}
                    variant="contained"
                    style={{ background: "green", color: "white" }}
                    disabled={true}
                    endIcon={<DoneIcon />}
                  >
                    Submitted
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    onClick={(e) => handleSave(e, "shouldSubmit")}
                    variant="contained"
                    color="primary"
                    disabled={
                      (isEmpty(dirtyKeys) && isEmpty(dirtyFiles)) ||
                      isLoadingSave ||
                      !props.validatesMinReqs
                    }
                  >
                    Submit
                  </Button>
                )}
                {isLoadingSave && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </form>
      {props.readOnly && <ReadOnly classes={classes} />}
      {props.authorityDomain === "solera" &&
        props.status === "READY FOR BATCH" &&
        props.canDownload && (
          <FloatingButton
            clickHandler={props.handleDownload}
            disabled={props.isLoadingDownload}
            loading={props.isLoadingDownload}
          />
        )}
      <ApplicationDrawer
        classes={classes}
        {...props}
        allAdmins={allAdmins}
        referrers={referrers}
        validationErrors={props.validationErrors}
      />
      <Snackbar
        style={{ marginTop: "80px" }}
        open={!!warning}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="warning">{warning}</Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "80px" }}
        open={!!props.errorDownload}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="warning">
          There was an issue downloading this application
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "80px" }}
        open={!!displaySavedSuccess}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Application successfully saved</Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "80px" }}
        open={!!isUploadingFiles}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info" color="warning">
          Uploading files...
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "80px" }}
        open={!isEmpty(errorsSubmit)}
        onClose={handleCloseSubmitErrorAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          onClose={handleCloseSubmitErrorAlert}
          classes={{ action: classes.alertAction }}
        >
          <AlertTitle>Submission Error</AlertTitle>
          The following fields are missing and are required for submission:{" "}
          <List dense>
            {errorsSubmit.map((err) => {
              return (
                <ListItem disableGutters key={err}>
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <AddIcon fontSize="small" style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary={err} />
                </ListItem>
              );
            })}
          </List>
        </Alert>
      </Snackbar>
      {props.id && props.authorityDomain === "solera" && (
        <Box position="fixed" right="10px" top="150px">
          <Fab
            color="primary"
            aria-label="open dialog for sms form"
            onClick={() => setShowSmsContact((curr) => !curr)}
          >
            <MessageIcon />
          </Fab>
        </Box>
      )}
      <SmsContact
        open={showSmsContact}
        primaryPhone={props.phoneNumber}
        primaryName={`${props.firstName} ${props.lastName}`}
        additionalName={`${props.additionalParticipantFirstName} ${props.additionalParticipantLastName}`}
        additionalPhone={props.additionalParticipantPhone}
        handleClose={() => setShowSmsContact((curr) => !curr)}
        saveNote={props.saveNote}
        primaryAgreesToSms={props.agreesToTextNotifications}
        additionalAgreesToSms={
          props.additionalParticipantAgreesToTextNotifications
        }
      />
    </div>
  );
};

function ReadOnly({ classes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box className={classes.readOnlyLayer}>
      <Box className={classes.readOnlyContainer}>
        <Typography
          variant="body2"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
        >
          read-only
          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography className={classes.popover}>
              Change status to "DRAFT" or "REQUIRES REVIEW" to edit. You can
              still upload files in "READ-ONLY".
            </Typography>
          </Popover>
        </Typography>
        <Box ml={1} display="flex">
          <HelpIcon
            style={{ fontSize: 18 }}
            onClick={handlePopoverOpen}
          ></HelpIcon>
        </Box>
      </Box>
    </Box>
  );
}

ApplicationForm.defaultProps = {
  dirtyKeys: [],
};

ApplicationForm.propTypes = {
  dirtyKeys: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
};

export default ApplicationForm;
