import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Input,
  FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Accordion from "components/Accordion";
import PhoneInput from "components/PhoneInput";
import { ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL } from "config/constants";

import { equality } from "util/helpers";

const AdditionalParticipantBasic = ({
  section,
  classes,
  accountType,
  additionalParticipantId,
  addAdditionalParticipant,
  additionalParticipantFirstName,
  additionalParticipantMiddleName,
  additionalParticipantLastName,
  additionalParticipantEmail,
  additionalParticipantPhone,
  handleChange,
  readOnly,
}) => {
  // console.log("rendering additional participant basic");

  return (
    <>
      {accountType !== ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section}-content`}
            id={`${section}-header`}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Typography variant="h6">Add Another Participant</Typography>
              {/* <Typography variant="button">Incomplete</Typography> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.section}>
              <Box my={3}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {"Add another plan participant?"}
                  </FormLabel>
                  <RadioGroup
                    aria-label={"addAdditionalParticipant"}
                    name={"addAdditionalParticipant"}
                    value={addAdditionalParticipant}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio disabled={readOnly} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio disabled={readOnly} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {addAdditionalParticipant === true && (
                <>
                  {!additionalParticipantId && (
                    <Box>
                      <Typography variant="body2" color="error">
                        To enter more detailed additional participant data than
                        the inputs you see below, please first save the
                        Application. After saving a new section for this data
                        will appear.
                      </Typography>
                    </Box>
                  )}
                  <TextField
                    className={classes.input}
                    variant="standard"
                    label="First Name"
                    type="text"
                    name="additionalParticipantFirstName"
                    id="additionalParticipantFirstNameBasic"
                    value={additionalParticipantFirstName || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <TextField
                    className={classes.input}
                    variant="standard"
                    label="Middle Name"
                    type="text"
                    name="additionalParticipantMiddleName"
                    id="additionalParticipantMiddleNameBasic"
                    value={additionalParticipantMiddleName || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <TextField
                    className={classes.input}
                    variant="standard"
                    label="Last Name"
                    type="text"
                    name="additionalParticipantLastName"
                    id="additionalParticipantLastNameBasic"
                    value={additionalParticipantLastName || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <TextField
                    className={classes.input}
                    variant="standard"
                    label="Email"
                    type="email"
                    name="additionalParticipantEmail"
                    id="additionalParticipantEmailBasic"
                    value={additionalParticipantEmail || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{
                      readOnly: Boolean(readOnly),
                    }}
                  ></TextField>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="additionalParticipantPhone">
                      Phone Number
                    </InputLabel>
                    <Input
                      value={additionalParticipantPhone || ""}
                      onChange={handleChange}
                      name="additionalParticipantPhone"
                      id="additionalParticipantPhoneBasic"
                      inputComponent={PhoneInput}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

AdditionalParticipantBasic.defaultProps = {
  addAdditionalParticipant: null,
  additionalParticipantFirstName: "",
  additionalParticipantMiddleName: "",
  additionalParticipantLastName: "",
  additionalParticipantEmail: "",
  additionalParticipantPhone: "",
  additionalParticipantId: "",
  readOnly: false,
};

AdditionalParticipantBasic.propTypes = {
  addAdditionalParticipant: PropTypes.bool,
  additionalParticipantMiddleName: PropTypes.string,
  additionalParticipantLastName: PropTypes.string,
  additionalParticipantFirstName: PropTypes.string,
  additionalParticipantEmail: PropTypes.string,
  additionalParticipantPhone: PropTypes.string,
  additionalParticipantId: PropTypes.string,
  readOnly: PropTypes.bool,
};

const inputs = [
  "accountType",
  "addAdditionalParticipant",
  "additionalParticipantFirstName",
  "additionalParticipantMiddleName",
  "additionalParticipantLastName",
  "additionalParticipantEmail",
  "additionalParticipantPhone",
  "additionalParticipantId",
  "readOnly",
];

export default memo(AdditionalParticipantBasic, (prev, next) =>
  equality(prev, next, inputs)
);
