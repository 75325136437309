import React, { memo, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Select,
  MenuItem,
  InputLabel,
  Box,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DatePicker from "components/DatePicker";
import Accordion from "components/Accordion";
import SSNInput from "components/SSNInput";

import {
  equality,
  mapInvestmentsToOptions,
  mapCountriesToOptions,
} from "util/helpers";
import { ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL } from "config/constants";
import { isEmpty } from "lodash";

const FinancialInstrument = ({
  section,
  accountType,
  iraType,
  checkingAccountTaxTypes,
  checkingAccountNicknameRoth,
  checkingAccountNicknameTraditional,
  investmentSector1,
  investmentSector2,
  willInvestInternationally,
  countriesForInternationalTrans,
  createTraditionalCheckingForTaxDeferredFunds,
  createRothCheckingForPostTaxFunds,
  inheritedIraHolderFirstName,
  inheritedIraHolderMiddleName,
  inheritedIraHolderLastName,
  inheritedIraHolderDob,
  inheritedIraHolderDateOfDeath,
  inheritedIraHolderSocialSecurityNumber,
  handleChange,
  updateField,
  classes,
  readOnly,
  isLoadingSSNs,
}) => {
  // console.log("rendering financial instrument");

  useEffect(() => {
    function init() {
      // other apps may not have same logic for checking account types
      if (isEmpty(checkingAccountTaxTypes)) {
        let payload = [];
        if (createRothCheckingForPostTaxFunds === true) {
          payload.push("post-tax");
        }
        if (createTraditionalCheckingForTaxDeferredFunds === true) {
          payload.push("tax-defer");
        }
        if (!isEmpty(payload)) {
          updateField("checkingAccountTaxTypes", payload);
        }
      }
    }
    init();
    /* eslint-disable-next-line */
  }, []);

  const inheritedSsn = useMemo(
    () => (
      <SSNInput
        label="Social Security Number"
        name="inheritedIraHolderSocialSecurityNumber"
        value={inheritedIraHolderSocialSecurityNumber}
        handleChange={handleChange}
        readOnly={readOnly || isLoadingSSNs}
        isLoading={isLoadingSSNs}
      />
    ),
    /* eslint-disable-next-line */
    [inheritedIraHolderSocialSecurityNumber, readOnly, isLoadingSSNs]
  );

  function handleCheckingAccountTypeCheckbox(e, value) {
    if (!checkingAccountTaxTypes) return;
    const name = e.target.name;
    const newValue = checkingAccountTaxTypes.includes(value)
      ? checkingAccountTaxTypes.filter((option) => option !== value)
      : [...checkingAccountTaxTypes, value];
    return updateField(name, newValue);
  }

  const shouldDisplayBeneficiary =
    iraType === "Inherited-Traditional" || iraType === "Inherited-Roth";

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${section}-content`}
        id={`${section}-header`}
      >
        <Typography variant="h6">Account and Investment Options</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.section}>
          {/* account options */}
          <Box>
            <Box mb={3}>
              <FormLabel component="legend">{"Account Options"}</FormLabel>
            </Box>
            {!!accountType ? (
              accountType === ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL ? (
                <Box>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{"IRA type"}</FormLabel>
                    <RadioGroup
                      aria-label={"iraType"}
                      name={"iraType"}
                      value={iraType}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value={"Traditional"}
                        control={<Radio disabled={readOnly} />}
                        label="Traditional"
                      />
                      <FormControlLabel
                        value={"Roth"}
                        control={<Radio disabled={readOnly} />}
                        label="Roth"
                      />
                      <FormControlLabel
                        value={"Inherited-Traditional"}
                        control={<Radio disabled={readOnly} />}
                        label="Beneficiary/Inherited Traditional"
                      />
                      <FormControlLabel
                        value={"Inherited-Roth"}
                        control={<Radio disabled={readOnly} />}
                        label="Beneficiary/Inherited Roth"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : (
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Choose checking account tax option
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkingAccountTaxTypes.includes(
                            "tax-defer"
                          )}
                          onChange={(e) =>
                            handleCheckingAccountTypeCheckbox(e, "tax-defer")
                          }
                          name="checkingAccountTaxTypes"
                          disabled={readOnly}
                        />
                      }
                      label="Checking account for tax deferred funds (Traditional)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkingAccountTaxTypes.includes("post-tax")}
                          onChange={(e) =>
                            handleCheckingAccountTypeCheckbox(e, "post-tax")
                          }
                          name="checkingAccountTaxTypes"
                          disabled={readOnly}
                        />
                      }
                      label="Checking account for Roth funds (Post-tax)"
                    />
                  </FormGroup>
                </FormControl>
              )
            ) : null}
          </Box>
          {/* account nickname */}
          {checkingAccountTaxTypes.map((type) => {
            const name =
              type === "tax-defer"
                ? "checkingAccountNicknameTraditional"
                : "checkingAccountNicknameRoth";
            const label =
              type === "tax-defer"
                ? "Account nickname (Traditional)"
                : "Account nickname (Post-tax)";
            const value =
              type === "tax-defer"
                ? checkingAccountNicknameTraditional
                : checkingAccountNicknameRoth;
            return (
              <TextField
                key={type}
                className={classes.input}
                variant="standard"
                label={label}
                type="text"
                name={name}
                id={name}
                value={value || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
            );
          })}
          <Box>
            <InputLabel id="investmentSector1" className={classes.selectLabel}>
              Investment Sector 1
            </InputLabel>
            <Select
              fullWidth
              labelId="investmentSector1"
              name="investmentSector1"
              id="investmentSector1"
              value={investmentSector1 || ""}
              onChange={handleChange}
              readOnly={readOnly}
            >
              {mapInvestmentsToOptions()
                .filter((opt) => opt.value !== investmentSector2)

                .map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
            </Select>
            <InputLabel id="investmentSector2" className={classes.selectLabel}>
              Investment Sector 2 (optional)
            </InputLabel>
            <Select
              fullWidth
              labelId="investmentSector2"
              name="investmentSector2"
              id="investmentSector2"
              value={investmentSector2 || ""}
              onChange={handleChange}
              readOnly={readOnly}
            >
              {mapInvestmentsToOptions()
                .filter((opt) => opt.value !== investmentSector1)

                .map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
            </Select>

            <Box my={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {"Will invest internationally"}
                </FormLabel>
                <RadioGroup
                  aria-label={"willInvestInternationally"}
                  name={"willInvestInternationally"}
                  value={willInvestInternationally}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio disabled={readOnly} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio disabled={readOnly} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              {willInvestInternationally === true && (
                <>
                  <InputLabel
                    id="countriesForInternationalTrans"
                    className={classes.selectLabel}
                  >
                    Countries planning on investing
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="countriesForInternationalTrans"
                    name="countriesForInternationalTrans"
                    id="countriesForInternationalTrans"
                    value={countriesForInternationalTrans || ""}
                    onChange={handleChange}
                    multiple
                    readOnly={readOnly}
                  >
                    {mapCountriesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Box>
          </Box>
          {shouldDisplayBeneficiary && (
            <Box>
              <TextField
                className={classes.input}
                fullWidth
                variant="standard"
                label={"Inherited Ira Holder First Name"}
                type="text"
                name={"inheritedIraHolderFirstName"}
                id={"inheritedIraHolderFirstName"}
                value={inheritedIraHolderFirstName || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
              <TextField
                fullWidth
                className={classes.input}
                variant="standard"
                label={"Inherited Ira Holder Middle Name"}
                type="text"
                name={"inheritedIraHolderMiddleName"}
                id={"inheritedIraHolderMiddleName"}
                value={inheritedIraHolderMiddleName || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
              <TextField
                className={classes.input}
                variant="standard"
                fullWidth
                label={"Inherited Ira Holder Last Name"}
                type="text"
                name={"inheritedIraHolderLastName"}
                id={"inheritedIraHolderLastName"}
                value={inheritedIraHolderLastName || ""}
                onChange={handleChange}
                margin="dense"
                inputProps={{
                  readOnly: Boolean(readOnly),
                }}
              ></TextField>
              <Box mt={3} display="flex" flexDirection="column">
                <FormLabel component="legend">{"Date of birth"}</FormLabel>
                <DatePicker
                  id={`datepicker-inheritedIraHolderDob`}
                  initialDate={inheritedIraHolderDob}
                  dateHandler={updateField.bind(null, "inheritedIraHolderDob")}
                  disabled={readOnly}
                />
              </Box>
              <Box mt={3} display="flex" flexDirection="column">
                <FormLabel component="legend">{"Date of death"}</FormLabel>
                <DatePicker
                  id={`datepicker-inheritedIraHolderDod`}
                  initialDate={inheritedIraHolderDateOfDeath}
                  dateHandler={updateField.bind(
                    null,
                    "inheritedIraHolderDateOfDeath"
                  )}
                  disabled={readOnly}
                />
              </Box>
              <Box mt={2}>{inheritedSsn}</Box>
            </Box>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

FinancialInstrument.defaultProps = {
  accountType: "",
  iraType: "",
  checkingAccountTaxTypes: [],
  checkingAccountNicknameRoth: "",
  checkingAccountNicknameTraditional: "",
  investmentSector1: "",
  investmentSector2: "",
  willInvestInternationally: null,
  countriesForInternationalTrans: null,
  inheritedIraHolderFirstName: "",
  inheritedIraHolderMiddleName: "",
  inheritedIraHolderLastName: "",
  inheritedIraHolderDob: new Date(),
  inheritedIraHolderDateOfDeath: new Date(),
  inheritedIraHolderSocialSecurityNumber: "",
  readOnly: false,
};

FinancialInstrument.propTypes = {
  accountType: PropTypes.string,
  iraType: PropTypes.string,
  checkingAccountTaxTypes: PropTypes.arrayOf(PropTypes.string),
  checkingAccountNicknameRoth: PropTypes.string,
  checkingAccountNicknameTraditional: PropTypes.string,
  investmentSector1: PropTypes.string,
  investmentSector2: PropTypes.string,
  willInvestInternationally: PropTypes.bool,
  countriesForInternationalTrans: PropTypes.arrayOf(PropTypes.string),
  inheritedIraHolderFirstName: PropTypes.string,
  inheritedIraHolderMiddleName: PropTypes.string,
  inheritedIraHolderLastName: PropTypes.string,
  inheritedIraHolderDob: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  inheritedIraHolderDateOfDeath: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  inheritedIraHolderSocialSecurityNumber: PropTypes.string,
  readOnly: PropTypes.bool,
  isLoadingSSNs: PropTypes.bool,
};

const fields = [
  "accountType",
  "iraType",
  "checkingAccountTaxTypes",
  "checkingAccountNicknameRoth",
  "checkingAccountNicknameTraditional",
  "investmentSector1",
  "investmentSector2",
  "willInvestInternationally",
  "countriesForInternationalTrans",
  "inheritedIraHolderFirstName",
  "inheritedIraHolderMiddleName",
  "inheritedIraHolderLastName",
  "inheritedIraHolderDob",
  "inheritedIraHolderDateOfDeath",
  "inheritedIraHolderSocialSecurityNumber",
  "readOnly",
  "isLoadingSSNs",
];

export default memo(FinancialInstrument, (prev, next) =>
  equality(prev, next, fields)
);
