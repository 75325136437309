import React, { useState, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Link as MuiLink,
  TextField,
  Button,
} from "@material-ui/core";

import { UserContext } from "components/User";

const successMessage =
  "Your request has been received. If your email address is in our system, you will receive an email with instructions to reset your password.";
const errorMessage =
  "Sorry something went wrong. Please contact an administrator.";

const PasswordReset = () => {
  const user = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setSuccess("");
      setError("");
      setLoading(true);
      await user.sendPasswordResetEmail(email);
      setSuccess(successMessage);
    } catch (e) {
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  return (
    <main>
      {user.isLoadingAuthListener === true ? (
        <Redirect to="/login" />
      ) : (
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit} autoComplete="on">
            <Box display="flex" flexDirection="column">
              <Typography gutterBottom={true} variant="h4">
                Password Reset
              </Typography>
              <Box my={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="email"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleEmail}
                  autoComplete="email"
                  margin="dense"
                ></TextField>
              </Box>
              <Box mt={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={!email || loading}
                >
                  Submit
                </Button>
              </Box>
              <Box py={2}>
                <Typography variant="body2" style={{ color: "green" }}>
                  {success}
                </Typography>
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              </Box>
            </Box>
          </form>
          <Box py={8}>
            <Typography variant="body2">
              <MuiLink to="/login" component={Link}>
                Back to login
              </MuiLink>
            </Typography>
          </Box>
        </Container>
      )}
    </main>
  );
};

export default PasswordReset;
