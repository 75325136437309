import firebase, { FIREBASE_URLS } from "config/firebase";
import bcrypt from "bcryptjs";
import { firebase as firebaseAdmin } from "@firebase/app";
import { last } from "lodash";

import * as Sentry from "@sentry/react";

export const applicationsCollection = firebase
  .firestore()
  .collection("applications");
export const participantsCollection = firebase
  .firestore()
  .collection("participants");
export const usersCollection = firebase.firestore().collection("users");
export const fairMarketCollection = firebase
  .firestore()
  .collection("fairMarketAccounts");

export const totalCountsCollection = firebase.firestore().collection("count");
//////////////////////////////////////////
const storageRef = firebase.storage().ref();

export function addTimestamps(data) {
  data.createdAt = firebaseAdmin.firestore.FieldValue.serverTimestamp();
  data.updatedAt = firebaseAdmin.firestore.FieldValue.serverTimestamp();
}

export async function uploadFile(path, file) {
  const fileName = last(path.split("/"));
  const errMsg = `Error uploading file ${fileName}.`;
  console.log("uploading file");
  if (!path || !file) throw Error(errMsg);
  const isBlob = file instanceof Blob;
  if (!isBlob) throw Error(errMsg);
  try {
    // take rel path to create full ref , eg "/applications/[:id]/trust/iraTrust.pdf"
    const ref = storageRef.child(path);
    return ref
      .put(file)
      .then((snap) => {
        return snap.ref.fullPath;
      })
      .catch((error) => {
        // rollbar.error(error, `catch block upload file ${path}`);
        Sentry.captureException(error, {
          extra: { msg: `catch block upload file ${path}` },
        });
        console.error(error);
      });
  } catch (error) {
    // rollbar.error(error, "upload file");
    Sentry.captureException(error, { extra: { msg: "upload file" } });
    console.error(error);
    throw error;
  }
}

export async function getImageUrl(path, altExtension) {
  try {
    const ref = storageRef.child(path);
    const url = await ref.getDownloadURL();
    return url;
  } catch (error) {
    if (altExtension) {
      path = path.split(".")[0] + altExtension;
      const url = await getImageUrl(path);
      return url;
    }
    return "";
  }
}

export async function downloadFile(fullPath, retries = 1) {
  try {
    const ref = storageRef.child(fullPath);
    const fileName = ref.name || "file.pdf";
    ref
      .getDownloadURL()
      .then((url) => {
        forceDownload(url, fileName);
      })
      .catch((error) => {
        if (retries > 0 && fullPath.includes("plan_adoption")) {
          const altPath = fullPath.replace("plan_adoption.pdf", "trust.pdf");
          return downloadFile(altPath, 0);
        } else {
          // rollbar.error(error, "downloadFile catch");
          Sentry.captureException(error, {
            extra: { msg: "downloadFile catch" },
          });
          console.error(error);
        }
      });
  } catch (error) {
    console.log(error, "handling error");
    // rollbar.error(error, "downloadFile");
    Sentry.captureException(error, { extra: { msg: "handling error" } });
  }
}

// Current blob size limit is around 500MB for browsers
export function downloadResource(url, fileName) {
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, fileName, false);
    })
    .catch((e) => console.error(e));
}

export function forceDownload(url, fileName, newTab = true) {
  try {
    var a = document.createElement("a");
    a.download = fileName;
    if (newTab) a.target = "_blank";
    a.href = url;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    // rollbar.error(error, "forceDownload");
    Sentry.captureException(error, { extra: { msg: "forceDownload" } });
    alert(
      "Sorry there was an error downloading this file. This bug has been reported"
    );
  }
}

export async function exportCsv(asyncParser, data, opts, fileName) {
  return asyncParser(data, opts)
    .then((csv) => {
      var a = document.createElement("a");
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      a.href = url;
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((error) => {
      console.error(error);
      // rollbar.error(error, "exportCsv");
      Sentry.captureException(error, { extra: { msg: "exportCsv" } });
    });
}

export async function generateIdentityRedirect(participantId) {
  try {
    return `${window.location.origin}${FIREBASE_URLS.verifyIdentity}/indirect?participantId=${participantId}`;

    // send request
    const token = await getToken();
    const res = await request(FIREBASE_URLS.verifyIdentity, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        participantId,
      }),
    });
    if (!res) throw Error("response not returned");
    if (!res.ok) throw Error(await res.text());
    const data = await res.json();
    console.log("data", data);
    // extract link
    const { redirectUrl, transactionReference } = data || {};

    const jumioTransaction = {
      transactionReference,
    };

    // update participant document for tracking
    await participantsCollection.doc(participantId).update({
      jumioTransaction,
    });

    return redirectUrl;
  } catch (error) {
    console.error(error);
    // rollbar.error(error, "generateIdentityRedirect");
    Sentry.captureException(error, {
      extra: { msg: "generateIdentityRedirect" },
    });
  }
}

export function getToken() {
  return firebase.auth().currentUser.getIdToken();
}

export async function request(url, options, retries = 3, backoff = 300) {
  try {
    const res = await fetch(url, options);
    if (!res) throw Error("response not returned");
    if (!res.ok) {
      throw Error(await res.text());
    }
    return res;
  } catch (err) {
    console.log("retry request");
    console.error(err);
    if (retries < 1) {
      console.log("no more retries", err);
      throw err;
    }
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(request(url, options, retries - 1, backoff * 2));
      }, backoff)
    );
  }
}
