import React, { useState, useContext, useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Box,
  Container,
  Fab,
  Typography,
  Button,
  InputBase,
  InputLabel,
  Input,
  Divider,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { UserContext } from "components/User";
import DatePicker from "components/DatePicker";
import { exportCsv } from "util/requests";
import {
  getFairMarketStats,
  getAllFairMarketAccounts,
  searchFairMarketAccount,
  updateFairMarketAccount,
} from "util/requests/fairMarket";

import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  fabContainer: {
    position: "fixed",
    right: 10,
    top: 80,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
  },
  inputContainer: {
    color: "inherit",
    border: "1px solid black",
    marginRight: 10,
    position: "relative",
    height: "36px",
    width: "20ch",
    borderRadius: "4px",
    borderColor: fade("rgb(133, 133, 133)", 0.5),
    "&:focus-within": {
      borderColor: fade(theme.palette.text.primary, 1),
    },
    "&:hover": {
      borderColor: fade(theme.palette.text.primary, 1),
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  input: {
    padding: 0,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "36px",
  },
}));

function loadScript() {
  const script = document.createElement("script");
  script.setAttribute("async", "");
  const src = "https://cdn.jsdelivr.net/npm/json2csv";
  script.src = src;

  document.body.appendChild(script);
}

const FairMarket = () => {
  // Only Solera admins have access here
  const admin = useContext(UserContext);
  const loadedScript = useRef(false);
  const [stats, setStats] = useState(null);
  const [account, setAccount] = useState({});
  const [search, setSearch] = useState("");
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const shouldRedirect = admin.authorityDomain !== "solera";
  const classes = useStyles();

  useEffect(() => {
    if (typeof window !== "undefined" && !loadedScript.current) {
      loadedScript.current = true;
      loadScript();
    }
    // fetch data for fair market share
    function initStats() {
      getFairMarketStats().then(({ stats }) => {
        setStats(stats);
      });
    }
    initStats();
  }, []);

  async function download() {
    setIsLoadingDownload(true);
    // get all accounts to ensure all fresh
    const { data } = await getAllFairMarketAccounts();
    const json2csv = window.json2csv;
    if (!json2csv) return;
    const parseAsync = json2csv.parseAsync;
    const fields = [
      {
        label: "ID Reference",
        value: "docId",
      },
      {
        label: "Account number",
        value: "accountNumber",
      },
      {
        label: "Provider",
        value: "referralSource",
      },
      {
        label: "LLC/TRUST Name",
        value: "trustOrLlcName",
      },
      {
        label: "LLC/TRUST Account",
        value: "trustOrLlcNumber",
      },
      {
        label: "Customer name",
        value: "customerFullName",
      },
      {
        label: "Email address",
        value: "emailAddress",
      },
      {
        label: "Date Opened",
        value: "dateOpened",
      },
      {
        label: "Fair Market value",
        value: "marketValue",
      },
      {
        label: "Date of Valuation",
        value: (row) => {
          let val = "";
          try {
            val = row.valuationDate
              ? DateTime.fromISO(row.valuationDate).toLocaleString(
                  DateTime.DATE_SHORT
                )
              : "";
          } catch (error) {
            console.error(error);
          }
          return val === "Invalid DateTime" ? "" : val;
        },
      },
    ];
    const opts = { fields };

    const fileName = "fair_market_value_accounts.csv";

    await exportCsv(parseAsync, data, opts, fileName);
    setIsLoadingDownload(false);
  }

  async function handleSearch() {
    try {
      setIsLoadingSearch(true);
      setAccount({});
      const account = await searchFairMarketAccount(search);
      setAccount(account);
      setIsLoadingSearch(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function updateValue(data) {
    const marketValue = parseFloat(data.marketValue);
    const payload = {
      marketValue,
      valuationDate: data.valuationDate.toISOString(),
    };

    const id = account.id;
    const wasCompleted = !!account.completedAt;
    await updateFairMarketAccount(id, payload, wasCompleted);
    handleSuccessUpdate();
  }

  function handleSuccessUpdate() {
    setSuccessUpdate(true);
    setTimeout(() => setSuccessUpdate(false), 2000);
  }

  return (
    <main>
      <div id="script-container"></div>
      {shouldRedirect ? (
        <Redirect to="/" />
      ) : (
        <div>
          <Container maxWidth="md">
            <Box className={classes.contentContainer}>
              <Box pt={4} pb={2}>
                <Typography variant="h4">Fair Market Value</Typography>
              </Box>

              <Box pb={1}>
                <Typography variant="h6" color="secondary">
                  {stats ? stats.completed : "xx"}/{stats ? stats.total : "xx"}{" "}
                  forms complete
                </Typography>
              </Box>
              <Typography variant="h6" color="secondary">
                Last updated {stats && stats.lastUpdated}
              </Typography>
              <Box mt={7}>
                <Typography>Find an account</Typography>
                <Box display="flex" mt={1}>
                  <Box className={classes.inputContainer}>
                    <Box className={classes.searchIcon}>
                      <SearchIcon />
                    </Box>
                    <InputBase
                      placeholder="Account number"
                      className={classes.input}
                      value={search}
                      onChange={({ target }) => setSearch(target.value)}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Box>
                  <Box position="relative">
                    <Button
                      onClick={handleSearch}
                      variant="contained"
                      color="primary"
                      disabled={!search || isLoadingSearch}
                      className={classes.button}
                    >
                      Search
                    </Button>
                    {isLoadingSearch && (
                      <CircularProgress
                        size={24}
                        color="secondary"
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              {account === null ? (
                <Box py={3}>
                  <Typography>
                    Sorry, we couldn't find an account with that number.
                  </Typography>
                </Box>
              ) : isEmpty(account) ? null : (
                <Box mt={4}>
                  <Divider />
                  <Box pt={3}>
                    <Typography variant="h6">
                      {account.trustOrLlcName}
                    </Typography>
                    <Box py={1}>
                      {account && account.completedAt ? (
                        <Typography
                          variant="body2"
                          color="secondary"
                          style={{ textTransform: "uppercase" }}
                        >
                          This customer has submitted their fair market value
                          valuation
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          color="error"
                          style={{ textTransform: "uppercase" }}
                        >
                          <b>
                            This customer has not submitted their fair market
                            value valuation
                          </b>
                        </Typography>
                      )}
                    </Box>
                    <FairMarketForm
                      account={account}
                      handleSubmit={updateValue}
                      successUpdate={successUpdate}
                    />
                  </Box>
                </Box>
              )}

              <Box className={classes.fabContainer}>
                <Fab
                  color="secondary"
                  aria-label="add"
                  onClick={download}
                  disabled={isLoadingDownload}
                >
                  <GetAppIcon />
                </Fab>
              </Box>
            </Box>
          </Container>
        </div>
      )}
    </main>
  );
};

function FairMarketForm({ account, handleSubmit, successUpdate }) {
  const [marketValue, setMarketValue] = useState(0);
  const [valuationDate, setValuationDate] = useState(new Date());
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setMarketValue(
      account.marketValue === undefined || account.marketValue === null
        ? ""
        : account.marketValue
    );
    setValuationDate(
      account.valuationDate ? new Date(account.valuationDate) : new Date()
    );
  }, [account]);

  function submit(e) {
    e.preventDefault();
    setDisabled(true);
    const payload = {
      marketValue,
      valuationDate,
    };
    handleSubmit(payload);
  }

  function handleValuationDate(value) {
    setValuationDate(value);
    if (disabled) setDisabled(false);
  }

  function handleChange(e) {
    setMarketValue(e.target.value);
    if (disabled) setDisabled(false);
  }

  return (
    <Box>
      <form onSubmit={submit}>
        <Box display="flex" flexDirection="column" width={400}>
          <Box mt={2}>
            <Typography>
              <b>Update Account</b>
            </Typography>
          </Box>
          <Box py={2}>
            <InputLabel shrink htmlFor="marketValue">
              Market value
            </InputLabel>
            <Input
              type="number"
              name="marketValue"
              id="marketValue"
              value={marketValue}
              onChange={handleChange}
              margin="dense"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </Box>
          <Box mt={2}>
            <InputLabel component="legend">{"Valuation date"}</InputLabel>
            <DatePicker
              id={`datepicker-valuation-date`}
              initialDate={valuationDate || new Date()}
              dateHandler={handleValuationDate}
            />
          </Box>
          <Box my={3} pb={3} display="flex">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                (account.marketValue === marketValue &&
                  account.valuationDate === valuationDate) ||
                !marketValue ||
                !valuationDate ||
                disabled === true
              }
            >
              Update value
            </Button>
            {successUpdate && (
              <Box ml={2} display="flex">
                <CheckCircleOutlineIcon fontSize="large" color="secondary" />
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default FairMarket;
