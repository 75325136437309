import firebase from "config/firebase";
import * as Sentry from '@sentry/react'

async function getRefs() {
  try {
    const doc = await firebase
      .firestore()
      .collection("settings")
      .doc("referrers")
      .collection("list")
      .get();
    const docs = doc.docs;
    const arr = [];
    docs.forEach((doc) => {
      arr.push(doc.data());
    });
    return arr;
  } catch (e) {
    console.error(e);
    // rollbar.error(e, "get referrer list");
    Sentry.captureException(e, { extra: { msg: "get referrer list" } })
    return [];
  }
}

export async function retrieveReferrers() {
  let local;
  try {
    local = localStorage.getItem("referrers");
  } catch (e) { }

  if (local) {
    // check expiry
    const obj = JSON.parse(local);
    const hasExpired = new Date().getTime() > obj.expires;
    if (!hasExpired) {
      return obj.data;
    }
  }
  const referrers = await getRefs();
  const expiry = new Date().getTime() + 1000 * 60 * 60 * 24; // 1 day
  localStorage.setItem(
    "referrers",
    JSON.stringify({ data: referrers, expires: expiry })
  );
  return referrers;
}

export async function getReferrerFromAuthority(authority) {
  try {
    const data = await retrieveReferrers();
    // get first one, there may be multiple that meet criteria
    const referrer = data.find((item) => item.authority === authority);
    return referrer.referralSource;
  } catch (error) {
    return "0000-NOT ASSIGNED";
  }
}
