import { cloneDeep, omit } from "lodash";

// save to and fetch
const participants = "participants";
const applications = "applications";
const additionalParticipants = "additionalParticipants";

// ssn is not handled here
export const map = {
  /// primary participant
  firstName: participants,
  lastName: participants,
  middleName: participants,
  email: participants,
  phoneNumber: participants,
  agreesToTextNotifications: participants,
  usaCitizen: participants,
  countryCitizenship: participants,
  usaPermanentResident: participants,
  hasUSAddress: participants,
  foreignAddressLine1: participants,
  foreignAddressApt: participants,
  foreignAddressCity: participants,
  foreignAddressState: participants,
  foreignAddressPostcode: participants,
  foreignAddressCountry: participants,
  hasForeignAddress: participants,
  resCity: participants,
  resState: participants,
  resAddressLine1: participants,
  resPostcode: participants,
  resAptNumber: participants,
  homeAddressObj: participants,
  resAddressEqualsMailing: participants,
  mailingCity: participants,
  mailingState: participants,
  mailingAddressLine1: participants,
  mailingAptNumber: participants,
  mailingPostcode: participants,
  mailingAddressObj: participants,
  hasResidedAtAddressForRequiredDuration: participants,
  previousAddressObj: participants,
  previousAddressLine1: participants,
  previousAptNumber: participants,
  previousCity: participants,
  previousState: participants,
  previousPostcode: participants,
  gender: participants,
  birthDate: participants,
  maritalStatus: participants,
  maidenName: participants,
  employmentStatus: participants,
  currentOrRecentJobTitle: participants,
  formerEmployer: participants,
  employer: participants,
  industry: participants,
  ssnRef: participants,
  greenCardStorageRef: participants,
  w8BENStorageRef: participants,
  identityJumioCallback: participants,
  identityExpiryDateOfId: participants,
  identityIdType: participants,
  identityIssueDateOfId: participants,
  identityIssuingCountryOfPassport: participants,
  identityIssuingStateOfId: participants,
  identityJumioFailAt: participants,
  identityJumioSuccessAt: participants,
  identityJumioReason: participants,
  identityNumberOnId: participants,
  ///////////////////////////////////////
  submittedAt: applications,
  status: applications,
  assignedTo: applications,
  id: applications,
  applicationRefId: applications,
  accountType: applications,
  checkingAccountType: applications,
  checkingAccountTaxTypes: applications,
  createTraditionalCheckingForTaxDeferredFunds: applications,
  createRothCheckingForPostTaxFunds: applications,
  checkingAccountNicknameRoth: applications,
  checkingAccountNicknameTraditional: applications,
  iraType: applications,
  additionalParticipantId: applications,
  referralSource: applications,
  successorLegalName: applications,
  notes: applications,
  trustStorageRef: applications,
  ss4StorageRef: applications,
  llcOperatingAgreementRef: applications,
  llcArticlesOfOrganizationRef: applications,
  trustName: applications,
  trustCreationDate: applications,
  trustStateOfRegistration: applications,
  trustAddressEquals: applications,
  trustAddressLine1: applications,
  trustState: applications,
  trustCity: applications,
  trustApt: applications,
  trustPostcode: applications,
  llcName: applications,
  llcStateOfRegistration: applications,
  llcAddressEquals: applications,
  llcAddressLine1: applications,
  llcState: applications,
  llcCity: applications,
  llcUnitOrSuite: applications,
  llcPostcode: applications,
  llcWhollyOwnedByIra: applications,
  iraPercentOwnershipInLlc: applications,
  llcOwnerNameWith25PercentShare1: applications,
  llcOwnerNameWith25PercentShare2: applications,
  llcOwnerNameWith25PercentShare3: applications,
  llcOwnerSharePercentage1: applications,
  llcOwnerSharePercentage2: applications,
  llcOwnerSharePercentage3: applications,
  createdByUserType: applications,
  investmentSector1: applications,
  investmentSector2: applications,
  willInvestInternationally: applications,
  countriesForInternationalTrans: applications,
  addAdditionalParticipant: applications,
  designatedBeneficiaries: applications,
  doesRequireSpousalConsent: applications,
  spouseApt: applications,
  spouseCity: applications,
  spouseCountry: applications,
  spouseEmail: applications,
  spouseAddressLine1: applications,
  spouseLegalName: applications,
  spousePostcode: applications,
  spouseState: applications,
  spouseBirthDate: applications,
  spouseSsnRef: applications,
  shouldNameBeneficiary: applications,
  inheritedIraHolderFirstName: applications,
  inheritedIraHolderMiddleName: applications,
  inheritedIraHolderLastName: applications,
  inheritedIraHolderDob: applications,
  inheritedIraHolderDateOfDeath: applications,
  //
  additionalParticipantLastName: [applications, additionalParticipants],
  additionalParticipantFirstName: [applications, additionalParticipants],
  additionalParticipantMiddleName: [applications, additionalParticipants],
  additionalParticipantEmail: [applications, additionalParticipants],
  additionalParticipantPhone: [applications, additionalParticipants],
  additionalParticipantAgreesToTextNotifications: additionalParticipants,
  additionalParticipantUsaCitizen: additionalParticipants,
  additionalParticipantCountryCitizenship: additionalParticipants,
  additionalParticipantUsaPermanentResident: additionalParticipants,
  additionalParticipantHasUSAddress: additionalParticipants,
  additionalParticipantForeignAddressLine1: additionalParticipants,
  additionalParticipantForeignAddressApt: additionalParticipants,
  additionalParticipantForeignAddressCity: additionalParticipants,
  additionalParticipantForeignAddressState: additionalParticipants,
  additionalParticipantForeignAddressPostcode: additionalParticipants,
  additionalParticipantForeignAddressCountry: additionalParticipants,
  additionalParticipantHasForeignAddress: additionalParticipants,
  additionalParticipantResCity: additionalParticipants,
  additionalParticipantResState: additionalParticipants,
  additionalParticipantResAddressLine1: additionalParticipants,
  additionalParticipantResPostcode: additionalParticipants,
  additionalParticipantResAptNumber: additionalParticipants,
  additionalParticipantPreviousAddressLine1: additionalParticipants,
  additionalParticipantPreviousAddressObj: additionalParticipants,
  additionalParticipantPreviousAptNumber: additionalParticipants,
  additionalParticipantPreviousCity: additionalParticipants,
  additionalParticipantPreviousState: additionalParticipants,
  additionalParticipantPreviousPostcode: additionalParticipants,
  additionalParticipantHasResidedAtAddressForRequiredDuration: additionalParticipants,
  additionalParticipantResAddressEqualsMailing: additionalParticipants,
  additionalParticipantMailingCity: additionalParticipants,
  additionalParticipantMailingState: additionalParticipants,
  additionalParticipantMailingAddressLine1: additionalParticipants,
  additionalParticipantMailingAptNumber: additionalParticipants,
  additionalParticipantMailingPostcode: additionalParticipants,
  additionalParticipantGender: additionalParticipants,
  additionalParticipantBirthDate: additionalParticipants,
  additionalParticipantMaritalStatus: additionalParticipants,
  additionalParticipantMaidenName: additionalParticipants,
  additionalParticipantEmploymentStatus: additionalParticipants,
  additionalParticipantCurrentOrRecentJobTitle: additionalParticipants,
  additionalParticipantEmployer: additionalParticipants,
  additionalParticipantIndustry: additionalParticipants,
  additionalParticipantGreenCardStorageRef: additionalParticipants,
  additionalParticipantW8BENStorageRef: additionalParticipants,
  additionalParticipantIdentityJumioCallback: additionalParticipants,
  additionalParticipantIdentityExpiryDateOfId: additionalParticipants,
  additionalParticipantIdentityIdType: additionalParticipants,
  additionalParticipantIdentityIssueDateOfId: additionalParticipants,
  additionalParticipantIdentityIssuingCountryOfPassport: additionalParticipants,
  additionalParticipantIdentityIssuingStateOfId: additionalParticipants,
  additionalParticipantIdentityJumioFailAt: additionalParticipants,
  additionalParticipantIdentityJumioSuccessAt: additionalParticipants,
  additionalParticipantIdentityNumberOnId: additionalParticipants,
  additionalParticipantIdentityJumioReason: additionalParticipants,
  additionalParticipantSecurityAnswer1: additionalParticipants,
  additionalParticipantSecurityAnswer2: additionalParticipants,
  additionalParticipantSecurityAnswer3: additionalParticipants,
  additionalParticipantSecurityQuestion1: additionalParticipants,
  additionalParticipantSecurityQuestion2: additionalParticipants,
  additionalParticipantSecurityQuestion3: additionalParticipants,
  additionalParticipantSsnRef: additionalParticipants,
  //////////////////////////////
};

export default function resolveDataMap(obj, collection) {
  // return filtered data obj
  console.log("resolve data map", obj);
  const filtered = Object.keys(obj).reduce((acc, curr) => {
    if (Array.isArray(map[curr])) {
      if (map[curr].includes(collection)) {
        const value = obj[curr];
        const scrubbed = removeNestedSSNs(curr, value);
        acc[curr] = scrubbed;
      }
    } else {
      if (map[curr] === collection) {
        const value = obj[curr];
        const scrubbed = removeNestedSSNs(curr, value);
        acc[curr] = scrubbed;
      }
    }
    return acc;
  }, {});
  return filtered;
}

function removeNestedSSNs(key, value) {
  const keys = ["designatedBeneficiaries"];
  if (keys.includes(key)) {
    // scrub
    let newValue = cloneDeep(value);
    // handle specific nested cases
    if (key === "designatedBeneficiaries") {
      newValue = newValue.map((obj) => {
        const scrubbed = omit(obj, ["beneficiarySsn", "beneficiaryTaxId"]);
        return scrubbed;
      });
    }
    return newValue;
  }
  return value;
}

export const additionalParticipantDataMap = {
  firstName: "additionalParticipantFirstName",
  lastName: "additionalParticipantLastName",
  middleName: "additionalParticipantMiddleName",
  email: "additionalParticipantEmail",
  phoneNumber: "additionalParticipantPhone",
  agreesToTextNotifications: "additionalParticipantAgreesToTextNotifications",
  usaCitizen: "additionalParticipantUsaCitizen",
  countryCitizenship: "additionalParticipantCountryCitizenship",
  usaPermanentResident: "additionalParticipantUsaPermanentResident",
  hasUSAddress: "additionalParticipantHasUSAddress",
  foreignAddressLine1: "additionalParticipantForeignAddressLine1",
  foreignAddressApt: "additionalParticipantForeignAddressApt",
  foreignAddressCity: "additionalParticipantForeignAddressCity",
  foreignAddressState: "additionalParticipantForeignAddressState",
  foreignAddressPostcode: "additionalParticipantForeignAddressPostcode",
  foreignAddressCountry: "additionalParticipantForeignAddressCountry",
  hasForeignAddress: "additionalParticipantHasForeignAddress",
  resCity: "additionalParticipantResCity",
  resState: "additionalParticipantResState",
  resAddressLine1: "additionalParticipantResAddressLine1",
  resPostcode: "additionalParticipantResPostcode",
  resAptNumber: "additionalParticipantResAptNumber",
  previousAddressLine1: "additionalParticipantPreviousAddressLine1",
  previousAddressObj: "additionalParticipantPreviousAddressObj",
  previousAptNumber: "additionalParticipantPreviousAptNumber",
  previousCity: "additionalParticipantPreviousCity",
  previousState: "additionalParticipantPreviousState",
  previousPostcode: "additionalParticipantPreviousPostcode",
  hasResidedAtAddressForRequiredDuration:
    "additionalParticipantHasResidedAtAddressForRequiredDuration",
  resAddressEqualsMailing: "additionalParticipantResAddressEqualsMailing",
  mailingCity: "additionalParticipantMailingCity",
  mailingState: "additionalParticipantMailingState",
  mailingAddressLine1: "additionalParticipantMailingAddressLine1",
  mailingAptNumber: "additionalParticipantMailingAptNumber",
  mailingPostcode: "additionalParticipantMailingPostcode",
  gender: "additionalParticipantGender",
  birthDate: "additionalParticipantBirthDate",
  maritalStatus: "additionalParticipantMaritalStatus",
  maidenName: "additionalParticipantMaidenName",
  employmentStatus: "additionalParticipantEmploymentStatus",
  currentOrRecentJobTitle: "additionalParticipantCurrentOrRecentJobTitle",
  employer: "additionalParticipantEmployer",
  formerEmployer: "additionalParticipantFormerEmployer",
  industry: "additionalParticipantIndustry",
  ssnRef: "additionalParticipantSsnRef",
  greenCardStorageRef: "additionalParticipantGreenCardStorageRef",
  w8BENStorageRef: "additionalParticipantW8BENStorageRef",
  identityExpiryDateOfId: "additionalParticipantIdentityExpiryDateOfId",
  identityIdType: "additionalParticipantIdentityIdType",
  identityIssueDateOfId: "additionalParticipantIdentityIssueDateOfId",
  identityIssuingCountryOfPassport:
    "additionalParticipantIdentityIssuingCountryOfPassport",
  identityJumioCallback: "additionalParticipantIdentityJumioCallback",
  identityIssuingStateOfId: "additionalParticipantIdentityIssuingStateOfId",
  identityJumioFailAt: "additionalParticipantIdentityJumioFailAt",
  identityJumioSuccessAt: "additionalParticipantIdentityJumioSuccessAt",
  identityJumioReason: "additionalParticipantIdentityJumioReason",
  identityNumberOnId: "additionalParticipantIdentityNumberOnId",
};

export const statusTypes = [
  { name: "DRAFT", value: "DRAFT" },
  { name: "REQUIRES REVIEW", value: "REQUIRES REVIEW" },
  { name: "READY FOR BATCH", value: "READY FOR BATCH" },
  { name: "IN PROCESS", value: "IN PROCESS" },
  { name: "APPROVED", value: "APPROVED" },
  { name: "DECLINED", value: "DECLINED" },
  { name: "ON HOLD", value: "ON HOLD" },
  { name: "ARCHIVED", value: "ARCHIVED" },
];

// we map the refs to a front-end key that doesn't get saved
export const ssnDataMap = {
  // front-end key: [backendKey, store]
  primarySocialSecurity: ["ssnRef", participants],
  trustTaxId: ["trustTaxIdRef", applications],
  inheritedIraHolderSocialSecurityNumber: [
    "inheritedIraHolderSsnRef",
    applications,
  ],
  llcTaxId: ["llcTaxIdRef", applications],
  spouseSocialSecurity: ["spouseSsnRef", applications],
};

export const additionalParticipantSsnDataMap = {
  additionalParticipantSocialSecurity: [
    "additionalParticipantSsnRef",
    participants,
  ],
};

export const nestedSSNDataMap = {
  beneficiarySsn: ["ssnRef", applications],
  beneficiaryTaxId: ["taxIdRef", applications],
};

export const documentMap = {
  ss4StorageRef: "ss4.pdf",
  trustStorageRef: "plan_adoption.pdf",
  llcOperatingAgreementRef: "llc_operating_agreement.pdf",
  llcArticlesOfOrganizationRef: "llc_articles_of_organization.pdf",
  greenCardStorageRef: "green_card.pdf",
  w8BENStorageRef: "W8_BEN.pdf",
  additionalParticipantGreenCardStorageRef: "green_card.pdf",
  additionalParticipantW8BENStorageRef: "W8_BEN.pdf",
};

export const resolveDocumentPaths = (
  ref,
  applicationId,
  participantId,
  additionalParticipantId
) => {
  const paths = {
    ss4StorageRef: `/applications/${applicationId}/trust/ss4.pdf`,
    trustStorageRef: `/applications/${applicationId}/trust/plan_adoption.pdf`,
    llcOperatingAgreementRef: `/applications/${applicationId}/llc/llc_operating_agreement.pdf`,
    llcArticlesOfOrganizationRef: `/applications/${applicationId}/llc/llc_articles_of_organization.pdf`,
    greenCardStorageRef: `/participants/${participantId}/identity/green_card.pdf`,
    w8BENStorageRef: `/participants/${participantId}/identity/W8_BEN.pdf`,
    additionalParticipantGreenCardStorageRef: `/participants/${additionalParticipantId}/identity/green_card.pdf`,
    additionalParticipantW8BENStorageRef: `/participants/${additionalParticipantId}/identity/W8_BEN.pdf`,
  };
  return paths[ref];
};

export function mapParticipantDataToApplication(app, part) {
  const fields = Object.keys(map)
    .filter((key) => map[key] === "participants")
    .map((key) => key);
  fields.forEach((field) => {
    app[field] = part[field];
  });
  return app;
}

export function mapApplicationDataToParticipant(part, app) {
  const fields = Object.keys(map)
    .filter((key) => map[key] === "applications")
    .map((key) => key);
  fields.forEach((field) => {
    part[field] = app[field];
  });
  return part;
}

export function mapAdditionalParticipantData(data) {
  console.log("map data", data);
  const payload = Object.keys(additionalParticipantDataMap).reduce(
    (acc, curr) => {
      if (data[additionalParticipantDataMap[curr]] !== undefined) {
        console.log(data[additionalParticipantDataMap[curr]]);
        acc[curr] = data[additionalParticipantDataMap[curr]];
      }
      return acc;
    },
    {}
  );
  return payload;
}

export function mapAdditionalParticipantDataToApplication(app, part) {
  Object.keys(additionalParticipantDataMap).forEach((key) => {
    app[additionalParticipantDataMap[key]] = part[key];
  });
  return app;
}
