import React, { useState, useContext, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  TextField,
  OutlinedInput,
  InputLabel,
  Typography,
  FormControl,
  makeStyles,
  InputAdornment,
  IconButton,
  Link as MuiLink,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { UserContext } from "components/User";

const useStyles = makeStyles(() => ({
  cta: {
    marginTop: "20px",
  },
  passLabel: {
    top: "-8px",
  },
}));

const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(5);
  const { isLoggedIn, signIn, errorLogin } = useContext(UserContext);

  const classes = useStyles();

  async function handleSubmit(e) {
    e.preventDefault();
    await signIn(inputs);
  }

  function handleChange(e) {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    let mounted = true;
    // count down when errorLogin is updated (it gets updated each failed attempt)
    function handleCount() {
      if (mounted && errorLogin) {
        setLoginAttempts((curr) => --curr);
      }
    }
    handleCount();
    return function cleanup() {
      mounted = false;
    };
  }, [errorLogin]);

  return (
    <main>
      <Container maxWidth="sm">
        {isLoggedIn ? (
          <Redirect to="/" />
        ) : (
          <form onSubmit={handleSubmit} autoComplete="on">
            <Box display="flex" flexDirection="column">
              <Typography gutterBottom={true} variant="h4">
                Login
              </Typography>
              <Box my={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="email"
                  type="email"
                  name="email"
                  id="email"
                  value={inputs.email}
                  onChange={handleChange}
                  autoComplete="email"
                  margin="dense"
                ></TextField>
              </Box>
              <FormControl variant="outlined">
                <InputLabel htmlFor="password" className={classes.passLabel}>
                  password
                </InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  label="Password"
                  type={isPasswordVisible ? "text" : "password"}
                  value={inputs.password}
                  onChange={handleChange}
                  autoComplete="current-password"
                  margin="dense"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        edge="end"
                        size="small"
                      >
                        {isPasswordVisible ? (
                          <VisibilityIcon fontSize="small" />
                        ) : (
                          <VisibilityOffIcon fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box py={1}>
                <Typography variant="body2" color="textSecondary">
                  Log in attempts: {loginAttempts}
                </Typography>
              </Box>

              <Button
                className={classes.cta}
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={
                  !inputs.email || !inputs.password || loginAttempts <= 0
                }
              >
                Submit
              </Button>

              <Box py={2}>
                <Typography variant="body1" color="error">
                  {errorLogin}
                </Typography>
              </Box>
            </Box>
          </form>
        )}
        <Box py={8}>
          <Typography variant="body2">
            <MuiLink to="/password-reset" component={Link}>
              Forgot your password?
            </MuiLink>
          </Typography>
        </Box>
      </Container>
    </main>
  );
};

export default Login;
