import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Plus from "@material-ui/icons/ControlPoint";

import { UserContext } from "components/User";

const useStyles = makeStyles((theme) => ({
  nav: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    "& a": {
      color: "#fff",
    },
  },
  button: {
    color: theme.palette.background.default,
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.2)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.background.divider,
      },
    },
  },
  navLeft: {
    flex: 0.5,
    "& a:first-of-type": {
      marginRight: "20px",
    },
    display: "flex",
    alignItems: "center",
  },
  navRight: {
    flex: 0.5,
    display: "flex",
    justifyContent: "flex-end",
    "& a": {
      lineHeight: "0",
    },
  },
}));

const Header = () => {
  const { isLoggedIn, authorityDomain } = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar position="static">
      <Toolbar>
        <nav className={classes.nav}>
          <Box className={classes.navLeft}>
            <Link to="/">Dashboard</Link>
            <Tooltip
              title="new application"
              placement="right"
              aria-label="new application"
            >
              <IconButton
                onClick={() => history.push("/applications/new")}
                aria-label="new application"
                className={classes.button}
              >
                <Plus />
              </IconButton>
            </Tooltip>
            {authorityDomain === "solera" && (
              <Box ml={3}>
                <Link to="/fair-market-value">Fair Market Value</Link>
              </Box>
            )}
          </Box>
          {isLoggedIn && (
            <Box className={classes.navRight}>
              <Link to="/my-account">
                <AccountCircle />
              </Link>
            </Box>
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
