import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Accordion from "components/Accordion";
import DatePicker from "components/DatePicker";
import { equality, mapIndustriesToOptions } from "util/helpers";

const PersonalInfo = ({
  section,
  gender,
  birthDate,
  maritalStatus,
  maidenName,
  employmentStatus,
  currentOrRecentJobTitle,
  formerEmployer,
  employer,
  industry,
  handleChange,
  updateField,
  classes,
  readOnly,
}) => {
  // console.log("personal info render");
  const shouldDislayMaidenName =
    gender === "female" &&
    (maritalStatus === "married" ||
      maritalStatus === "divorced" ||
      maritalStatus === "widowed");
  const shouldDisplayJobTitle =
    employmentStatus === null ||
    employmentStatus === "employed" ||
    employmentStatus === "self-employed";
  const shouldDisplayEmployer =
    employmentStatus === null ||
    employmentStatus === "self-employed" ||
    employmentStatus === "employed";
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${section}-content`}
        id={`${section}-header`}
      >
        <Typography variant="h6">Personal Information</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.section}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{"Applicant gender"}</FormLabel>
            <RadioGroup
              aria-label={"gender"}
              name={"gender"}
              value={gender}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value={"female"}
                control={<Radio disabled={readOnly} />}
                label="Female"
              />
              <FormControlLabel
                value={"male"}
                control={<Radio disabled={readOnly} />}
                label="male"
              />
            </RadioGroup>
          </FormControl>
          <Box mt={3} display="flex" flexDirection="column">
            <FormLabel component="legend">{"Birth date"}</FormLabel>
            <DatePicker
              id={`datepicker-birthDate`}
              initialDate={birthDate}
              dateHandler={updateField.bind(null, "birthDate")}
              disabled={readOnly}
            />
          </Box>
          <Box mt={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{"Marital status"}</FormLabel>
              <RadioGroup
                aria-label={"maritalStatus"}
                name={"maritalStatus"}
                value={maritalStatus}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value={"single"}
                  control={<Radio disabled={readOnly} />}
                  label="Single"
                />
                <FormControlLabel
                  value={"married"}
                  control={<Radio disabled={readOnly} />}
                  label="Married"
                />
                <FormControlLabel
                  value={"divorced"}
                  control={<Radio disabled={readOnly} />}
                  label="Divorced"
                />
                <FormControlLabel
                  value={"widowed"}
                  control={<Radio disabled={readOnly} />}
                  label="Widowed"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {shouldDislayMaidenName && (
            <TextField
              className={classes.input}
              variant="standard"
              label="Maiden name"
              type="text"
              name="maidenName"
              id="maidenName"
              value={maidenName || ""}
              onChange={handleChange}
              margin="dense"
              inputProps={{ readOnly: Boolean(readOnly) }}
            ></TextField>
          )}
          <Box mt={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{"Employment status"}</FormLabel>
              <RadioGroup
                aria-label={"employmentStatus"}
                name={"employmentStatus"}
                value={employmentStatus}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value={"employed"}
                  control={<Radio disabled={readOnly} />}
                  label="Employed"
                />
                <FormControlLabel
                  value={"self-employed"}
                  control={<Radio disabled={readOnly} />}
                  label="Self-employed"
                />
                <FormControlLabel
                  value={"unemployed"}
                  control={<Radio disabled={readOnly} />}
                  label="Unemployed"
                />
                <FormControlLabel
                  value={"retired"}
                  control={<Radio disabled={readOnly} />}
                  label="Retired"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <TextField
            className={classes.input}
            variant="standard"
            label={
              shouldDisplayJobTitle
                ? "Current Job Title"
                : "Most Recent Job Title"
            }
            type="text"
            name="currentOrRecentJobTitle"
            id="currentOrRecentJobTitle"
            value={currentOrRecentJobTitle || ""}
            onChange={handleChange}
            margin="dense"
            inputProps={{ readOnly: Boolean(readOnly) }}
          ></TextField>

          <TextField
            className={classes.input}
            variant="standard"
            label={shouldDisplayEmployer ? "Employer" : "Most recent employer"}
            type="text"
            name={shouldDisplayEmployer ? "employer" : "formerEmployer"}
            id={shouldDisplayEmployer ? "employer" : "formerEmployer"}
            value={
              shouldDisplayEmployer ? employer || "" : formerEmployer || ""
            }
            onChange={handleChange}
            autoComplete="organization"
            margin="dense"
            inputProps={{ readOnly: Boolean(readOnly) }}
          ></TextField>
          <InputLabel id="industry" className={classes.selectLabel}>
            Select an industry
          </InputLabel>
          <Select
            fullWidth
            labelId="industry"
            name="industry"
            id="industry"
            value={industry || ""}
            onChange={handleChange}
            readOnly={readOnly}
          >
            {mapIndustriesToOptions().map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

PersonalInfo.defaultProps = {
  gender: null,
  birthDate: new Date(),
  maritalStatus: null,
  maidenName: "",
  employmentStatus: null,
  currentOrRecentJobTitle: "",
  employer: "",
  formerEmployer: "",
  industry: "",
  readOnly: false,
};

PersonalInfo.propTypes = {
  gender: PropTypes.string,
  birthDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  maritalStatus: PropTypes.string,
  maidenName: PropTypes.string,
  employmentStatus: PropTypes.string,
  currentOrRecentJobTitle: PropTypes.string,
  employer: PropTypes.string,
  formerEmployer: PropTypes.string,
  industry: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  readOnly: PropTypes.bool,
};

const fields = [
  "gender",
  "birthDate",
  "maritalStatus",
  "maidenName",
  "employmentStatus",
  "currentOrRecentJobTitle",
  "employer",
  "formerEmployer",
  "industry",
  "readOnly",
];

export default memo(PersonalInfo, (prev, next) => equality(prev, next, fields));
