import { Accordion as MUIAccordion, withStyles } from "@material-ui/core";
export default withStyles({
  root: {
    marginTop: "2rem",
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  expanded: {},
})(MUIAccordion);
