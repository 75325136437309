import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import MarkdownView from "react-showdown";

import { getDocumentation } from "util/requests/settings";

const Documentation = () => {
  const [markdown, setMarkdown] = useState(null);
  useEffect(() => {
    function init() {
      if (!markdown) {
        getDocumentation(setMarkdown);
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container maxWidth="md">
      <Box pb={6}>
        <Box pt={4} pb={2}>
          <Typography variant="h4">Instructions</Typography>
        </Box>
        {!markdown && (
          <Box>
            <CircularProgress />
          </Box>
        )}
        <Box>
          <MarkdownView
            markdown={markdown}
            options={{
              tables: false,
              simplifiedAutoLink: true,
              strikethrough: false,
              tasklists: true,
              openLinksInNewWindow: true,
              emoji: true,
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Documentation;
