import React, { useRef } from "react";
import Proptypes from "prop-types";
import { Typography, Box, makeStyles, IconButton } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

const useStyles = makeStyles({
  input: {
    display: "none",
  },
});

const FileUpload = (props) => {
  const { name, error, required, changeHandler, accept } = props;
  const classes = useStyles();
  const fileInput = useRef(null);
  return (
    <>
      <Box>
        <input
          className={classes.input}
          ref={fileInput}
          name={name}
          type="file"
          id={name}
          required={required}
          aria-required={required}
          aria-invalid={!!error}
          aria-describedby={`${name}Error`}
          onChange={changeHandler}
          accept={accept}
        />
        <IconButton
          aria-label="left"
          color="secondary"
          onClick={() => {
            fileInput.current.click();
          }}
          disableRipple
        >
          <PublishIcon />
        </IconButton>
      </Box>
      {!!error && (
        <Box>
          <Typography variant="body2" id={`${name}Error`} color="error">
            {error}
          </Typography>
        </Box>
      )}
    </>
  );
};

FileUpload.defaultProps = {
  required: false,
  accept: "application/pdf",
};

FileUpload.propTypes = {
  name: Proptypes.string.isRequired,
  error: Proptypes.string,
  required: Proptypes.bool,
  changeHandler: Proptypes.func.isRequired,
  accept: Proptypes.string,
};

export default FileUpload;
