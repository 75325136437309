import firebase from "config/firebase";

export default function monitorVersion() {
  const env =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ENV === "staging"
      ? "staging"
      : "production";
  const vJson = require("../version.json");
  const version = vJson && vJson.version && vJson.version[env];
  if (!version) return;
  // ensure firebase matches local version
  // if not we offer a hard refresh and get fresh files
  console.log(version);
  firebase
    .firestore()
    .collection("settings")
    .doc("portalVersion")
    .onSnapshot((doc) => {
      if (doc.exists) {
        const { version: dbVersion } = doc.data();
        console.log("db version", dbVersion);
        if (dbVersion !== version) {
          const hasCheckedRecently = getCheckedVersion();
          if (hasCheckedRecently) return;
          const res = window.confirm(
            "A newer version of this app exists. Would you like to refresh to use it?\n\nPlease note by clicking 'OK' the page will refresh and you will lose all your unsaved work. You can always refresh the page later."
          );
          if (res === true) {
            saveCheckedVersion();
            clearCacheAndRefresh();
          }
        }
      }
    });
}

async function clearCacheAndRefresh() {
  try {
    if ("caches" in window) {
      const names = await caches.keys();
      console.log("names", names);
      await Promise.all(names.map((name) => caches.delete(name)));
      window.location.reload();
    } else {
      window.location.reload();
    }
  } catch (error) {
    window.location.reload();
  }
}

function getCheckedVersion() {
  let local;
  try {
    local = localStorage.getItem("checkedVersion");
  } catch (e) {}

  if (local) {
    // check expiry
    const obj = JSON.parse(local);
    const hasExpired = new Date().getTime() > obj.expires;
    if (!hasExpired) {
      return true;
    }
  }
  return false;
}

function saveCheckedVersion() {
  const expiry = new Date().getTime() + 1000 * 60 * 60 * 2; // 2 hours
  localStorage.setItem(
    "checkedVersion",
    JSON.stringify({ checkedVersion: true, expires: expiry })
  );
}
