import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  AccordionSummary,
  Input,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  AccordionDetails,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Accordion from "components/Accordion";
import SSNInput from "components/SSNInput";

import { equality, mapStatesToOptions } from "util/helpers";
import {
  CHECKING_ACCOUNT_TYPE_LLC,
  ACCOUNT_TYPE_BUSINESS_ACCOUNT,
} from "config/constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LLC = ({
  section,
  accountType,
  checkingAccountType,
  llcName,
  llcTaxId,
  llcStateOfRegistration,
  llcAddressEquals,
  llcAddressLine1,
  llcState,
  llcCity,
  llcUnitOrSuite,
  llcPostcode,
  llcWhollyOwnedByIra,
  iraPercentOwnershipInLlc,
  llcOwnerNameWith25PercentShare1,
  llcOwnerNameWith25PercentShare2,
  llcOwnerNameWith25PercentShare3,
  llcOwnerSharePercentage1,
  llcOwnerSharePercentage2,
  llcOwnerSharePercentage3,
  //
  resCity,
  resState,
  resAddressLine1,
  resPostcode,
  mailingCity,
  mailingState,
  mailingAddressLine1,
  mailingPostcode,
  //
  handleChange,
  updateField,
  classes,
  readOnly,
  isLoadingSSNs,
}) => {
  // console.log("rendering LLC");
  const isResAddressComplete =
    resCity && resState && resAddressLine1 && resPostcode;
  const isMailingAddressComplete =
    mailingCity && mailingState && mailingAddressLine1 && mailingPostcode;

  const llcTax = useMemo(
    () => (
      <SSNInput
        label="LLC Tax id"
        maskType="taxId"
        name="llcTaxId"
        value={llcTaxId}
        handleChange={handleChange}
        readOnly={readOnly || isLoadingSSNs}
        isLoading={isLoadingSSNs}
      />
    ),
    /* eslint-disable-next-line */
    [llcTaxId, readOnly, isLoadingSSNs]
  );

  const totalShare = (name, value) => {
    let arr = [
      { name: "iraPercentOwnershipInLlc", value: iraPercentOwnershipInLlc },
      { name: "llcOwnerSharePercentage1", value: llcOwnerSharePercentage1 },
      { name: "llcOwnerSharePercentage2", value: llcOwnerSharePercentage2 },
      { name: "llcOwnerSharePercentage3", value: llcOwnerSharePercentage3 },
    ];

    arr = arr.filter((item) => item.name !== name);
    let total = 0;

    arr.forEach((item) => {
      total += parseFloat(item.value || 0);
    });
    total += parseFloat(value || 0);
    return total;
  };

  const handlePercentShare = (e) => {
    let { value } = e.target;
    value = parseFloat(value);
    if (value > 100) return;
    updateField("iraPercentOwnershipInLlc", value);
  };

  const handleAdditionalOwnerPercent = (e) => {
    let { name, value } = e.target;
    value = parseFloat(value) || 0;

    const total = totalShare(name, value);
    if (total > 100) return;
    if (value <= 100 || value === "") {
      updateField(name, value);
    }
  };

  return (
    <>
      {checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${section}-content`}
            id={`${section}-header`}
          >
            <Typography variant="h6">LLC</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.section}>
              <Box mb={4}>
                <TextField
                  fullWidth
                  className={classes.input}
                  variant="standard"
                  label="LLC Name"
                  type="text"
                  name={"llcName"}
                  id={"llcName"}
                  value={llcName || ""}
                  onChange={handleChange}
                  margin="dense"
                  inputProps={{
                    readOnly: Boolean(readOnly),
                  }}
                ></TextField>
                {llcTax}
                <InputLabel id="resState" className={classes.selectLabel}>
                  State of LLC registration
                </InputLabel>
                <Select
                  fullWidth
                  labelId="llcStateOfRegistration"
                  name="llcStateOfRegistration"
                  id="llcStateOfRegistration"
                  value={llcStateOfRegistration || ""}
                  onChange={handleChange}
                  readOnly={readOnly}
                >
                  {mapStatesToOptions().map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {isResAddressComplete && isMailingAddressComplete ? (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{"LLC Address info"}</FormLabel>
                  <RadioGroup
                    aria-label={"llcAddressEquals"}
                    name={"llcAddressEquals"}
                    value={llcAddressEquals}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value={"residential"}
                      control={<Radio disabled={readOnly} />}
                      label="Same as residential"
                    />
                    <FormControlLabel
                      value={"mailing"}
                      control={<Radio disabled={readOnly} />}
                      label="Same as mailing"
                    />
                    <FormControlLabel
                      value={"other"}
                      control={<Radio disabled={readOnly} />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <Alert severity="warning">
                  Please complete the Contact information address section
                </Alert>
              )}

              {!!llcAddressEquals && (
                <>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="LLC address line 1"
                    type="text"
                    name="llcAddressLine1"
                    id="llcAddressLine1"
                    value={llcAddressLine1 || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="LLC Unit or Suite"
                    type="text"
                    name="llcUnitOrSuite"
                    id="llcUnitOrSuite"
                    value={llcUnitOrSuite || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="LLC City"
                    type="text"
                    name="llcCity"
                    id="llcCity"
                    value={llcCity || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                  <InputLabel id="llcState" className={classes.selectLabel}>
                    LLC State
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="llcState"
                    name="llcState"
                    id="llcState"
                    value={llcState || ""}
                    onChange={handleChange}
                    readOnly={readOnly}
                  >
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    label="Zip code"
                    type="text"
                    name="llcPostcode"
                    id="llcPostcode"
                    value={llcPostcode || ""}
                    onChange={handleChange}
                    margin="dense"
                    inputProps={{ readOnly: Boolean(readOnly) }}
                  ></TextField>
                </>
              )}
              <Box pt={3}>
                {accountType !== ACCOUNT_TYPE_BUSINESS_ACCOUNT && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {"LLC Wholly-owner by IRA"}
                    </FormLabel>
                    <RadioGroup
                      aria-label={"llcWhollyOwnedByIra"}
                      name={"llcWhollyOwnedByIra"}
                      value={llcWhollyOwnedByIra}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio disabled={readOnly} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio disabled={readOnly} />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                {llcWhollyOwnedByIra === false && (
                  <Box mt={2}>
                    <FormLabel component="legend">
                      {"primary applicant % ownership in this LLC"}
                    </FormLabel>
                    <Input
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      fullWidth
                      className={classes.input}
                      variant="standard"
                      placeholder="% share"
                      type="number"
                      name="iraPercentOwnershipInLlc"
                      id="iraPercentOwnershipInLlc"
                      value={iraPercentOwnershipInLlc || ""}
                      onChange={handlePercentShare}
                      margin="dense"
                      inputProps={{
                        readOnly: Boolean(readOnly),
                        max: 100,
                        min: 0,
                      }}
                    ></Input>
                    {parseFloat(iraPercentOwnershipInLlc) < 75 &&
                      parseFloat(iraPercentOwnershipInLlc) > 0 && (
                        <Box mt={2}>
                          <Typography variant="body1">
                            Please include additional LLC owners with 25% or
                            more
                          </Typography>
                          <Box mt={2}>
                            <Typography variant="body2">
                              Additional owner (1)
                            </Typography>
                            <Box className={classes.inputRow}>
                              <TextField
                                className={classes.inputCol1}
                                id="llcOwnerNameWith25PercentShare1"
                                name="llcOwnerNameWith25PercentShare1"
                                value={llcOwnerNameWith25PercentShare1}
                                type="text"
                                onChange={handleChange}
                                placeholder="Name"
                              />
                              <Input
                                endAdornment={
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                }
                                className={classes.inputCol2}
                                id="llcOwnerSharePercentage1"
                                name="llcOwnerSharePercentage1"
                                value={llcOwnerSharePercentage1 || ""}
                                type="number"
                                onChange={handleAdditionalOwnerPercent}
                                placeholder="Share %"
                                inputProps={{
                                  readOnly: Boolean(readOnly),
                                  max: 100,
                                  min: 0,
                                }}
                              />
                            </Box>
                          </Box>
                          <Box mt={2}>
                            <Typography variant="body2">
                              Additional owner (2)
                            </Typography>
                            <Box className={classes.inputRow}>
                              <TextField
                                className={classes.inputCol1}
                                id="llcOwnerNameWith25PercentShare2"
                                name="llcOwnerNameWith25PercentShare2"
                                value={llcOwnerNameWith25PercentShare2}
                                type="text"
                                onChange={handleChange}
                                placeholder="Name"
                              />
                              <Input
                                endAdornment={
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                }
                                className={classes.inputCol2}
                                id="llcOwnerSharePercentage2"
                                name="llcOwnerSharePercentage2"
                                value={llcOwnerSharePercentage2 || ""}
                                type="number"
                                onChange={handleAdditionalOwnerPercent}
                                placeholder="Share %"
                                inputProps={{
                                  readOnly: Boolean(readOnly),
                                  max: 100,
                                  min: 0,
                                }}
                              />
                            </Box>
                          </Box>
                          <Box mt={2}>
                            <Typography variant="body2">
                              Additional owner (3)
                            </Typography>
                            <Box className={classes.inputRow}>
                              <TextField
                                className={classes.inputCol1}
                                id="llcOwnerNameWith25PercentShare3"
                                name="llcOwnerNameWith25PercentShare3"
                                value={llcOwnerNameWith25PercentShare3}
                                type="text"
                                onChange={handleChange}
                                placeholder="Name"
                              />
                              <Input
                                endAdornment={
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                }
                                className={classes.inputCol2}
                                id="llcOwnerSharePercentage3"
                                name="llcOwnerSharePercentage3"
                                value={llcOwnerSharePercentage3 || ""}
                                type="number"
                                onChange={handleAdditionalOwnerPercent}
                                placeholder="Share %"
                                inputProps={{
                                  readOnly: Boolean(readOnly),
                                  max: 100,
                                  min: 0,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                  </Box>
                )}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

LLC.defaultProps = {
  accountType: null,
  checkingAccountType: null,
  llcWhollyOwnedByIra: null,
  iraPercentOwnershipInLlc: 0,
  llcName: "",
  llcStateOfRegistration: "",
  llcAddressEquals: "",
  llcAddressLine1: "",
  llcState: "",
  llcUnitOrSuite: "",
  llcCity: "",
  llcPostcode: "",
  llcTaxId: "",
  readOnly: false,
  llcOwnerNameWith25PercentShare1: "",
  llcOwnerNameWith25PercentShare2: "",
  llcOwnerNameWith25PercentShare3: "",
  llcOwnerSharePercentage1: 0,
  llcOwnerSharePercentage2: 0,
  llcOwnerSharePercentage3: 0,
};

LLC.propTypes = {
  accountType: PropTypes.string,
  checkingAccountType: PropTypes.string,
  llcName: PropTypes.string,
  llcStateOfRegistration: PropTypes.string,
  llcAddressEquals: PropTypes.oneOf(["mailing", "residential", "other", ""]),
  llcAddressLine1: PropTypes.string,
  llcState: PropTypes.string,
  llcUnitOrSuite: PropTypes.string,
  llcCity: PropTypes.string,
  llcPostcode: PropTypes.string,
  llcWhollyOwnedByIra: PropTypes.bool,
  iraPercentOwnershipInLlc: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  llcTaxId: PropTypes.string,
  llcOwnerNameWith25PercentShare1: PropTypes.string,
  llcOwnerNameWith25PercentShare2: PropTypes.string,
  llcOwnerNameWith25PercentShare3: PropTypes.string,
  llcOwnerSharePercentage1: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  llcOwnerSharePercentage2: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  llcOwnerSharePercentage3: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  readOnly: PropTypes.bool,
  isLoadingSSNs: PropTypes.bool,
};

const fields = [
  "accountType",
  "checkingAccountType",
  "llcName",
  "llcStateOfRegistration",
  "llcAddressEquals",
  "llcAddressLine1",
  "llcState",
  "llcUnitOrSuite",
  "llcCity",
  "llcPostcode",
  "llcWhollyOwnedByIra",
  "iraPercentOwnershipInLlc",
  "resCity",
  "resState",
  "resAddressLine1",
  "resPostcode",
  "mailingCity",
  "mailingState",
  "mailingAddressLine1",
  "mailingPostcode",
  "llcTaxId",
  "llcOwnerNameWith25PercentShare1",
  "llcOwnerNameWith25PercentShare2",
  "llcOwnerNameWith25PercentShare3",
  "llcOwnerSharePercentage1",
  "llcOwnerSharePercentage2",
  "llcOwnerSharePercentage3",
  "readOnly",
  "isLoadingSSNs",
];

export default memo(LLC, (prev, next) => equality(prev, next, fields));
