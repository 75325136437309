import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import theme from "config/theme";
import resolveThemeFromDomain from "config/resolveThemeFromDomain";

import Dashboard from "pages/Dashboard";
import ApplicationsNew from "pages/ApplicationsNew";
import Application from "pages/Application";
import Account from "pages/Account";
import Login from "pages/Login";
import NotAuthorized from "pages/NotAuthorized";
import PasswordReset from "pages/PasswordReset";
import Search from "pages/Search";
import FourOFour from "pages/FourOFour";
import FairMarket from "pages/FairMarket";
import Documentation from "pages/Documentation";

import PrivateRoute from "components/PrivateRoute";
import User from "components/User";
import ApplicationsData from "components/ApplicationsData";
import Header from "components/Header";
import ErrorBoundary from "components/ErrorBoundary";

import monitorVersion from "util/monitorVersion";
/// DEV ///
const DEV_SUBDOMAIN = "snbadmin";
///////////

monitorVersion();

function forceSubdomain() {
  if (
    // force redirect from launch from localhost
    process.env.NODE_ENV === "development" &&
    window.location.host.split(".")[1] === undefined
  ) {
    window.location.href = `https://${DEV_SUBDOMAIN}.${window.location.host}`;
  }
}

function Applications() {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/new`} exact>
        <ApplicationsNew />
      </Route>
      <Route path={`${url}/search`} exact>
        <Search />
      </Route>
      <Route path={`${url}/:id`}>
        <Application />
      </Route>
    </Switch>
  );
}

function App() {
  forceSubdomain();
  const themeName = resolveThemeFromDomain();
  return (
    <ErrorBoundary>
      <User>
        <div>
          <ThemeProvider theme={theme(themeName)}>
            <CssBaseline />
            <ApplicationsData>
              <Router>
                <div>
                  <Header />
                  <Switch>
                    <PrivateRoute path="/applications">
                      <ErrorBoundary>
                        <Applications />
                      </ErrorBoundary>
                    </PrivateRoute>

                    <PrivateRoute exact path="/">
                      <ErrorBoundary>
                        <Dashboard />
                      </ErrorBoundary>
                    </PrivateRoute>
                    <PrivateRoute exact path="/my-account">
                      <Account />
                    </PrivateRoute>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/password-reset">
                      <PasswordReset />
                    </Route>
                    <PrivateRoute exact path="/fair-market-value">
                      <ErrorBoundary>
                        <FairMarket />
                      </ErrorBoundary>
                    </PrivateRoute>
                    <PrivateRoute exact path="/instructions">
                      <ErrorBoundary>
                        <Documentation />
                      </ErrorBoundary>
                    </PrivateRoute>
                    <Route path="/not-authorized">
                      <NotAuthorized />
                    </Route>
                    {/* 404 */}
                    <Route path="*">
                      <FourOFour />
                    </Route>
                  </Switch>
                </div>
              </Router>
            </ApplicationsData>
          </ThemeProvider>
        </div>
      </User>
    </ErrorBoundary>
  );
}

export default App;
