import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { sendSms } from "../../util/requests/sms";
import { formatPhoneNumber } from "../../util/helpers";
import { validatePhoneNumber } from "../../util/validation";

// allows admins to send sms to customers. if successfully sent, a note will be sent
function SmsContact({
  primaryPhone,
  additionalPhone,
  primaryName,
  additionalName,
  open,
  handleClose,
  saveNote,
  primaryAgreesToSms,
  additionalAgreesToSms,
}) {
  const [msg, setMsg] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // run this each time the dialog is reopened
    if (open && primaryPhone && primaryAgreesToSms) {
      setSelectedPhone(primaryPhone);
    }
  }, [open, primaryPhone, primaryAgreesToSms]);

  function handleMsg(e) {
    setMsg(e.target.value);
  }

  function handlePhoneNumber(e) {
    setSelectedPhone(e.target.value);
  }

  function resetAndClose() {
    handleClose();
    setMsg("");
    setSelectedPhone("");
    setSuccess(false);
    setError(null);
  }

  async function handleForm(e) {
    try {
      setSuccess(false);
      e.preventDefault();
      setError(null);
      setLoading(true);
      const data = await sendSms(msg, primaryPhone);
      if (data.errorCode) throw Error(data.errorMessage);
      await saveNote(`SMS sent to ${selectedPhone}: ${msg}`);
      setMsg("");
      setSuccess(true);
    } catch (e) {
      console.log("error in component", e.message.message);
      const details = e.message;
      try {
        const parsed = JSON.parse(details);
        setError({ message: parsed.msg });
      } catch (e) {
        setError({
          message: "Sorry, we could not send the text for an unknown reason.",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return validatePhoneNumber(primaryPhone) ? (
    <Dialog
      onClose={resetAndClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box position="absolute" right={10} top={10}>
        {success === true && (
          <>
            <Box
              height={350}
              width={350}
              marginTop={"-185px"}
              marginRight={"-175px"}
              style={{
                background: "lightgray",
                borderRadius: "50%",
              }}
            ></Box>
            <Box
              display="flex"
              flexDirection="row"
              position="absolute"
              top={"30%"}
              left={"20%"}
            >
              <Typography
                variant="h4"
                style={{ color: "green", fontWeight: 500 }}
              >
                SENT
              </Typography>
              <CheckCircleOutlineIcon
                style={{ marginLeft: "5px", color: "green", fontSize: "40px" }}
              />
            </Box>
          </>
        )}
      </Box>
      <form noValidate autoComplete="off">
        <DialogTitle id="simple-dialog-title">
          Send an SMS to a Customer
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Type the text message you wish to send to the customer in the input
            below.
          </DialogContentText>
          {!primaryAgreesToSms && (
            <Typography variant="body2" style={{ color: "blue" }}>
              *The primary participant has not agreed to receiving text
              messages.
            </Typography>
          )}
          {!additionalAgreesToSms && (
            <Typography variant="body2" style={{ color: "blue" }}>
              *The additional participant has not agreed to receiving text
              messages.
            </Typography>
          )}

          <Box my={4}>
            <InputLabel id="select-phone-for-sms">Phone number</InputLabel>
            <Select
              labelId="select-phone-for-sms"
              id="select-phone"
              value={selectedPhone}
              onChange={handlePhoneNumber}
              fullWidth
            >
              <MenuItem value={primaryPhone} disabled={!primaryAgreesToSms}>
                {primaryName}

                <Box component="span" ml={2}>
                  {formatPhoneNumber(primaryPhone)}
                </Box>
              </MenuItem>
              {!!additionalPhone && (
                <MenuItem
                  value={additionalPhone}
                  disabled={!additionalAgreesToSms}
                >
                  {additionalName}

                  <Box component="span" ml={2}>
                    {formatPhoneNumber(additionalPhone)}
                  </Box>
                </MenuItem>
              )}
            </Select>
          </Box>

          <Box>
            <TextField
              id="sms-contents"
              label="Text message content"
              inputRef={(input) => {
                if (input != null) {
                  input.focus();
                }
              }}
              multiline
              fullWidth
              rows={6}
              value={msg}
              onChange={handleMsg}
              variant="filled"
              placeholder="Write the contents of the text message here"
            />
          </Box>
          <Box py={2}>
            {error && (
              <Typography style={{ color: "red" }}>{error.message}</Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={resetAndClose}
            color="primary"
            disabled={loading}
          >
            {success === true ? "Close" : "Cancel"}
          </Button>
          <Box position="relative">
            <Button
              onClick={handleForm}
              color="primary"
              disabled={!selectedPhone || msg.length < 10 || loading}
            >
              Send to {formatPhoneNumber(selectedPhone)}
            </Button>
            {loading && (
              <Box position="absolute" top="50%" left="50%" mt={-1} ml={-1}>
                <CircularProgress size={16} color="primary" />
              </Box>
            )}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  ) : null;
}

export default SmsContact;
