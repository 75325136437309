export const SOLERA = "solera";

export const DASHBOARD_RECORD_LIMIT = 20;

export const STATUS = {
  DRAFT: "DRAFT",
  REQUIRES_REVIEW: "REQUIRES REVIEW",
  READY_FOR_BATCH: "READY FOR BATCH",
  IN_PROCESS: "IN PROCESS",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  ON_HOLD: "ON HOLD",
  ARCHIVED: "ARCHIVED",
};

// keys
export const KEYS = {
  MIN_KEYS: [
    { field: "accountType", label: "Account type" },
    { field: "checkingAccountType", label: "Checking account type" },
    { field: "firstName", label: "First name" },
    { field: "lastName", label: "Last name" },
    {
      field: "email",
      label: "Email",
    },
    { field: "phoneNumber", label: "Phone number" },
    {
      field: "countryCitizenship",
      label: "US Citizen / Country of citizenship",
    },
    { field: "gender", label: "Gender" },
    { field: "birthDate", label: "Birth date" },
    { field: "maritalStatus", label: "Marital status" },
    { field: "employmentStatus", label: "Employment status" },
  ],
};

export const ACCOUNT_TYPE_EQRP = "eQRP";
export const ACCOUNT_TYPE_SOLO_401K = "Solo 401K";
export const ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY =
  "Checkbook IRA - Bank Account Only";
export const ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL =
  "Checkbook IRA - Custodial IRA & Bank Account";
export const ACCOUNT_TYPE_BUSINESS_ACCOUNT =
  "Business Account - Bank Account Only";
export const ACCOUNT_TYPE_CUSTODIAL_IRA = "Custodial IRA - Bank Account Only";

export const CHECKING_ACCOUNT_TYPE_TRUST = "Trust";
export const CHECKING_ACCOUNT_TYPE_LLC = "LLC";
